import { isClient } from "@tamagui/constants";
import { createVariable } from "../createVariable";
const scannedCache = /* @__PURE__ */ new WeakMap(), totalSelectorsInserted = /* @__PURE__ */ new Map(), allSelectors = {}, allRules = {}, insertedTransforms = {}, getAllSelectors = () => allSelectors, getAllRules = () => Object.values(allRules), getAllTransforms = () => insertedTransforms;
function addTransform(identifier, css, rule) {
  const s = css.indexOf("transform:");
  if (s === -1) {
    process.env.NODE_ENV === "development" && console.error(`\u274C Invalid transform, likely used deg/% improperly ${identifier}`);
    return;
  }
  const startI = s + 10, endI = css.indexOf(";"), value = css.slice(startI, endI);
  if (!insertedTransforms[identifier])
    return insertedTransforms[identifier] = value, !0;
}
function listenForSheetChanges() {
  if (!isClient)
    return;
  new MutationObserver((entries) => {
    for (const entry of entries)
      if (entry instanceof HTMLStyleElement && entry.sheet || entry instanceof HTMLLinkElement && entry.href.endsWith(".css")) {
        scanAllSheets();
        break;
      }
  }).observe(document.head, {
    childList: !0
  });
}
let lastScannedSheets = null;
function scanAllSheets(collectThemes = !1, tokens) {
  if (process.env.NODE_ENV === "test" || !isClient)
    return;
  let themes;
  const sheets = document.styleSheets || [], prev = lastScannedSheets, current = new Set(sheets);
  if (document.styleSheets) {
    for (const sheet2 of current)
      if (sheet2) {
        const out = updateSheetStyles(sheet2, !1, collectThemes, tokens);
        out && (themes = out);
      }
    lastScannedSheets = current;
  }
  if (prev)
    for (const sheet2 of prev)
      sheet2 && !current.has(sheet2) && updateSheetStyles(sheet2, !0);
  return themes;
}
function track(id, remove = !1) {
  const next = (totalSelectorsInserted.get(id) || 0) + (remove ? -1 : 1);
  return totalSelectorsInserted.set(id, next), next;
}
const bailAfterEnv = process.env.TAMAGUI_BAIL_AFTER_SCANNING_X_CSS_RULES, bailAfter = bailAfterEnv ? +bailAfterEnv : 250;
function updateSheetStyles(sheet2, remove = !1, collectThemes = !1, tokens) {
  let rules;
  try {
    if (rules = sheet2.cssRules, !rules)
      return;
  } catch {
    return;
  }
  const firstSelector = getTamaguiSelector(rules[0], collectThemes)?.[0], lastSelector = getTamaguiSelector(rules[rules.length - 1], collectThemes)?.[0], cacheKey = `${rules.length}${firstSelector}${lastSelector}`, lastScanned = scannedCache.get(sheet2);
  if (!remove && lastScanned === cacheKey)
    return;
  const len = rules.length;
  let fails = 0, dedupedThemes;
  for (let i = 0; i < len; i++) {
    const rule = rules[i];
    if (!(rule instanceof CSSStyleRule))
      continue;
    const response = getTamaguiSelector(rule, collectThemes);
    if (response)
      fails = 0;
    else {
      if (fails++, fails > bailAfter)
        return;
      continue;
    }
    const [identifier, cssRule, isTheme] = response;
    if (isTheme) {
      const deduped = addThemesFromCSS(cssRule, tokens);
      deduped && (dedupedThemes ||= [], dedupedThemes.push(deduped));
      continue;
    }
    const total = track(identifier, remove);
    remove ? total === 0 && delete allSelectors[identifier] : identifier in allSelectors || (!identifier.startsWith("_transform-") || addTransform(identifier, cssRule.cssText, cssRule)) && (allSelectors[identifier] = cssRule.cssText);
  }
  return scannedCache.set(sheet2, cacheKey), dedupedThemes;
}
let colorVarToVal, rootComputedStyle = null;
function addThemesFromCSS(cssStyleRule, tokens) {
  const selectors = cssStyleRule.selectorText.split(",");
  if (!selectors.length)
    return;
  if (tokens && !colorVarToVal) {
    colorVarToVal = {};
    for (const key in tokens.color) {
      const token = tokens.color[key];
      colorVarToVal[token.name] = token.val;
    }
  }
  const rules = (cssStyleRule.cssText || "").slice(cssStyleRule.selectorText.length + 2, -1).trim().split(";"), values = {};
  for (const rule of rules) {
    const sepI = rule.indexOf(":");
    if (sepI === -1)
      continue;
    const key = rule.slice(rule.indexOf("--") + 2, sepI), val = rule.slice(sepI + 2);
    let value;
    if (val.startsWith("var(")) {
      const varName = val.slice(6, -1), tokenVal = colorVarToVal[varName];
      tokenVal ? value = tokenVal : (rootComputedStyle ||= getComputedStyle(document.body), value = rootComputedStyle.getPropertyValue("--" + varName));
    } else
      value = val;
    values[key] = createVariable(
      {
        key,
        name: key,
        val: value
      },
      !0
    );
  }
  const dedupedEntry = {
    names: [],
    theme: values
  };
  for (const selector of selectors) {
    const matches = selector.match(/(.t_(light|dark))?[\s]?(.t_([a-z0-9_]+))[\s]*$/i) || [], [_0, _1, scheme, _2, name] = matches, themeName = name && scheme && scheme !== name ? `${scheme}_${name}` : name || scheme;
    !themeName || dedupedEntry.names.includes(themeName) || themeName === "light_dark" || themeName === "dark_light" || dedupedEntry.names.push(themeName);
  }
  return dedupedEntry;
}
function getTamaguiSelector(rule, collectThemes = !1) {
  if (rule instanceof CSSStyleRule) {
    const text = rule.selectorText;
    if (text[0] === ":" && text[1] === "r") {
      if (text.startsWith(":root ._"))
        return [getIdentifierFromTamaguiSelector(text), rule];
      if (collectThemes && (text.startsWith(":root.t_") || text.startsWith(":root .t_")))
        return [
          text.slice(0, 20),
          // just used as uid
          rule,
          !0
        ];
    }
  } else if (rule instanceof CSSMediaRule)
    return rule.cssRules.length > 1 ? void 0 : getTamaguiSelector(rule.cssRules[0]);
}
const getIdentifierFromTamaguiSelector = (selector) => {
  let res = selector.slice(7);
  return selector.includes(":") ? res.replace(/:[a-z]+$/, "") : res;
}, sheet = isClient ? document.head.appendChild(document.createElement("style")).sheet : null;
function updateRules(identifier, rules) {
  return identifier in allRules ? !1 : (allRules[identifier] = rules.join(" "), identifier.startsWith("_transform-") ? addTransform(identifier, rules[0]) : !0);
}
function insertStyleRules(rulesToInsert) {
  if (!(!rulesToInsert.length || !sheet)) {
    for (const { identifier, rules } of rulesToInsert)
      if (shouldInsertStyleRules(identifier)) {
        allSelectors[identifier] = rules.join(`
`), track(identifier), updateRules(identifier, rules);
        for (const rule of rules)
          if (process.env.NODE_ENV === "production")
            sheet.insertRule(rule, sheet.cssRules.length);
          else
            try {
              sheet.insertRule(rule, sheet.cssRules.length);
            } catch (err) {
              console.groupCollapsed(
                `Error inserting rule into CSSStyleSheet: ${String(err)}`
              ), console.info({ rule, rulesToInsert }), console.trace(), console.groupEnd();
            }
      }
  }
}
const minInsertAmt = process.env.TAMAGUI_INSERT_SELECTOR_TRIES ? +process.env.TAMAGUI_INSERT_SELECTOR_TRIES : 2;
function shouldInsertStyleRules(identifier) {
  if (process.env.IS_STATIC === "is_static")
    return !0;
  const total = totalSelectorsInserted.get(identifier);
  return process.env.NODE_ENV === "development" && totalSelectorsInserted.size > +(process.env.TAMAGUI_STYLE_INSERTION_WARNING_LIMIT || 1e4) && console.warn(
    'Warning: inserting many CSS rules, you may be animating something and generating many CSS insertions, which can degrade performance. Instead, try using the "disableClassName" property on elements that change styles often. To disable this warning set TAMAGUI_STYLE_INSERTION_WARNING_LIMIT from 50000 to something higher'
  ), total === void 0 || total < minInsertAmt;
}
export {
  getAllRules,
  getAllSelectors,
  getAllTransforms,
  insertStyleRules,
  insertedTransforms,
  listenForSheetChanges,
  scanAllSheets,
  shouldInsertStyleRules,
  updateRules
};
