import { useControllableState } from "@tamagui/use-controllable-state";
import { useSheetController } from "./useSheetController";
const useSheetOpenState = (props) => {
  const { isHidden, controller } = useSheetController(), onOpenChangeInternal = (val) => {
    controller?.onOpenChange?.(val), props.onOpenChange?.(val);
  }, [open, setOpen] = useControllableState({
    prop: controller?.open ?? props.open,
    defaultProp: props.defaultOpen ?? !1,
    onChange: onOpenChangeInternal,
    strategy: "most-recent-wins",
    transition: !0
  });
  return {
    open,
    setOpen,
    isHidden,
    controller
  };
};
export {
  useSheetOpenState
};
