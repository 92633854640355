import { isWeb } from "@tamagui/constants";
function expandStyle(key, value) {
  if (key === "flex")
    return [
      ["flexGrow", value],
      ["flexShrink", 1],
      ["flexBasis", "auto"]
    ];
  switch (key) {
    case "textAlignVertical":
      return [["verticalAlign", value === "center" ? "middle" : value]];
    case "writingDirection":
      return [["direction", value]];
  }
  if (key in EXPANSIONS)
    return EXPANSIONS[key].map((key2) => [key2, value]);
}
const all = ["Top", "Right", "Bottom", "Left"], horiz = ["Right", "Left"], vert = ["Top", "Bottom"], xy = ["X", "Y"], EXPANSIONS = {
  borderColor: ["TopColor", "RightColor", "BottomColor", "LeftColor"],
  borderRadius: [
    "TopLeftRadius",
    "TopRightRadius",
    "BottomRightRadius",
    "BottomLeftRadius"
  ],
  borderWidth: ["TopWidth", "RightWidth", "BottomWidth", "LeftWidth"],
  margin: all,
  marginHorizontal: horiz,
  marginVertical: vert,
  overscrollBehavior: xy,
  padding: all,
  paddingHorizontal: horiz,
  paddingVertical: vert,
  ...isWeb && {
    // react-native only supports borderStyle
    borderStyle: ["TopStyle", "RightStyle", "BottomStyle", "LeftStyle"],
    // react-native doesn't support X / Y
    overflow: xy
  }
};
for (const parent in EXPANSIONS) {
  const prefix = parent.slice(0, /[A-Z]/.exec(parent)?.index ?? parent.length);
  EXPANSIONS[parent] = EXPANSIONS[parent].map((k) => `${prefix}${k}`);
}
export {
  expandStyle
};
