import React, { FC, StrictMode, useContext, useMemo } from 'react'
import {
  KeyboardAvoidingView,
  View,
  ImageBackground,
  useWindowDimensions,
} from 'react-native'
// import { ImageBackground } from 'react-native'

import { HERO_BACKGROUND_IMAGE_URL } from '@data/config/images'

import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ImageKitImage } from './GraphQLImage'
import { Element } from './Element'

import { ComponentsContext, TokensContext, BreakpointContext } from './context'
import { applyStyles } from './util/applyStyles'

import { useBreakpoint } from './useBreakpoint'

interface ScreenProps {
  justifyContent?: 'center'
  alignItems?: 'center'
  variant?: string
  children: React.ReactNode
}

export const Screen = ({
  justifyContent,
  alignItems,
  children,
  variant,
}: ScreenProps) => {
  const insets = useSafeAreaInsets()

  const tokensContext = useContext(TokensContext)
  const componentsContext = useContext(ComponentsContext)
  const breakpointContext = useContext(BreakpointContext)

  const screenStyles = useMemo(() => {
    return applyStyles(
      tokensContext,
      componentsContext,
      breakpointContext,
      { justifyContent, alignItems },
      'Screen',
      variant
    )
  }, [
    tokensContext,
    componentsContext,
    breakpointContext,
    justifyContent,
    alignItems,
    variant,
  ])

  return (
    // <StrictMode>
    <View
      style={[
        screenStyles,
        {
          paddingLeft: Math.max(
            screenStyles.paddingLeft ||
              screenStyles.paddingHorizontal ||
              screenStyles.padding ||
              0,
            insets.left
          ),
          paddingRight: Math.max(
            screenStyles.paddingRight ||
              screenStyles.paddingHorizontal ||
              screenStyles.padding ||
              0,
            insets.right
          ),
        },
      ]}
    >
      {children}
    </View>
    // </StrictMode>
  )
}

export const GradientBackgroundScreen: FC<ScreenProps> = (screenProps) => {
  return (
    // <StrictMode>
    <Element
      style={{
        flexGrow: 1,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundImage: `url(${HERO_BACKGROUND_IMAGE_URL})`,
      }}
    >
      {/* <ImageKitImage
        as={ImageBackground}
        imageUrl={HERO_BACKGROUND_IMAGE_URL}
        // maxHeight={100}
        maxWidth='max'
        transformations={{ c: 'force' }}
        variant='contentCentered'
      > */}
      <Screen {...screenProps} variant='gradientBackgroundScreen' />
      {/* </ImageKitImage> */}
    </Element>
    // </StrictMode>
  )
}
