function createShallowSetState(setter, debug) {
  return (next) => setter((prev) => mergeIfNotShallowEqual(prev, next, debug));
}
function mergeIfNotShallowEqual(prev, next, debug) {
  return isEqualShallow(prev, next) ? prev : (process.env.NODE_ENV === "development" && debug && console.warn("setStateShallow CHANGE", { prev, next }), { ...prev, ...next });
}
function isEqualShallow(prev, next) {
  for (const key in next)
    if (prev[key] !== next[key])
      return !1;
  return !0;
}
export {
  createShallowSetState,
  isEqualShallow,
  mergeIfNotShallowEqual
};
