import { useDelayGroupContext } from "@floating-ui/react";
import { getSpace } from "@tamagui/get-token";
import { Paragraph } from "@tamagui/text";
import * as React from "react";
import { Tooltip, TooltipGroup } from "./Tooltip";
import { jsx, jsxs } from "react/jsx-runtime";
const TooltipSimple = React.forwardRef(
  ({ label, children, contentProps, disabled, ...tooltipProps }, ref) => {
    let context;
    try {
      context = useDelayGroupContext();
    } catch {
    }
    const child = React.Children.only(children), contents = /* @__PURE__ */ jsxs(
      Tooltip,
      {
        offset: 15,
        restMs: 40,
        delay: 40,
        ...tooltipProps,
        ...disabled ? { open: !1 } : null,
        children: [
          /* @__PURE__ */ jsx(
            Tooltip.Trigger,
            {
              ...typeof label == "string" && {
                "aria-label": label
              },
              asChild: "except-style",
              children: ref && React.isValidElement(child) ? React.cloneElement(child, { ref }) : child
            }
          ),
          /* @__PURE__ */ jsxs(
            Tooltip.Content,
            {
              zIndex: 1e9,
              enterStyle: { x: 0, y: -8, opacity: 0, scale: 0.93 },
              exitStyle: { x: 0, y: -8, opacity: 0, scale: 0.93 },
              x: 0,
              scale: 1,
              y: 0,
              elevation: "$1",
              opacity: 1,
              paddingVertical: getSpace(tooltipProps.size || "$true", {
                shift: -4
              }),
              animateOnly: ["transform", "opacity"],
              animation: [
                "quicker",
                {
                  opacity: {
                    overshootClamping: !0
                  }
                }
              ],
              ...contentProps,
              children: [
                /* @__PURE__ */ jsx(Tooltip.Arrow, {}),
                /* @__PURE__ */ jsx(Paragraph, { size: "$3", children: label })
              ]
            }
          )
        ]
      }
    );
    return context ? contents : /* @__PURE__ */ jsx(TooltipGroup, { delay: defaultTooltipDelay, children: contents });
  }
), defaultTooltipDelay = { open: 3e3, close: 100 };
export {
  TooltipSimple
};
