import { useComposedRefs } from "@tamagui/compose-refs";
import { styled } from "@tamagui/core";
import { SizableText } from "@tamagui/text";
import * as React from "react";
import { useSelectContext, useSelectItemParentContext } from "./context";
import { useSelectItemContext } from "./SelectItem";
import { Fragment, jsx } from "react/jsx-runtime";
const ITEM_TEXT_NAME = "SelectItemText", SelectItemTextFrame = styled(SizableText, {
  name: ITEM_TEXT_NAME,
  variants: {
    unstyled: {
      false: {
        userSelect: "none",
        color: "$color",
        ellipse: !0
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), SelectItemText = SelectItemTextFrame.styleable(function(props, forwardedRef) {
  const { __scopeSelect, className, ...itemTextProps } = props, context = useSelectContext(ITEM_TEXT_NAME, __scopeSelect), itemParentContext = useSelectItemParentContext(ITEM_TEXT_NAME, __scopeSelect), ref = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, ref), itemContext = useSelectItemContext(ITEM_TEXT_NAME, __scopeSelect), contents = React.useRef();
  return contents.current = /* @__PURE__ */ jsx(
    SelectItemTextFrame,
    {
      className,
      size: itemParentContext.size,
      id: itemContext.textId,
      ...itemTextProps,
      ref: composedRefs
    }
  ), React.useEffect(() => {
    itemParentContext.initialValue === itemContext.value && !context.selectedIndex && context.setSelectedItem(contents.current);
  }, []), React.useEffect(() => itemParentContext.valueSubscribe((val) => {
    val === itemContext.value && context.setSelectedItem(contents.current);
  }), [itemContext.value]), itemParentContext.shouldRenderWebNative ? /* @__PURE__ */ jsx(Fragment, { children: props.children }) : /* @__PURE__ */ jsx(Fragment, { children: contents.current });
});
export {
  ITEM_TEXT_NAME,
  SelectItemText,
  SelectItemTextFrame
};
