import * as React from "react";
import * as ResponderSystem from "./ResponderSystem";
export * from "./utils";
const emptyObject = {};
function useResponderEvents(hostRef, config = emptyObject) {
  const id = React.useId(), isAttachedRef = React.useRef(!1);
  React.useEffect(() => (ResponderSystem.attachListeners(), () => {
    ResponderSystem.removeNode(id);
  }), [id]), React.useEffect(() => {
    const {
      onMoveShouldSetResponder,
      onMoveShouldSetResponderCapture,
      onScrollShouldSetResponder,
      onScrollShouldSetResponderCapture,
      onSelectionChangeShouldSetResponder,
      onSelectionChangeShouldSetResponderCapture,
      onStartShouldSetResponder,
      onStartShouldSetResponderCapture
    } = config, requiresResponderSystem = onMoveShouldSetResponder != null || onMoveShouldSetResponderCapture != null || onScrollShouldSetResponder != null || onScrollShouldSetResponderCapture != null || onSelectionChangeShouldSetResponder != null || onSelectionChangeShouldSetResponderCapture != null || onStartShouldSetResponder != null || onStartShouldSetResponderCapture != null, node = hostRef.current;
    requiresResponderSystem ? (ResponderSystem.addNode(id, node, config), isAttachedRef.current = !0) : isAttachedRef.current && (ResponderSystem.removeNode(id), isAttachedRef.current = !1);
  }, [config, hostRef, id]), process.env.NODE_ENV === "development" && (React.useDebugValue({
    isResponder: hostRef.current === ResponderSystem.getResponderNode()
  }), React.useDebugValue(config));
}
export {
  useResponderEvents
};
