import { useMedia } from "@tamagui/core";
const useSelectBreakpointActive = (sheetBreakpoint) => {
  const media = useMedia();
  return sheetBreakpoint ? sheetBreakpoint === !0 ? !0 : sheetBreakpoint ? media[sheetBreakpoint] : !1 : !1;
}, useShowSelectSheet = (context) => {
  const breakpointActive = useSelectBreakpointActive(context.sheetBreakpoint);
  return context.open === !1 ? !1 : breakpointActive;
};
export {
  useSelectBreakpointActive,
  useShowSelectSheet
};
