import React from 'react'

import { ExternalLink } from './Link'
import { Screen } from './Screen'
import { LinkText, Text } from './Text'

type ErrorBoundaryProps = {
  children: React.ReactNode
  fallback?: React.ReactNode
  onError?: (error: Error) => void
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error) {
    this.props.onError?.(error)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback ?? (
          <Screen alignItems='center' justifyContent='center'>
            <Text size={6} variant='errorBox'>
              Something went wrong, please email{' '}
              <ExternalLink to='mailto:hi@guild.host'>
                <LinkText size={6}>hi@guild.host</LinkText>
              </ExternalLink>{' '}
              for help.
            </Text>
          </Screen>
        )
      )
    }
    return this.props.children
  }
}
