import { getBoundingClientRect } from "./utils";
const emptyFunction = () => {
}, emptyObject = {}, emptyArray = [];
function normalizeIdentifier(identifier) {
  return identifier > 20 ? identifier % 20 : identifier;
}
function createResponderEvent(domEvent, responderTouchHistoryStore) {
  let rect, propagationWasStopped = !1, changedTouches, touches;
  const domEventChangedTouches = domEvent.changedTouches, domEventType = domEvent.type, metaKey = domEvent.metaKey === !0, shiftKey = domEvent.shiftKey === !0, force = domEventChangedTouches?.[0].force || 0, identifier = normalizeIdentifier(domEventChangedTouches?.[0].identifier || 0), clientX = domEventChangedTouches?.[0].clientX || domEvent.clientX, clientY = domEventChangedTouches?.[0].clientY || domEvent.clientY, pageX = domEventChangedTouches?.[0].pageX || domEvent.pageX, pageY = domEventChangedTouches?.[0].pageY || domEvent.pageY, preventDefault = typeof domEvent.preventDefault == "function" ? domEvent.preventDefault.bind(domEvent) : emptyFunction, timestamp = domEvent.timeStamp;
  function normalizeTouches(touches2) {
    return Array.prototype.slice.call(touches2).map((touch) => ({
      force: touch.force,
      identifier: normalizeIdentifier(touch.identifier),
      get locationX() {
        return locationX(touch.clientX);
      },
      get locationY() {
        return locationY(touch.clientY);
      },
      pageX: touch.pageX,
      pageY: touch.pageY,
      target: touch.target,
      timestamp
    }));
  }
  if (domEventChangedTouches != null)
    changedTouches = normalizeTouches(domEventChangedTouches), touches = normalizeTouches(domEvent.touches);
  else {
    const emulatedTouches = [
      {
        force,
        identifier,
        get locationX() {
          return locationX(clientX);
        },
        get locationY() {
          return locationY(clientY);
        },
        pageX,
        pageY,
        target: domEvent.target,
        timestamp
      }
    ];
    changedTouches = emulatedTouches, touches = domEventType === "mouseup" || domEventType === "dragstart" ? emptyArray : emulatedTouches;
  }
  const responderEvent = {
    bubbles: !0,
    cancelable: !0,
    // `currentTarget` is set before dispatch
    currentTarget: null,
    defaultPrevented: domEvent.defaultPrevented,
    dispatchConfig: emptyObject,
    eventPhase: domEvent.eventPhase,
    isDefaultPrevented() {
      return domEvent.defaultPrevented;
    },
    isPropagationStopped() {
      return propagationWasStopped;
    },
    isTrusted: domEvent.isTrusted,
    nativeEvent: {
      altKey: !1,
      ctrlKey: !1,
      metaKey,
      shiftKey,
      changedTouches,
      force,
      identifier,
      get locationX() {
        return locationX(clientX);
      },
      get locationY() {
        return locationY(clientY);
      },
      pageX,
      pageY,
      target: domEvent.target,
      timestamp,
      touches,
      type: domEventType
    },
    persist: emptyFunction,
    preventDefault,
    stopPropagation() {
      propagationWasStopped = !0;
    },
    target: domEvent.target,
    timeStamp: timestamp,
    touchHistory: responderTouchHistoryStore.touchHistory
  };
  function locationX(x) {
    if (rect = rect || getBoundingClientRect(responderEvent.currentTarget), rect)
      return x - rect.left;
  }
  function locationY(y) {
    if (rect = rect || getBoundingClientRect(responderEvent.currentTarget), rect)
      return y - rect.top;
  }
  return responderEvent;
}
export {
  createResponderEvent as default
};
