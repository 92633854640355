import { Collapsible } from "@tamagui/collapsible";
import { createCollection } from "@tamagui/collection";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { YStack } from "@tamagui/stacks";
import { H1 } from "@tamagui/text";
import { useControllableState } from "@tamagui/use-controllable-state";
import { useDirection } from "@tamagui/use-direction";
import { createStyledContext, styled } from "@tamagui/web";
import * as React from "react";
import { jsx } from "react/jsx-runtime";
const ACCORDION_NAME = "Accordion", ACCORDION_KEYS = ["Home", "End", "ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"], [Collection, useCollection] = createCollection(ACCORDION_NAME), ACCORDION_CONTEXT = "Accordion", AccordionComponent = React.forwardRef((props, forwardedRef) => {
  const { type, ...accordionProps } = props, singleProps = accordionProps, multipleProps = accordionProps;
  return /* @__PURE__ */ jsx(Collection.Provider, { __scopeCollection: props.__scopeAccordion || ACCORDION_CONTEXT, children: type === "multiple" ? /* @__PURE__ */ jsx(AccordionImplMultiple, { ...multipleProps, ref: forwardedRef }) : /* @__PURE__ */ jsx(AccordionImplSingle, { ...singleProps, ref: forwardedRef }) });
});
AccordionComponent.displayName = ACCORDION_NAME;
AccordionComponent.propTypes = {
  type(props) {
    const value = props.value || props.defaultValue;
    return props.type && !["single", "multiple"].includes(props.type) ? new Error(
      "Invalid prop `type` supplied to `Accordion`. Expected one of `single | multiple`."
    ) : props.type === "multiple" && typeof value == "string" ? new Error(
      "Invalid prop `type` supplied to `Accordion`. Expected `single` when `defaultValue` or `value` is type `string`."
    ) : props.type === "single" && Array.isArray(value) ? new Error(
      "Invalid prop `type` supplied to `Accordion`. Expected `multiple` when `defaultValue` or `value` is type `string[]`."
    ) : null;
  }
};
const { Provider: AccordionValueProvider, useStyledContext: useAccordionValueContext } = createStyledContext(), {
  Provider: AccordionCollapsibleProvider,
  useStyledContext: useAccordionCollapsibleContext
} = createStyledContext(), AccordionImplSingle = React.forwardRef((props, forwardedRef) => {
  const {
    value: valueProp,
    defaultValue,
    control,
    onValueChange = () => {
    },
    collapsible = !1,
    ...accordionSingleProps
  } = props, [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue || "",
    onChange: onValueChange
  });
  return /* @__PURE__ */ jsx(
    AccordionValueProvider,
    {
      scope: props.__scopeAccordion,
      value: value ? [value] : [],
      onItemOpen: setValue,
      onItemClose: React.useCallback(
        () => collapsible && setValue(""),
        [setValue, collapsible]
      ),
      children: /* @__PURE__ */ jsx(
        AccordionCollapsibleProvider,
        {
          scope: props.__scopeAccordion,
          collapsible,
          children: /* @__PURE__ */ jsx(AccordionImpl, { ...accordionSingleProps, ref: forwardedRef })
        }
      )
    }
  );
}), AccordionImplMultiple = React.forwardRef((props, forwardedRef) => {
  const {
    value: valueProp,
    defaultValue,
    onValueChange = () => {
    },
    ...accordionMultipleProps
  } = props, [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue || [],
    onChange: onValueChange
  }), handleItemOpen = React.useCallback(
    (itemValue) => setValue((prevValue = []) => [...prevValue, itemValue]),
    [setValue]
  ), handleItemClose = React.useCallback(
    (itemValue) => setValue((prevValue = []) => prevValue.filter((value2) => value2 !== itemValue)),
    [setValue]
  );
  return /* @__PURE__ */ jsx(
    AccordionValueProvider,
    {
      scope: props.__scopeAccordion,
      value: value || [],
      onItemOpen: handleItemOpen,
      onItemClose: handleItemClose,
      children: /* @__PURE__ */ jsx(AccordionCollapsibleProvider, { scope: props.__scopeAccordion, collapsible: !0, children: /* @__PURE__ */ jsx(AccordionImpl, { ...accordionMultipleProps, ref: forwardedRef }) })
    }
  );
}), { Provider: AccordionImplProvider, useStyledContext: useAccordionContext } = createStyledContext(), AccordionImpl = React.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeAccordion,
      disabled,
      dir,
      orientation = "vertical",
      ...accordionProps
    } = props, accordionRef = React.useRef(null), composedRef = useComposedRefs(accordionRef, forwardedRef), getItems = useCollection(__scopeAccordion || ACCORDION_CONTEXT), isDirectionLTR = useDirection(dir) === "ltr", handleKeyDown = composeEventHandlers(
      props.onKeyDown,
      (event) => {
        if (!ACCORDION_KEYS.includes(event.key))
          return;
        const target = event.target, triggerCollection = getItems().filter((item) => !item.ref.current?.disabled), triggerIndex = triggerCollection.findIndex(
          (item) => item.ref.current === target
        ), triggerCount = triggerCollection.length;
        if (triggerIndex === -1)
          return;
        event.preventDefault();
        let nextIndex = triggerIndex;
        const homeIndex = 0, endIndex = triggerCount - 1, moveNext = () => {
          nextIndex = triggerIndex + 1, nextIndex > endIndex && (nextIndex = homeIndex);
        }, movePrev = () => {
          nextIndex = triggerIndex - 1, nextIndex < homeIndex && (nextIndex = endIndex);
        };
        switch (event.key) {
          case "Home":
            nextIndex = homeIndex;
            break;
          case "End":
            nextIndex = endIndex;
            break;
          case "ArrowRight":
            orientation === "horizontal" && (isDirectionLTR ? moveNext() : movePrev());
            break;
          case "ArrowDown":
            orientation === "vertical" && moveNext();
            break;
          case "ArrowLeft":
            orientation === "horizontal" && (isDirectionLTR ? movePrev() : moveNext());
            break;
          case "ArrowUp":
            orientation === "vertical" && movePrev();
            break;
        }
        const clampedIndex = nextIndex % triggerCount;
        triggerCollection[clampedIndex].ref.current?.focus();
      }
    );
    return /* @__PURE__ */ jsx(
      AccordionImplProvider,
      {
        scope: __scopeAccordion,
        disabled,
        direction: dir,
        orientation,
        children: /* @__PURE__ */ jsx(Collection.Slot, { __scopeCollection: __scopeAccordion || ACCORDION_CONTEXT, children: /* @__PURE__ */ jsx(
          YStack,
          {
            "data-orientation": orientation,
            ref: composedRef,
            ...accordionProps,
            ...isWeb && {
              onKeyDown: handleKeyDown
            }
          }
        ) })
      }
    );
  }
), ITEM_NAME = "AccordionItem", { Provider: AccordionItemProvider, useStyledContext: useAccordionItemContext } = createStyledContext(), AccordionItem = React.forwardRef(
  (props, forwardedRef) => {
    const { __scopeAccordion, value, ...accordionItemProps } = props, accordionContext = useAccordionContext(__scopeAccordion), valueContext = useAccordionValueContext(__scopeAccordion), triggerId = React.useId(), open = value && valueContext.value.includes(value) || !1, disabled = accordionContext.disabled || props.disabled;
    return /* @__PURE__ */ jsx(
      AccordionItemProvider,
      {
        scope: __scopeAccordion,
        open,
        disabled,
        triggerId,
        children: /* @__PURE__ */ jsx(
          Collapsible,
          {
            "data-orientation": accordionContext.orientation,
            "data-state": open ? "open" : "closed",
            __scopeCollapsible: __scopeAccordion || ACCORDION_CONTEXT,
            ...accordionItemProps,
            ref: forwardedRef,
            disabled,
            open,
            onOpenChange: (open2) => {
              open2 ? valueContext.onItemOpen(value) : valueContext.onItemClose(value);
            }
          }
        )
      }
    );
  }
);
AccordionItem.displayName = ITEM_NAME;
const HEADER_NAME = "AccordionHeader", AccordionHeader = React.forwardRef(
  (props, forwardedRef) => {
    const { __scopeAccordion, ...headerProps } = props, accordionContext = useAccordionContext(__scopeAccordion), itemContext = useAccordionItemContext(__scopeAccordion);
    return /* @__PURE__ */ jsx(
      H1,
      {
        "data-orientation": accordionContext.orientation,
        "data-state": getState(itemContext.open),
        "data-disabled": itemContext.disabled ? "" : void 0,
        ...headerProps,
        ref: forwardedRef
      }
    );
  }
);
AccordionHeader.displayName = HEADER_NAME;
const AccordionTriggerFrame = styled(Collapsible.Trigger, {
  variants: {
    unstyled: {
      false: {
        cursor: "pointer",
        backgroundColor: "$background",
        borderColor: "$borderColor",
        borderWidth: 1,
        padding: "$true",
        hoverStyle: {
          backgroundColor: "$backgroundHover"
        },
        focusStyle: {
          backgroundColor: "$backgroundFocus"
        },
        pressStyle: {
          backgroundColor: "$backgroundPress"
        }
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), AccordionTrigger = AccordionTriggerFrame.styleable(function(props, forwardedRef) {
  const { __scopeAccordion, ...triggerProps } = props, accordionContext = useAccordionContext(__scopeAccordion), itemContext = useAccordionItemContext(__scopeAccordion), collapsibleContext = useAccordionCollapsibleContext(__scopeAccordion);
  return /* @__PURE__ */ jsx(Collection.ItemSlot, { __scopeCollection: __scopeAccordion || ACCORDION_CONTEXT, children: /* @__PURE__ */ jsx(
    AccordionTriggerFrame,
    {
      __scopeCollapsible: __scopeAccordion || ACCORDION_CONTEXT,
      "aria-disabled": itemContext.open && !collapsibleContext.collapsible || void 0,
      "data-orientation": accordionContext.orientation,
      id: itemContext.triggerId,
      ...triggerProps,
      ref: forwardedRef
    }
  ) });
}), AccordionContentFrame = styled(Collapsible.Content, {
  variants: {
    unstyled: {
      false: {
        padding: "$true",
        backgroundColor: "$background"
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), AccordionContent = AccordionContentFrame.styleable(function(props, forwardedRef) {
  const { __scopeAccordion, ...contentProps } = props, accordionContext = useAccordionContext(__scopeAccordion), itemContext = useAccordionItemContext(__scopeAccordion);
  return /* @__PURE__ */ jsx(
    AccordionContentFrame,
    {
      role: "region",
      "aria-labelledby": itemContext.triggerId,
      "data-orientation": accordionContext.orientation,
      __scopeCollapsible: __scopeAccordion || ACCORDION_CONTEXT,
      ...contentProps,
      ref: forwardedRef
    }
  );
});
function getState(open) {
  return open ? "open" : "closed";
}
const Accordion = withStaticProperties(AccordionComponent, {
  Trigger: AccordionTrigger,
  Header: AccordionHeader,
  Content: AccordionContent,
  Item: AccordionItem
});
export {
  Accordion
};
