import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { getRect } from "../helpers/getRect";
import { measureLayout } from "./useElementLayout";
function usePlatformMethods(hostRef) {
  useIsomorphicLayoutEffect(() => {
    const node = hostRef.current;
    node && (node.measure ||= (callback) => measureLayout(node, null, callback), node.measureLayout ||= (relativeToNode, success) => measureLayout(node, relativeToNode, success), node.measureInWindow ||= (callback) => {
      node && setTimeout(() => {
        const { height, left, top, width } = getRect(node);
        callback(left, top, width, height);
      }, 0);
    });
  }, [hostRef]);
}
export {
  usePlatformMethods
};
