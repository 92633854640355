import { getConfig } from "../config";
function getExpandedShorthands(props) {
  const shorthands = getConfig().shorthands;
  if (!shorthands)
    return props;
  const res = {};
  for (const key in props)
    res[shorthands[key] || key] = props[key];
  return res;
}
export {
  getExpandedShorthands
};
