import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { useEvent } from "@tamagui/core";
import { useDidFinishSSR } from "@tamagui/use-did-finish-ssr";
import React, {
  createContext,
  memo,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useReducer
} from "react";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
var ACTIONS = /* @__PURE__ */ ((ACTIONS2) => (ACTIONS2[ACTIONS2.REGISTER_HOST = 0] = "REGISTER_HOST", ACTIONS2[ACTIONS2.DEREGISTER_HOST = 1] = "DEREGISTER_HOST", ACTIONS2[ACTIONS2.ADD_UPDATE_PORTAL = 2] = "ADD_UPDATE_PORTAL", ACTIONS2[ACTIONS2.REMOVE_PORTAL = 3] = "REMOVE_PORTAL", ACTIONS2))(ACTIONS || {});
const INITIAL_STATE = {};
const registerHost = (state, hostName) => (hostName in state || (state[hostName] = []), state), deregisterHost = (state, hostName) => (delete state[hostName], state), addUpdatePortal = (state, hostName, portalName, node) => {
  hostName in state || (state = registerHost(state, hostName));
  const index = state[hostName].findIndex((item) => item.name === portalName);
  return index !== -1 ? state[hostName][index].node = node : state[hostName].push({
    name: portalName,
    node
  }), state;
}, removePortal = (state, hostName, portalName) => {
  if (!(hostName in state))
    return console.info(
      `Failed to remove portal '${portalName}', '${hostName}' was not registered!`
    ), state;
  const index = state[hostName].findIndex((item) => item.name === portalName);
  return index !== -1 && state[hostName].splice(index, 1), state;
}, reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case 0 /* REGISTER_HOST */:
      return registerHost({ ...state }, action.hostName);
    case 1 /* DEREGISTER_HOST */:
      return deregisterHost({ ...state }, action.hostName);
    case 2 /* ADD_UPDATE_PORTAL */:
      return addUpdatePortal(
        { ...state },
        action.hostName,
        action.portalName,
        action.node
      );
    case 3 /* REMOVE_PORTAL */:
      return removePortal(
        { ...state },
        action.hostName,
        action.portalName
      );
    default:
      return state;
  }
}, PortalStateContext = createContext(null), PortalDispatchContext = createContext(null), usePortalState = (hostName) => {
  const state = useContext(PortalStateContext);
  if (state === null)
    throw new Error(
      "'PortalStateContext' cannot be null, please add 'PortalProvider' to the root component."
    );
  return state[hostName] || [];
}, usePortal = (hostName = "root") => {
  const dispatch = useContext(PortalDispatchContext);
  if (dispatch === null)
    throw new Error(
      "'PortalDispatchContext' cannot be null, please add 'PortalProvider' to the root component."
    );
  const registerHost2 = useCallback(() => {
    dispatch({
      type: 0 /* REGISTER_HOST */,
      hostName
    });
  }, []), deregisterHost2 = useCallback(() => {
    dispatch({
      type: 1 /* DEREGISTER_HOST */,
      hostName
    });
  }, []), addUpdatePortal2 = useCallback((name, node) => {
    dispatch({
      type: 2 /* ADD_UPDATE_PORTAL */,
      hostName,
      portalName: name,
      node
    });
  }, []), removePortal2 = useCallback((name) => {
    dispatch({
      type: 3 /* REMOVE_PORTAL */,
      hostName,
      portalName: name
    });
  }, []);
  return {
    registerHost: registerHost2,
    deregisterHost: deregisterHost2,
    addPortal: addUpdatePortal2,
    updatePortal: addUpdatePortal2,
    removePortal: removePortal2
  };
}, PortalProviderComponent = ({
  rootHostName = "root",
  shouldAddRootHost = !0,
  children
}) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE), transitionDispatch = useMemo(() => (value) => {
    startTransition(() => {
      dispatch(value);
    });
  }, [dispatch]);
  return /* @__PURE__ */ jsx(PortalDispatchContext.Provider, { value: transitionDispatch, children: /* @__PURE__ */ jsxs(PortalStateContext.Provider, { value: state, children: [
    children,
    shouldAddRootHost && /* @__PURE__ */ jsx(PortalHost, { name: rootHostName })
  ] }) });
}, PortalProvider = memo(PortalProviderComponent);
PortalProvider.displayName = "PortalProvider";
const defaultRenderer = (children) => /* @__PURE__ */ jsx(Fragment, { children }), PortalHostComponent = (props) => {
  const { name, forwardProps, render = defaultRenderer } = props, isServer = !useDidFinishSSR(), state = usePortalState(name), { registerHost: registerHost2, deregisterHost: deregisterHost2 } = usePortal(props.name);
  return useEffect(() => {
    if (!isServer)
      return registerHost2(), () => {
        deregisterHost2();
      };
  }, [isServer]), render(
    forwardProps ? state.map((item) => {
      let next = item.node;
      return forwardProps ? React.Children.map(next, (child) => React.isValidElement(child) ? React.cloneElement(child, { key: child.key, ...forwardProps }) : child) : next;
    }) : state.map((item) => item.node)
  );
}, PortalHost = memo(PortalHostComponent);
PortalHost.displayName = "PortalHost";
const PortalComponent = (props) => {
  const {
    name: _providedName,
    hostName,
    handleOnMount: _providedHandleOnMount,
    handleOnUnmount: _providedHandleOnUnmount,
    handleOnUpdate: _providedHandleOnUpdate,
    children
  } = props, { addPortal: addUpdatePortal2, removePortal: removePortal2 } = usePortal(hostName), id = useId(), name = _providedName || id, handleOnMount = useEvent(() => {
    _providedHandleOnMount ? _providedHandleOnMount(() => addUpdatePortal2(name, children)) : addUpdatePortal2(name, children);
  }), handleOnUnmount = useEvent(() => {
    _providedHandleOnUnmount ? _providedHandleOnUnmount(() => removePortal2(name)) : removePortal2(name);
  }), handleOnUpdate = useEvent(() => {
    _providedHandleOnUpdate ? _providedHandleOnUpdate(() => addUpdatePortal2(name, children)) : addUpdatePortal2(name, children);
  });
  return useIsomorphicLayoutEffect(() => (handleOnMount(), () => {
    handleOnUnmount();
  }), []), useEffect(() => {
    handleOnUpdate();
  }, [children]), null;
}, PortalItem = memo(PortalComponent);
PortalItem.displayName = "Portal";
export {
  ACTIONS,
  INITIAL_STATE,
  PortalHost,
  PortalItem,
  PortalProvider,
  usePortal
};
