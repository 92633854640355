import { createStyledContext } from "@tamagui/core";
const SwitchContext = createStyledContext({
  checked: !1,
  disabled: !1,
  size: void 0,
  frameWidth: 0,
  unstyled: process.env.TAMAGUI_HEADLESS === "1"
});
export {
  SwitchContext
};
