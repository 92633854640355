const SELECT_NAME = "Select", WINDOW_PADDING = 8, SCROLL_ARROW_VELOCITY = 8, SCROLL_ARROW_THRESHOLD = 8, MIN_HEIGHT = 80, FALLBACK_THRESHOLD = 16, VIEWPORT_NAME = "SelectViewport";
export {
  FALLBACK_THRESHOLD,
  MIN_HEIGHT,
  SCROLL_ARROW_THRESHOLD,
  SCROLL_ARROW_VELOCITY,
  SELECT_NAME,
  VIEWPORT_NAME,
  WINDOW_PADDING
};
