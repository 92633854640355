import { createContextScope } from "@tamagui/create-context";
const SLIDER_NAME = "Slider", [createSliderContext, createSliderScope] = createContextScope(SLIDER_NAME), [SliderProvider, useSliderContext] = createSliderContext(SLIDER_NAME), [SliderOrientationProvider, useSliderOrientationContext] = createSliderContext(SLIDER_NAME, {
  startEdge: "left",
  endEdge: "right",
  sizeProp: "width",
  size: 0,
  direction: 1
}), PAGE_KEYS = ["PageUp", "PageDown"], ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], BACK_KEYS = {
  ltr: ["ArrowDown", "Home", "ArrowLeft", "PageDown"],
  rtl: ["ArrowDown", "Home", "ArrowRight", "PageDown"]
};
export {
  ARROW_KEYS,
  BACK_KEYS,
  PAGE_KEYS,
  SLIDER_NAME,
  SliderOrientationProvider,
  SliderProvider,
  createSliderContext,
  createSliderScope,
  useSliderContext,
  useSliderOrientationContext
};
