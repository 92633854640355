import * as React from "react";
import { jsx } from "react/jsx-runtime";
function createContext(rootComponentName, defaultContext) {
  const Context = React.createContext(defaultContext);
  function Provider(props) {
    const { children, ...context } = props, value = React.useMemo(() => context, Object.values(context));
    return /* @__PURE__ */ jsx(Context.Provider, { value, children });
  }
  function useContext(consumerName) {
    const context = React.useContext(Context);
    if (context)
      return context;
    if (defaultContext !== void 0)
      return defaultContext;
    throw new Error(`\`${consumerName}\` must be used within \`${rootComponentName}\``);
  }
  return Provider.displayName = `${rootComponentName}Provider`, [Provider, useContext];
}
function createContextScope(scopeName, createContextScopeDeps = []) {
  let defaultContexts = [];
  function createContext2(rootComponentName, defaultContext) {
    const BaseContext = React.createContext(defaultContext), index = defaultContexts.length;
    defaultContexts = [...defaultContexts, defaultContext];
    function Provider(props) {
      const { scope, children, ...context } = props, Context = scope?.[scopeName]?.[index] || BaseContext, value = React.useMemo(
        () => context,
        Object.values(context)
      );
      return /* @__PURE__ */ jsx(Context.Provider, { value, children });
    }
    function useContext(consumerName, scope, options) {
      const Context = scope?.[scopeName]?.[index] || BaseContext, context = React.useContext(Context);
      if (context)
        return context;
      if (defaultContext !== void 0)
        return defaultContext;
      const missingContextMessage = `\`${consumerName}\` must be used within \`${rootComponentName}\``;
      if (options?.fallback)
        return options?.warn !== !1 && console.warn(missingContextMessage), options.fallback;
      throw new Error(missingContextMessage);
    }
    return Provider.displayName = `${rootComponentName}Provider`, [Provider, useContext];
  }
  const createScope = () => {
    const scopeContexts = defaultContexts.map((defaultContext) => React.createContext(defaultContext));
    return function(scope) {
      const contexts = scope?.[scopeName] || scopeContexts;
      return React.useMemo(
        () => ({ [`__scope${scopeName}`]: { ...scope, [scopeName]: contexts } }),
        [scope, contexts]
      );
    };
  };
  return createScope.scopeName = scopeName, [
    createContext2,
    composeContextScopes(createScope, ...createContextScopeDeps)
  ];
}
function composeContextScopes(...scopes) {
  const baseScope = scopes[0];
  if (scopes.length === 1)
    return baseScope;
  const createScope = () => {
    const scopeHooks = scopes.map((createScope2) => ({
      useScope: createScope2(),
      scopeName: createScope2.scopeName
    }));
    return function(overrideScopes) {
      const nextScopes = scopeHooks.reduce((nextScopes2, { useScope, scopeName }) => {
        const currentScope = useScope(overrideScopes)[`__scope${scopeName}`];
        return { ...nextScopes2, ...currentScope };
      }, {});
      return React.useMemo(
        () => ({ [`__scope${baseScope.scopeName}`]: nextScopes }),
        [nextScopes]
      );
    };
  };
  return createScope.scopeName = baseScope.scopeName, createScope;
}
export {
  createContext,
  createContextScope
};
