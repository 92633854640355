import { View, styled } from "@tamagui/core";
import { getElevation } from "./getElevation";
const fullscreenStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}, variants = {
  fullscreen: {
    true: fullscreenStyle
  },
  elevation: {
    "...size": getElevation,
    ":number": getElevation
  }
}, YStack = styled(View, {
  flexDirection: "column",
  variants
});
YStack.displayName = "YStack";
const XStack = styled(View, {
  flexDirection: "row",
  variants
});
XStack.displayName = "XStack";
const ZStack = styled(
  YStack,
  {
    position: "relative"
  },
  {
    neverFlatten: !0,
    isZStack: !0
  }
);
ZStack.displayName = "ZStack";
export {
  XStack,
  YStack,
  ZStack,
  fullscreenStyle
};
