import { Adapt, useAdaptParent } from "@tamagui/adapt";
import { AnimatePresence } from "@tamagui/animate-presence";
import { hideOthers } from "@tamagui/aria-hidden";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import {
  Theme,
  View,
  spacedChildren,
  styled,
  useGet,
  useMedia,
  useThemeName
} from "@tamagui/core";
import { createContext, createContextScope } from "@tamagui/create-context";
import { Dismissable } from "@tamagui/dismissable";
import { FocusScope } from "@tamagui/focus-scope";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { Portal, PortalHost, PortalItem } from "@tamagui/portal";
import { RemoveScroll } from "@tamagui/remove-scroll";
import { Overlay, Sheet, SheetController } from "@tamagui/sheet";
import { ButtonNestingContext, ThemeableStack, YStack } from "@tamagui/stacks";
import { H2, Paragraph } from "@tamagui/text";
import { useControllableState } from "@tamagui/use-controllable-state";
import * as React from "react";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const DIALOG_NAME = "Dialog", [createDialogContext, createDialogScope] = createContextScope(DIALOG_NAME), [DialogProvider, useDialogContext] = createDialogContext(DIALOG_NAME), TRIGGER_NAME = "DialogTrigger", DialogTriggerFrame = styled(View, {
  name: TRIGGER_NAME
}), DialogTrigger = DialogTriggerFrame.styleable(
  (props, forwardedRef) => {
    const { __scopeDialog, ...triggerProps } = props, isInsideButton = React.useContext(ButtonNestingContext), context = useDialogContext(TRIGGER_NAME, __scopeDialog), composedTriggerRef = useComposedRefs(forwardedRef, context.triggerRef);
    return /* @__PURE__ */ jsx(ButtonNestingContext.Provider, { value: !0, children: /* @__PURE__ */ jsx(
      DialogTriggerFrame,
      {
        tag: isInsideButton ? "span" : "button",
        "aria-haspopup": "dialog",
        "aria-expanded": context.open,
        "aria-controls": context.contentId,
        "data-state": getState(context.open),
        ...triggerProps,
        ref: composedTriggerRef,
        onPress: composeEventHandlers(props.onPress, context.onOpenToggle)
      }
    ) });
  }
);
DialogTrigger.displayName = TRIGGER_NAME;
const PORTAL_NAME = "DialogPortal", [PortalProvider, usePortalContext] = createDialogContext(
  PORTAL_NAME,
  {
    forceMount: void 0
  }
), DialogPortalFrame = styled(YStack, {
  variants: {
    unstyled: {
      false: {
        alignItems: "center",
        justifyContent: "center",
        fullscreen: !0,
        zIndex: 1e5,
        ...isWeb && {
          maxHeight: "100vh",
          position: "fixed"
        }
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), DialogPortalItem = (props) => {
  const themeName = useThemeName(), context = useDialogContext(PORTAL_NAME, props.__scopeDialog);
  return /* @__PURE__ */ jsx(PortalItem, { hostName: props.hostName, children: /* @__PURE__ */ jsx(DialogPortalItemContent, { ...props, themeName, context }) });
};
function DialogPortalItemContent(props) {
  const {
    __scopeDialog,
    children,
    context,
    themeName,
    space,
    spaceDirection,
    separator
  } = props;
  let childrenSpaced = children;
  return (space || separator) && (childrenSpaced = spacedChildren({
    children,
    separator,
    space,
    direction: spaceDirection
  })), /* @__PURE__ */ jsx(DialogProvider, { scope: __scopeDialog, ...context, children: /* @__PURE__ */ jsx(Theme, { name: themeName, children: childrenSpaced }) });
}
const DialogPortal = (props) => {
  const { __scopeDialog, forceMount, children, ...frameProps } = props, context = useDialogContext(PORTAL_NAME, __scopeDialog), isShowing = forceMount || context.open, [isFullyHidden, setIsFullyHidden] = React.useState(!isShowing);
  isShowing && isFullyHidden && setIsFullyHidden(!1);
  const contents = /* @__PURE__ */ jsx(
    AnimatePresence,
    {
      onExitComplete: () => {
        setIsFullyHidden(!0);
      },
      children: isShowing ? children : null
    }
  );
  if (useShowDialogSheet(context))
    return children;
  if (context.modal) {
    if (isFullyHidden)
      return null;
    const framedContents = /* @__PURE__ */ jsx(PortalProvider, { scope: __scopeDialog, forceMount, children: /* @__PURE__ */ jsx(DialogPortalFrame, { pointerEvents: isShowing ? "auto" : "none", ...frameProps, children: contents }) });
    return isWeb ? /* @__PURE__ */ jsx(Portal, { zIndex: props.zIndex ?? 1e5, children: /* @__PURE__ */ jsx(PassthroughTheme, { children: framedContents }) }) : /* @__PURE__ */ jsx(DialogPortalItem, { __scopeDialog, children: framedContents });
  }
  return contents;
};
DialogPortal.displayName = PORTAL_NAME;
const PassthroughTheme = ({ children }) => {
  const themeName = useThemeName();
  return /* @__PURE__ */ jsx(Theme, { name: themeName, forceClassName: !0, children });
}, OVERLAY_NAME = "DialogOverlay", DialogOverlayFrame = styled(Overlay, {
  name: OVERLAY_NAME
}), DialogOverlay = DialogOverlayFrame.extractable(
  React.forwardRef(
    ({ __scopeDialog, ...props }, forwardedRef) => {
      const portalContext = usePortalContext(OVERLAY_NAME, __scopeDialog), { forceMount = portalContext.forceMount, ...overlayProps } = props, context = useDialogContext(OVERLAY_NAME, __scopeDialog), showSheet = useShowDialogSheet(context);
      return !forceMount && (!context.modal || showSheet) ? null : /* @__PURE__ */ jsx(DialogOverlayImpl, { context, ...overlayProps, ref: forwardedRef });
    }
  )
);
DialogOverlay.displayName = OVERLAY_NAME;
const DialogOverlayImpl = React.forwardRef(
  (props, forwardedRef) => {
    const { context, ...overlayProps } = props;
    return (
      // Make sure `Content` is scrollable even when it doesn't live inside `RemoveScroll`
      // ie. when `Overlay` and `Content` are siblings
      /* @__PURE__ */ jsx(
        DialogOverlayFrame,
        {
          "data-state": getState(context.open),
          pointerEvents: context.open ? "auto" : "none",
          ...overlayProps,
          ref: forwardedRef
        }
      )
    );
  }
), CONTENT_NAME = "DialogContent", DialogContentFrame = styled(ThemeableStack, {
  name: CONTENT_NAME,
  tag: "dialog",
  variants: {
    size: {
      "...size": (val, extras) => ({})
    },
    unstyled: {
      false: {
        position: "relative",
        backgrounded: !0,
        padded: !0,
        radiused: !0,
        elevate: !0,
        zIndex: 1e5
      }
    }
  },
  defaultVariants: {
    size: "$true",
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), DialogContent = DialogContentFrame.extractable(
  React.forwardRef(
    ({ __scopeDialog, ...props }, forwardedRef) => {
      const portalContext = usePortalContext(CONTENT_NAME, __scopeDialog), { forceMount = portalContext.forceMount, ...contentProps } = props, context = useDialogContext(CONTENT_NAME, __scopeDialog), contents = context.modal ? /* @__PURE__ */ jsx(DialogContentModal, { context, ...contentProps, ref: forwardedRef }) : /* @__PURE__ */ jsx(DialogContentNonModal, { context, ...contentProps, ref: forwardedRef });
      return !isWeb || context.disableRemoveScroll ? contents : /* @__PURE__ */ jsx(
        RemoveScroll,
        {
          forwardProps: !0,
          enabled: context.open,
          allowPinchZoom: context.allowPinchZoom,
          shards: [context.contentRef],
          removeScrollBar: !1,
          children: /* @__PURE__ */ jsx("div", { "data-remove-scroll-container": !0, className: "_dsp_contents", children: contents })
        }
      );
    }
  )
);
DialogContent.displayName = CONTENT_NAME;
const DialogContentModal = React.forwardRef(
  ({ children, context, ...props }, forwardedRef) => {
    const contentRef = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, context.contentRef, contentRef);
    return isWeb && React.useEffect(() => {
      if (!context.open)
        return;
      const content = contentRef.current;
      if (content)
        return hideOthers(content);
    }, [context.open]), /* @__PURE__ */ jsx(
      DialogContentImpl,
      {
        ...props,
        context,
        ref: composedRefs,
        trapFocus: context.open,
        disableOutsidePointerEvents: !0,
        onCloseAutoFocus: composeEventHandlers(props.onCloseAutoFocus, (event) => {
          event.preventDefault(), context.triggerRef.current?.focus();
        }),
        onPointerDownOutside: composeEventHandlers(
          props.onPointerDownOutside,
          (event) => {
            const originalEvent = event.detail.originalEvent, ctrlLeftClick = originalEvent.button === 0 && originalEvent.ctrlKey === !0;
            (originalEvent.button === 2 || ctrlLeftClick) && event.preventDefault();
          }
        ),
        onFocusOutside: composeEventHandlers(
          props.onFocusOutside,
          (event) => event.preventDefault()
        ),
        ...!props.unstyled && {
          outlineStyle: "none"
        },
        children
      }
    );
  }
), DialogContentNonModal = React.forwardRef(
  (props, forwardedRef) => {
    const hasInteractedOutsideRef = React.useRef(!1);
    return /* @__PURE__ */ jsx(
      DialogContentImpl,
      {
        ...props,
        ref: forwardedRef,
        trapFocus: !1,
        disableOutsidePointerEvents: !1,
        onCloseAutoFocus: (event) => {
          props.onCloseAutoFocus?.(event), event.defaultPrevented || (hasInteractedOutsideRef.current || props.context.triggerRef.current?.focus(), event.preventDefault()), hasInteractedOutsideRef.current = !1;
        },
        onInteractOutside: (event) => {
          props.onInteractOutside?.(event), event.defaultPrevented || (hasInteractedOutsideRef.current = !0);
          const target = event.target, trigger = props.context.triggerRef.current;
          if (!(trigger instanceof HTMLElement))
            return;
          trigger.contains(target) && event.preventDefault();
        }
      }
    );
  }
), DialogContentImpl = React.forwardRef(
  (props, forwardedRef) => {
    const {
      __scopeDialog,
      trapFocus,
      onOpenAutoFocus,
      onCloseAutoFocus,
      disableOutsidePointerEvents,
      onEscapeKeyDown,
      onPointerDownOutside,
      onFocusOutside,
      onInteractOutside,
      context,
      ...contentProps
    } = props, contentRef = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, contentRef), showSheet = useShowDialogSheet(context), contents = /* @__PURE__ */ jsx(
      DialogContentFrame,
      {
        id: context.contentId,
        "aria-describedby": context.descriptionId,
        "aria-labelledby": context.titleId,
        "data-state": getState(context.open),
        ...contentProps
      }
    );
    return showSheet ? /* @__PURE__ */ jsx(DialogPortalItem, { hostName: getSheetContentsName(context), children: contentProps.children }) : isWeb ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Dismissable,
        {
          disableOutsidePointerEvents: context.open && disableOutsidePointerEvents,
          forceUnmount: !context.open,
          onEscapeKeyDown,
          onPointerDownOutside,
          onFocusOutside,
          onInteractOutside,
          ref: composedRefs,
          onDismiss: () => context.onOpenChange(!1),
          children: /* @__PURE__ */ jsx(
            FocusScope,
            {
              loop: !0,
              enabled: context.open,
              trapped: trapFocus,
              onMountAutoFocus: onOpenAutoFocus,
              forceUnmount: !context.open,
              onUnmountAutoFocus: onCloseAutoFocus,
              children: contents
            }
          )
        }
      ),
      process.env.NODE_ENV === "development" && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(TitleWarning, { titleId: context.titleId }),
        /* @__PURE__ */ jsx(
          DescriptionWarning,
          {
            contentRef,
            descriptionId: context.descriptionId
          }
        )
      ] })
    ] }) : contents;
  }
), TITLE_NAME = "DialogTitle", DialogTitleFrame = styled(H2, {
  name: TITLE_NAME
}), DialogTitle = DialogTitleFrame.styleable(
  (props, forwardedRef) => {
    const { __scopeDialog, ...titleProps } = props, context = useDialogContext(TITLE_NAME, __scopeDialog);
    return /* @__PURE__ */ jsx(DialogTitleFrame, { id: context.titleId, ...titleProps, ref: forwardedRef });
  }
);
DialogTitle.displayName = TITLE_NAME;
const DialogDescriptionFrame = styled(Paragraph, {
  name: "DialogDescription"
}), DESCRIPTION_NAME = "DialogDescription", DialogDescription = DialogDescriptionFrame.styleable(
  (props, forwardedRef) => {
    const { __scopeDialog, ...descriptionProps } = props, context = useDialogContext(DESCRIPTION_NAME, __scopeDialog);
    return /* @__PURE__ */ jsx(
      DialogDescriptionFrame,
      {
        id: context.descriptionId,
        ...descriptionProps,
        ref: forwardedRef
      }
    );
  }
);
DialogDescription.displayName = DESCRIPTION_NAME;
const CLOSE_NAME = "DialogClose", DialogCloseFrame = styled(View, {
  name: CLOSE_NAME,
  tag: "button"
}), DialogClose = DialogCloseFrame.styleable(
  (props, forwardedRef) => {
    const { __scopeDialog, displayWhenAdapted, ...closeProps } = props, context = useDialogContext(CLOSE_NAME, __scopeDialog, {
      warn: !1,
      fallback: {}
    }), isSheet = useShowDialogSheet(context), isInsideButton = React.useContext(ButtonNestingContext);
    return isSheet && !displayWhenAdapted ? null : /* @__PURE__ */ jsx(
      DialogCloseFrame,
      {
        accessibilityLabel: "Dialog Close",
        tag: isInsideButton ? "span" : "button",
        ...closeProps,
        ref: forwardedRef,
        onPress: composeEventHandlers(
          props.onPress,
          () => context.onOpenChange(!1)
        )
      }
    );
  }
);
function getState(open) {
  return open ? "open" : "closed";
}
const TITLE_WARNING_NAME = "DialogTitleWarning", [DialogWarningProvider, useWarningContext] = createContext(TITLE_WARNING_NAME, {
  contentName: CONTENT_NAME,
  titleName: TITLE_NAME,
  docsSlug: "dialog"
}), TitleWarning = ({ titleId }) => {
  if (process.env.NODE_ENV === "development") {
    const titleWarningContext = useWarningContext(TITLE_WARNING_NAME), MESSAGE = `\`${titleWarningContext.contentName}\` requires a \`${titleWarningContext.titleName}\` for the component to be accessible for screen reader users.

If you want to hide the \`${titleWarningContext.titleName}\`, you can wrap it with our VisuallyHidden component.`;
    React.useEffect(() => {
      isWeb && titleId && (document.getElementById(titleId) || console.warn(MESSAGE));
    }, [MESSAGE, titleId]);
  }
  return null;
}, DESCRIPTION_WARNING_NAME = "DialogDescriptionWarning", DescriptionWarning = ({
  contentRef,
  descriptionId
}) => {
  if (process.env.NODE_ENV === "development") {
    const MESSAGE = `Warning: Missing \`Description\` or \`aria-describedby={undefined}\` for {${useWarningContext(DESCRIPTION_WARNING_NAME).contentName}}.`;
    React.useEffect(() => {
      if (!isWeb)
        return;
      const contentNode = contentRef.current;
      if (!(contentNode instanceof HTMLElement))
        return;
      const describedById = contentNode.getAttribute("aria-describedby");
      descriptionId && describedById && (document.getElementById(descriptionId) || console.warn(MESSAGE));
    }, [MESSAGE, contentRef, descriptionId]);
  }
  return null;
}, Dialog = withStaticProperties(
  React.forwardRef(function(props, ref) {
    const {
      __scopeDialog,
      children,
      open: openProp,
      defaultOpen = !1,
      onOpenChange,
      modal = !0,
      allowPinchZoom = !1,
      disableRemoveScroll = !1
    } = props, baseId = React.useId(), scopeId = `scope-${baseId}`, contentId = `content-${baseId}`, titleId = `title-${baseId}`, descriptionId = `description-${baseId}`, scopeKey = __scopeDialog ? Object.keys(__scopeDialog)[0] : scopeId, sheetContentsName = getSheetContentsName({ scopeKey, contentId }), triggerRef = React.useRef(null), contentRef = React.useRef(null), [open, setOpen] = useControllableState({
      prop: openProp,
      defaultProp: defaultOpen,
      onChange: onOpenChange
    }), onOpenToggle = React.useCallback(() => {
      setOpen((prevOpen) => !prevOpen);
    }, [setOpen]), context = {
      scope: __scopeDialog,
      scopeKey,
      triggerRef,
      contentRef,
      contentId,
      titleId,
      descriptionId,
      open,
      onOpenChange: setOpen,
      onOpenToggle,
      modal,
      allowPinchZoom
    }, { when, AdaptProvider } = useAdaptParent({
      Contents: React.useCallback(
        (props2) => /* @__PURE__ */ jsx(PortalHost, { forwardProps: props2, name: sheetContentsName }),
        [sheetContentsName]
      )
    });
    return React.useImperativeHandle(
      ref,
      () => ({
        open: setOpen
      }),
      [setOpen]
    ), /* @__PURE__ */ jsx(AdaptProvider, { children: /* @__PURE__ */ jsx(
      DialogProvider,
      {
        ...context,
        sheetBreakpoint: when,
        disableRemoveScroll,
        children: /* @__PURE__ */ jsx(DialogSheetController, { onOpenChange: setOpen, __scopeDialog, children })
      }
    ) });
  }),
  {
    Trigger: DialogTrigger,
    Portal: DialogPortal,
    Overlay: DialogOverlay,
    Content: DialogContent,
    Title: DialogTitle,
    Description: DialogDescription,
    Close: DialogClose,
    Sheet: Sheet.Controlled,
    Adapt
  }
), SHEET_CONTENTS_NAME = "DialogSheetContents", DialogSheetContents = ({
  name,
  ...props
}) => /* @__PURE__ */ jsx(PortalHost, { forwardProps: props, name });
DialogSheetContents.displayName = SHEET_CONTENTS_NAME;
const getSheetContentsName = ({
  scopeKey,
  contentId
}) => `${scopeKey || contentId}SheetContents`, DialogSheetController = (props) => {
  const context = useDialogContext("DialogSheetController", props.__scopeDialog), showSheet = useShowDialogSheet(context), breakpointActive = useSheetBreakpointActive(context), getShowSheet = useGet(showSheet);
  return /* @__PURE__ */ jsx(
    SheetController,
    {
      onOpenChange: (val) => {
        getShowSheet() && props.onOpenChange(val);
      },
      open: context.open,
      hidden: breakpointActive === !1,
      children: props.children
    }
  );
}, useSheetBreakpointActive = (context) => {
  const media = useMedia();
  return context.sheetBreakpoint ? context.sheetBreakpoint === !0 ? !0 : media[context.sheetBreakpoint] : !1;
}, useShowDialogSheet = (context) => {
  const breakpointActive = useSheetBreakpointActive(context);
  return context.open === !1 ? !1 : breakpointActive;
};
export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogOverlayFrame,
  DialogPortal,
  DialogPortalFrame,
  DialogSheetContents,
  DialogTitle,
  DialogTrigger,
  DialogWarningProvider,
  createDialogScope
};
