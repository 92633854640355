import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { Slot, createStyledContext } from "@tamagui/core";
import React from "react";
import { jsx } from "react/jsx-runtime";
function createCollection(name) {
  const { Provider: CollectionProviderImpl, useStyledContext: useCollectionContext } = createStyledContext({
    collectionRef: { current: null },
    itemMap: /* @__PURE__ */ new Map()
  }), CollectionProvider = (props) => {
    const { __scopeCollection, children } = props, ref = React.useRef(null), itemMap = React.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ jsx(
      CollectionProviderImpl,
      {
        scope: __scopeCollection,
        itemMap,
        collectionRef: ref,
        children
      }
    );
  };
  CollectionProvider.displayName = "CollectionProvider";
  const COLLECTION_SLOT_NAME = name + "CollectionSlot", CollectionSlot = React.forwardRef((props, forwardedRef) => {
    const { __scopeCollection, children } = props, context = useCollectionContext(__scopeCollection), composedRefs = useComposedRefs(forwardedRef, context.collectionRef);
    return /* @__PURE__ */ jsx(Slot, { ref: composedRefs, children });
  });
  CollectionSlot.displayName = COLLECTION_SLOT_NAME;
  const ITEM_SLOT_NAME = name + "CollectionItemSlot", ITEM_DATA_ATTR = "data-collection-item", CollectionItemSlot = React.forwardRef((props, forwardedRef) => {
    const { __scopeCollection, children, ...itemData } = props, ref = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, ref), context = useCollectionContext(__scopeCollection);
    return React.useEffect(() => (context.itemMap.set(ref, { ref, ...itemData }), () => void context.itemMap.delete(ref))), /* @__PURE__ */ jsx(Slot, { [ITEM_DATA_ATTR]: "", ref: composedRefs, children });
  });
  CollectionItemSlot.displayName = ITEM_SLOT_NAME;
  function useCollection(__scopeCollection) {
    const context = useCollectionContext(__scopeCollection);
    return React.useCallback(() => {
      if (!isWeb)
        return [];
      const collectionNode = context.collectionRef.current;
      if (!collectionNode)
        return [];
      const orderedNodes = Array.from(
        collectionNode.querySelectorAll(`[${ITEM_DATA_ATTR}]`)
      );
      return Array.from(context.itemMap.values()).sort(
        (a, b) => orderedNodes.indexOf(a.ref.current) - orderedNodes.indexOf(b.ref.current)
      );
    }, [context.collectionRef, context.itemMap]);
  }
  return [
    { Provider: CollectionProvider, Slot: CollectionSlot, ItemSlot: CollectionItemSlot },
    useCollection
  ];
}
export {
  createCollection
};
