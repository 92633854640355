const pseudoDescriptorsBase = {
  // order of keys here important! in priority order
  hoverStyle: {
    name: "hover",
    priority: 1
  },
  pressStyle: {
    name: "active",
    stateKey: "press",
    priority: 2
  },
  focusStyle: {
    name: "focus",
    priority: 3
  }
}, pseudoPriorities = {
  hover: 1,
  press: 2,
  focus: 3
}, pseudoDescriptors = {
  ...pseudoDescriptorsBase,
  enterStyle: {
    name: "enter",
    selector: ".t_unmounted",
    priority: 4
  },
  exitStyle: {
    name: "exit",
    priority: 5
  }
};
export {
  pseudoDescriptors,
  pseudoDescriptorsBase,
  pseudoPriorities
};
