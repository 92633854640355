import { composeRefs } from "@tamagui/compose-refs";
import { ScrollView } from "@tamagui/scroll-view";
import { forwardRef, useMemo, useRef } from "react";
import { useSheetContext } from "./SheetContext";
import { jsx } from "react/jsx-runtime";
const SHEET_SCROLL_VIEW_NAME = "SheetScrollView", SheetScrollView = forwardRef(
  ({ __scopeSheet, children, onScroll, ...props }, ref) => {
    const context = useSheetContext(SHEET_SCROLL_VIEW_NAME, __scopeSheet), { scrollBridge } = context, scrollRef = useRef(null), state = useRef({
      lastPageY: 0,
      dragAt: 0,
      dys: [],
      // store a few recent dys to get velocity on release
      isScrolling: !1,
      isDragging: !1
    }), release = () => {
      if (!state.current.isDragging)
        return;
      state.current.isDragging = !1, scrollBridge.scrollStartY = -1, state.current.isScrolling = !1;
      let vy = 0;
      if (state.current.dys.length) {
        const recentDys = state.current.dys.slice(-10);
        vy = (recentDys.length ? recentDys.reduce((a, b) => a + b, 0) : 0) / recentDys.length * 0.04;
      }
      state.current.dys = [], scrollBridge.release({
        dragAt: state.current.dragAt,
        vy
      });
    };
    return /* @__PURE__ */ jsx(
      ScrollView,
      {
        ref: composeRefs(scrollRef, ref),
        flex: 1,
        scrollEventThrottle: 8,
        onScroll: (e) => {
          const { y } = e.nativeEvent.contentOffset;
          scrollBridge.y = y, y > 0 && (scrollBridge.scrollStartY = -1), onScroll?.(e);
        },
        onStartShouldSetResponder: () => (scrollBridge.scrollStartY = -1, state.current.isDragging = !0, !0),
        onMoveShouldSetResponder: () => !1,
        onResponderRelease: release,
        className: "_ovs-contain",
        ...props,
        children: useMemo(() => children, [children])
      }
    );
  }
);
export {
  SheetScrollView
};
