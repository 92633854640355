import { ImageStyle, TextStyle, ViewStyle } from 'react-native'

export const lightComponents: Record<
  string,
  (ViewStyle | TextStyle | ImageStyle) & {
    variants?: Record<string, ViewStyle | TextStyle | ImageStyle>
  }
> = {
  Global: {
    backgroundColor: 'grey.1',
  },
  Text: {
    color: 'grey.8',
    fontFamily: 'Inter',
    fontWeight: '400',
    WebkitFontSmoothing: 'antialiased',

    variants: {
      homeHeroMain: {
        fontFamily: 'AtomicMarker, Inter',

        fontVariantLigatures: 'none',
        color: 'grey.0',
        textShadowColor: 'rgba(27, 19, 64, 0.6)',
        textShadowOffset: { width: 0, height: 3 },
        textShadowRadius: 8,
      },
      homeHero: {
        fontFamily: 'AtomicMarker, Inter',

        fontVariantLigatures: 'none',
        color: 'grey.0',
        textShadowColor: 'rgba(27, 19, 64, 0.1)',
        textShadowOffset: { width: 0, height: 3 },
        textShadowRadius: 6,
        lineHeight: 8,
      },
      homeHeroCopy: {
        fontFamily: 'Inter',
        fontWeight: '600',
        color: 'grey.0',
        textShadowColor: 'rgba(27, 19, 64, 0.1)',
        textShadowOffset: { width: 0, height: 3 },
        textShadowRadius: 6,
        lineHeight: 8,
      },
      homeGuildsHeading: {
        color: '#5533EB',
        fontFamily: 'Inter',
        fontWeight: '600',
      },
      homeEventsHeading: {
        color: '#F26AF4',
        fontFamily: 'Inter',
        fontWeight: '600',
      },
      homePresentationsHeading: {
        color: '#F9894B',
        fontFamily: 'Inter',
        fontWeight: '600',
      },
      homeGuildsCheckmark: {
        display: 'flex',
        width: 4.5,
        minWidth: 4.5,
        height: 4.5,
        minHeight: 4.5,
        backgroundColor: 'rgba(85, 51, 235, 0.2)',
        color: '#5533EB',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      },
      homeEventsCheckmark: {
        display: 'flex',
        width: 4.5,
        minWidth: 4.5,
        height: 4.5,
        minHeight: 4.5,
        backgroundColor: 'rgba(242, 106, 244, 0.2)',
        color: '#F26AF4',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      },
      homePresentationsCheckmark: {
        display: 'flex',
        width: 4.5,
        minWidth: 4.5,
        height: 4.5,
        minHeight: 4.5,
        backgroundColor: 'rgba(249, 137, 75, 0.2)',
        color: '#F9894B',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      },
      marker: {
        fontFamily: 'AtomicMarker, Inter',
        paddingHorizontal: 3,

        background:
          'linear-gradient(89.31deg, #9A16C8 25.81%, #F880C1 52.29%, #FF8642 76.81%)',

        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',

        backgroundClip: 'text',
      },
      markerExtras: {
        fontFamily: 'AtomicMarkerExtras',
      },
      hero: { fontFamily: 'Inter', fontWeight: '700' },
      heroLight: { fontFamily: 'Inter', fontWeight: '700', color: 'grey.0' },
      heading: { fontFamily: 'Inter', fontWeight: '600' },
      headingLight: { fontFamily: 'Inter', fontWeight: '600', color: 'grey.0' },
      subHeading: { fontFamily: 'Inter', fontWeight: '500' },
      subHeadingLight: {
        fontFamily: 'Inter',
        fontWeight: '500',
        color: 'grey.0',
      },
      cardHeading: { color: 'grey.7', fontFamily: 'Inter', fontWeight: '500' },
      // subNav: { borderColor: 'grey.8', borderWidth: 0.5 },
      label: { fontFamily: 'Inter', fontWeight: '300' },
      caption: { color: 'grey.7' },
      captionLight: { color: 'grey.2' },
      button: {
        color: 'grey.1',
        fontFamily: 'Inter',
        fontWeight: '600',
        lineHeight: 9,
        textShadowColor: 'rgba(0,0,0,0.3)',
        textShadowOffset: { width: 0, height: 1 },
        textShadowRadius: 4,
      },
      buttonSecondary: {
        color: 'grey.8',
        fontFamily: 'Inter',
        fontWeight: '600',
        lineHeight: 9,
      },
      buttonSecondaryHover: {
        color: 'grey.8',
        fontFamily: 'Inter',
        fontWeight: '600',
        lineHeight: 9,
      },
      callToActionButton: {
        color: 'grey.7',
        fontFamily: 'Inter',
        fontWeight: '500',
      },
      notificationIcon: { color: 'grey.1' },
      subNav: { color: 'grey.8', fontFamily: 'Inter', fontWeight: '400' },
      subNavFocused: {
        color: 'darkBlue.10',
        fontFamily: 'Inter',
        fontWeight: '600',
      },
      highlight: {
        color: 'darkBlue.9',
        fontFamily: 'Inter',
        fontWeight: '600',
      },
      faded: {
        color: 'grey.6',
        fontFamily: 'Inter',
        fontWeight: '600',
      },
      backgroundHighlight: {
        fontFamily: 'Inter',
        fontWeight: '600',
        backgroundColor: 'lightBlue.9',
        borderRadius: 4,
        paddingVertical: 1,
        paddingHorizontal: 3,
        color: 'grey.0',
      },
      backgroundHighlightDark: {
        fontFamily: 'Inter',
        fontWeight: '600',
        backgroundColor: 'darkBlue.9',
        borderRadius: 4,
        paddingVertical: 1,
        paddingHorizontal: 3,
        color: 'grey.0',
      },
      backgroundFaded: {
        fontFamily: 'Inter',
        fontWeight: '600',
        backgroundColor: 'grey.2',
        borderRadius: 4,
        paddingVertical: 1,
        paddingHorizontal: 3,
        color: 'grey.7',
      },
      icon: {
        width: 5,
      },
      presenterIcon: {
        backgroundColor: 'grey.2',
        borderRadius: 4,
        borderColor: 'grey.4',
        borderWidth: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 5,
        height: 5,
        position: 'absolute',
        top: 5,
        left: 5,
      },
      onSurface: {
        color: 'grey.0',
      },
      badge: {
        marginTop: 0,
        color: 'grey.0',
        fontFamily: 'Inter',
        fontWeight: '700',
      },
      footer: {
        fontFamily: 'Inter',
        fontWeight: '500',
        color: 'grey.7',
      },
      footerPlatform: {
        fontFamily: 'Inter',
        fontWeight: '500',
        color: 'grey.6',
      },
      footerHero: {
        fontFamily: 'Inter',
        fontWeight: '700',
        color: 'grey.7',
      },
      emphasis: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontStyle: 'italic',
      },
      formHint: {
        fontFamily: 'Inter',
        fontStyle: 'italic',
        fontWeight: '300',
      },
      errorBox: {
        padding: 4,
        backgroundColor: 'red.1',
        color: 'red.10',
        borderRadius: 4,
      },
      errorBoxText: {
        color: 'red.10',
      },
      listItem: {
        paddingLeft: 4,
      },
      calendarDayFaded: {
        color: 'grey.6',
      },
      homeScreenSelectedGuilds: {
        maxWidth: 20,
      },
      noWrap: {
        whiteSpace: 'nowrap',
      },
      error: {
        fontFamily: 'Inter',
        fontWeight: '600',
        color: 'red.10',
      },
    },
  },
  LinkText: {
    color: 'darkBlue.10',
    fontFamily: 'Inter',
    fontWeight: '400',
    textDecorationLine: 'none',
    borderBottomColor: 'darkBlue.10',
    borderBottomWidth: 0.25,

    variants: {
      hero: {
        fontFamily: 'Inter',
        fontWeight: '700',
        borderBottomWidth: 0,
      },
      heading: {
        fontFamily: 'Inter',
        fontWeight: '600',
        borderBottomWidth: 0,
      },
      subHeading: {
        fontFamily: 'Inter',
        fontWeight: '500',
        borderBottomWidth: 0,
      },
      userBlockHeading: {
        fontFamily: 'Inter',
        fontWeight: '500',
        borderBottomWidth: 0,
      },
      noUnderline: { borderBottomWidth: 0 },
      markdown: {
        fontFamily: 'inherit',
        borderBottomWidth: 0,
        wordBreak: 'break-all',
      },
      navigation: { color: 'grey.8', borderBottomWidth: 0 },
      navigationSelected: {
        color: 'grey.8',
        // borderBottomWidth: 0,
        paddingTop: 1,
        paddingBottom: 0.5,
        borderBottomWidth: 0.5,
        borderBottomColor: 'darkBlue.10',
      },
      onSurface: {
        color: 'grey.0',
        borderBottomColor: 'grey.0',
      },
      errorBoxText: {
        color: 'red.10',
        borderBottomColor: 'red.10',
      },
      label: {
        fontFamily: 'Inter',
        fontWeight: '300',
      },
      caption: {
        color: 'grey.7',
        borderBottomColor: 'grey.7',
      },
    },
  },
  Button: {
    cursor: 'pointer',
    userSelect: 'none',
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 6,
    alignItems: 'center',
    // borderColor: 'darkBlue.10',
    borderColor: 'rgba(0, 0, 0, 0.15)',
    borderWidth: 0.25,

    linearGradientColorsFrom: '#6E33EB',
    linearGradientColorsTo: '#5533EB',

    loadingColor: '#FFFFFF',

    variants: {
      hover: {
        linearGradientColorsFrom: '#4F14CC',
        linearGradientColorsTo: '#3F1FCC',

        borderColor: 'rgba(0, 0, 0, 0.15)',
      },
      secondary: {
        linearGradientColorsFrom: '#F6F8F9',
        linearGradientColorsTo: '#E9E7F1',

        // borderColor: 'rgba(27, 19, 64, 0.05)',

        loadingColor: 'grey.8',
        borderColor: '#E2E1EB',

        // backgroundColor: 'lightBlue.1',
        // borderColor: 'transparent',
      },
      secondaryHover: {
        linearGradientColorsFrom: '#E9E7F8',
        linearGradientColorsTo: '#D3CFE8',

        // borderColor: 'rgba(27, 19, 64, 0.05)',

        loadingColor: 'grey.8',
        borderColor: '#D5D2EB',
      },
      sideNav: {
        borderColor: 'transparent',
        borderRadius: 0,
        borderWidth: 0,
        backgroundColor: 'none',
        alignItems: undefined,
        height: 9,
      },
      subNav: {
        backgroundColor: 'transparent',

        borderWidth: 0,
        borderRadius: 6,
        // borderColor: 'grey.2',
        // borderWidth: 0.25,
        alignItems: 'flex-start',
        paddingVertical: 2,
        paddingHorizontal: 3,
        // shadowColor: 'grey.2',
        // shadowRadius: 3,
      },
      subNavFocused: {
        backgroundColor: 'lightBlue.1',
        // borderRadius: 0,
        // borderColor: 'grey.2',
        // borderWidth: 0.25,
        alignItems: 'flex-start',
        paddingVertical: 2,
        paddingHorizontal: 3,
        borderWidth: 0,
        // shadowColor: 'grey.2',
        // shadowRadius: 3,
      },
      invisible: {
        borderColor: 'transparent',
        borderRadius: 0,
        borderWidth: 0,
        backgroundColor: 'none',
        alignItems: undefined,
      },
      invisibleCompact: {
        alignItems: undefined,
        backgroundColor: 'none',
        borderColor: 'transparent',
        borderRadius: 0,
        borderWidth: 0,
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
      invisibleVerticalCompact: {
        alignItems: undefined,
        backgroundColor: 'none',
        borderColor: 'transparent',
        borderRadius: 0,
        borderWidth: 0,
        paddingVertical: 0,
      },
      callToAction: {
        marginBottom: -4,
        backgroundColor: 'grey.0',
        borderColor: 'grey.3',
        borderTopWidth: 0.5,
        borderWidth: 0,
        borderRadius: 0,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        paddingVertical: 3,
        paddingHorizontal: 4,
      },
      callToActionHover: {
        marginBottom: -4,
        backgroundColor: 'grey.1',
        borderColor: 'grey.3',
        borderTopWidth: 0.5,
        borderWidth: 0,
        borderRadius: 0,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        paddingVertical: 3,
        paddingHorizontal: 4,
      },
      backgroundHighlight: {
        loadingColor: '#FFFFFF',
        linearGradientColorsFrom: '#6E33EB',
        linearGradientColorsTo: '#5533EB',
        paddingVertical: 0.5,
        paddingHorizontal: 3,
      },
      backgroundHighlightHover: {
        loadingColor: '#FFFFFF',
        linearGradientColorsFrom: '#4F14CC',
        linearGradientColorsTo: '#3F1FCC',

        borderColor: 'rgba(0, 0, 0, 0.15)',

        paddingVertical: 0.5,
        paddingHorizontal: 3,
      },
      dropDown: {
        paddingVertical: 'unset',
        paddingHorizontal: 'unset',
        borderColor: 'rgba(0, 0, 0, 0.15)',
        borderWidth: 0.25,

        linearGradientColorsFrom: '#6E33EB',
        linearGradientColorsTo: '#5533EB',
      },
      dropDownHover: {
        paddingVertical: 'unset',
        paddingHorizontal: 'unset',
        linearGradientColorsFrom: '#4F14CC',
        linearGradientColorsTo: '#3F1FCC',
        borderWidth: 0.25,
        borderColor: 'rgba(0, 0, 0, 0.15)',
      },
      dropDownSecondary: {
        paddingVertical: 'unset',
        paddingHorizontal: 'unset',
        // backgroundColor: 'lightBlue.1',

        linearGradientColorsFrom: '#F6F8F9',
        linearGradientColorsTo: '#E9E7F1',
        borderColor: 'rgba(27, 19, 64, 0.05)',
      },
      dropDownSecondaryHover: {
        paddingVertical: 'unset',
        paddingHorizontal: 'unset',

        linearGradientColorsFrom: '#E9E7F8',
        linearGradientColorsTo: '#D3CFE8',
        borderColor: 'rgba(27, 19, 64, 0.05)',
      },
      calendarDay: {
        width: 6,
        height: 6,
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        paddingVertical: 0,
        paddingHorizontal: 0,
        alignItems: 'center',
        borderRadius: 2,
      },
      calendarDaySelected: {
        width: 6,
        height: 6,
        backgroundColor: 'lightBlue.10',
        // backgroundColor: 'lightBlue.1',
        borderColor: 'lightBlue.10',
        borderRadius: 2,
        paddingVertical: 0,
        paddingHorizontal: 0,
        alignItems: 'center',
      },
      calendarDayStart: {
        width: 6,
        height: 6,
        backgroundColor: 'lightBlue.10',
        // backgroundColor: 'lightBlue.1',
        borderColor: 'lightBlue.10',
        borderRadius: 0,
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        paddingVertical: 0,
        paddingHorizontal: 0,
        alignItems: 'center',
      },
      calendarDayEnd: {
        width: 6,
        height: 6,
        backgroundColor: 'lightBlue.10',
        // backgroundColor: 'lightBlue.1',
        borderColor: 'lightBlue.10',
        borderRadius: 0,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        paddingVertical: 0,
        paddingHorizontal: 0,
        alignItems: 'center',
      },
      calendarDayBetween: {
        width: 6,
        height: 6,
        // backgroundColor: 'darkBlue.10',
        backgroundColor: 'lightBlue.10',
        borderColor: 'transparent',
        borderRadius: 0,
        paddingVertical: 0,
        paddingHorizontal: 0,
        alignItems: 'center',
      },
      calendarDayOutsideMonth: {
        width: 6,
        height: 6,
        backgroundColor: '#ffffff',
        borderColor: 'transparent',
        paddingVertical: 0,
        paddingHorizontal: 0,
        alignItems: 'center',
      },
      success: {
        linearGradientColorsFrom: '#1A9E1A',
        linearGradientColorsTo: '#1A9E1A',

        borderColor: '#1A9E1A',
      },
      error: {
        linearGradientColorsFrom: '#E14747',
        linearGradientColorsTo: '#E14747',

        borderColor: '#E14747',
      },
      homeHero: {
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 8,
        linearGradientColorsFrom: '#6E33EB',
        linearGradientColorsTo: '#5533EB',
        boxShadow:
          '0px 2px 6px rgba(27, 19, 64, 0.15), inset 0px -2px 3px rgba(0, 0, 0, 0.25)',
      },
      homeHeroHover: {
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 8,
        linearGradientColorsFrom: '#4F14CC',
        linearGradientColorsTo: '#3F1FCC',
        boxShadow:
          '0px 2px 6px rgba(27, 19, 64, 0.15), inset 0px -2px 3px rgba(0, 0, 0, 0.25)',
      },
      icon: {
        borderColor: 'rgba(27, 19, 64, 0.05)',
        linearGradientColorsFrom: '#F6F8F9',
        linearGradientColorsTo: '#E9E7F1',
        paddingVertical: 0,
        paddingHorizontal: 0,
        width: 8,
        height: 8,
      },
      iconHover: {
        borderColor: 'rgba(27, 19, 64, 0.05)',
        linearGradientColorsFrom: '#E9E7F8',
        linearGradientColorsTo: '#D3CFE8',
        paddingVertical: 0,
        paddingHorizontal: 0,
        width: 8,
        height: 8,
      },
      dynamicIsland: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'grey.3',
        borderColor: 'grey.3',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      dynamicIslandHover: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: '#DFD9FB',
        borderColor: '#DFD9FB',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 0,
        paddingHorizontal: 0,
      },
      delete: {
        linearGradientColorsFrom: '#E14747',
        linearGradientColorsTo: '#E14747',

        borderColor: '#E14747',
      },
      deleteHover: {
        linearGradientColorsFrom: '#990000',
        linearGradientColorsTo: '#990000',

        borderColor: '#990000',
      },
    },
  },
  Image: {
    variants: {
      userProfilePictureLarge: {
        borderRadius: '50%',
        backgroundColor: 'grey.0',
        boxShadow: '0 0 0 3px #FFFFFF',
        borderWidth: 0.25,
        borderColor: 'rgba(19, 0, 102, 0.16)',
      },
      userProfilePictureSmall: {
        borderRadius: '50%',
        backgroundColor: 'grey.0',
        boxShadow: '0 0 0 1px #FFFFFF',
        borderWidth: 0.25,
        borderColor: 'rgba(19, 0, 102, 0.16)',
      },
    },
  },
  GraphQLImage: {
    variants: {
      contentCentered: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  Card: {
    padding: 4,
    backgroundColor: 'grey.0',
    borderRadius: 6,
    // shadowColor: 'grey.3',
    shadowColor: 'rgba(10, 37, 64, 0.08)',
    shadowRadius: 3,

    variants: {
      main: {
        padding: 6,
      },
      eventDescriptionMain: {
        paddingVertical: 4,
        paddingHorizontal: 6,
        // paddingBottom: 6,
      },
      noHorizontalPadding: {
        paddingHorizontal: 0,
      },
      authCard: {
        padding: 0,
      },
      homeScreen: {
        padding: 6,
        borderRadius: 8,
        boxShadow:
          '0px 100px 80px rgba(85, 51, 235, 0.07), 0px 41.7776px 33.4221px rgba(85, 51, 235, 0.0503198), 0px 22.3363px 17.869px rgba(85, 51, 235, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 51, 235, 0.035), 0px 6.6501px 5.32008px rgba(85, 51, 235, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 51, 235, 0.0196802)',
      },
      homeScreenContent: {
        padding: 6,

        overflow: 'hidden',
        borderColor: 'rgba(85, 51, 235, 0.2)',
        borderWidth: 0.25,
        borderRadius: 8,
        boxShadow:
          '0px 100px 80px rgba(85, 51, 235, 0.07), 0px 41.7776px 33.4221px rgba(85, 51, 235, 0.0503198), 0px 22.3363px 17.869px rgba(85, 51, 235, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 51, 235, 0.035), 0px 6.6501px 5.32008px rgba(85, 51, 235, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 51, 235, 0.0196802)',
      },
      homeScreenGuildContent: {
        background:
          'radial-gradient(109.69% 142.8% at 63.2% -62.91%, rgba(249, 96, 124, 0.9) 0%, rgba(216, 61, 229, 0.9) 33.33%, rgba(95, 4, 217, 0.9) 100%)',
        padding: 6,
        overflow: 'hidden',
        borderColor: 'rgba(85, 51, 235, 0.2)',
        borderWidth: 0.25,
        borderRadius: 8,
        boxShadow:
          '0px 100px 80px rgba(85, 51, 235, 0.07), 0px 41.7776px 33.4221px rgba(85, 51, 235, 0.0503198), 0px 22.3363px 17.869px rgba(85, 51, 235, 0.0417275), 0px 12.5216px 10.0172px rgba(85, 51, 235, 0.035), 0px 6.6501px 5.32008px rgba(85, 51, 235, 0.0282725), 0px 2.76726px 2.21381px rgba(85, 51, 235, 0.0196802)',
      },
      authenticatedHomeScreen: {
        padding: 6,
        marginTop: [8, 0],
      },
    },
  },
  Screen: {
    marginTop: 0,
    paddingTop: 21,
    paddingBottom: [5, 20],
    paddingHorizontal: [5, 5, 8],
    backgroundColor: 'grey.1',
    flexGrow: 1,
    maxWidth: ['100%', '100%', 300],
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',

    variants: {
      authenticatedHomeScreen: {
        paddingTop: 'unset',
      },
      unauthenticatedHomeScreen: {
        // backgroundColor: 'grey.0',
        paddingTop: 9,
      },
      eventScreenCoverPhoto: {
        marginTop: -6,
        paddingTop: 0,
        backgroundColor: 'unset',
      },
      eventScreenCoverPhotoMobile: {
        paddingTop: 4,
        backgroundColor: 'unset',
      },
      gradientBackgroundScreen: {
        // paddingTop: [90, 64],
        backgroundColor: 'unset',
        maxWidth: 120,
        // paddingBottom: 20,
      },
    },
  },
  DrawerContent: {
    padding: 5,
    backgroundColor: 'grey.2',
    shadowColor: 'grey.6',
    shadowRadius: 10,
  },
  Modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(20, 20, 20, 0.2)',
    // backgroundColor: 'grey.5',
  },
  ModalWrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
    width: ['100%', 120, 180],
    minWidth: 100,
    // margin: [2, 5, 10],
    borderRadius: 6,
    shadowColor: 'grey.6',
    shadowRadius: 2,
    cursor: 'default',
    variants: {
      narrow: { maxWidth: 120 },
      authModal: { maxWidth: 120, padding: 0 },
    },
  },
  ModalContents: {
    backgroundColor: 'grey.0',
    padding: 6,
    borderRadius: 6,
    overflow: 'hidden',
    variants: { authModal: { padding: 0 } },
  },
  ModalCloseButton: {
    position: 'absolute',
    top: -1,
    right: -1,
    backgroundColor: 'grey.0',
    shadowColor: 'grey.9',
    shadowRadius: 1,
    borderRadius: 4,
    width: 5,
    height: 5,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1,
  },
  Icon: {
    color: 'grey.8',
    variants: {
      button: {
        color: 'grey.0',
      },
      captionLight: {
        color: 'grey.2',
      },
      callToActionButton: {
        color: 'grey.7',
      },
      buttonSecondary: {
        color: 'darkBlue.9',
      },
      dropDownOption: {
        color: 'grey.7',
      },
      subNav: { color: 'grey.8' },
      subNavFocused: { color: 'darkBlue.10' },
      badgeIcon: {
        color: 'grey.0',
        borderColor: 'grey.0',
        borderWidth: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        borderRadius: '50%',
        width: 9,
        height: 9,
        background:
          'linear-gradient(0deg, #5533EB 28.13%, rgba(85, 51, 235, 0) 159.37%), #FFFFFF',
        boxShadow: 'inset 0px 0px 0px 3px rgba(27, 19, 64, 0.2)',
        padding: 2.5,
      },
      guildBadge: {
        padding: 3,
        borderRadius: '50%',
        color: 'grey.0',
        // backgroundColor: 'darkBlue.9',
        background:
          'linear-gradient(0deg, #5533EB 28.13%, rgba(85, 51, 235, 0) 159.37%), #FFFFFF',
        boxShadow: 'inset 0px 0px 0px 3px rgba(27, 19, 64, 0.2)',
        width: 12,
        height: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      presenterIcon: {
        color: 'grey.0',
        borderRadius: 4,
        borderColor: 'grey.4',
        borderWidth: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 5,
        height: 5,
      },
      formatToolbar: {
        color: 'grey.7',
      },
      formatToolbarActive: {
        color: 'grey.8',
      },
      formatToolbarDropdown: {
        color: 'grey.7',
        marginRight: 2,
      },
      formatToolbarDropdownActive: {
        color: 'grey.8',
        marginRight: 2,
      },
      modalCloseButton: {
        display: 'flex',
        alignItems: 'center',
      },
      networkVisual: {
        mixBlendMode: 'overlay',
        color: '#1B1340',
        filter: 'drop-shadow(0 2px 1px rgba(255, 255, 255, 0.5))',
      },
      networkVisualText: {
        color: 'grey.0',
        textAlignVertical: 'text-top',
      },
      footerIcon: {
        color: 'grey.6',
      },
      error: {
        color: 'red.10',
      },
    },
  },
  HorizontalLine: {
    borderColor: 'grey.6',
    flex: 1,

    variants: {
      light: {
        borderColor: 'grey.4',
      },
      cardHeading: {
        borderColor: 'grey.4',
      },
      homeScreenFeatures: {
        borderColor: 'grey.6',
        width: 15,
      },
    },
  },
  VerticalLine: {
    borderColor: 'grey.6',
    flex: 1,
    // display: 'flex',
    // flexDirection: 'column',

    variants: {
      light: {
        borderColor: 'grey.4',
      },
      cardHeading: {
        borderColor: 'grey.3',
      },
    },
  },
  FooterDesktop: {
    backgroundColor: 'grey.3',
    borderRadius: 4.5,
    paddingVertical: 4,
    paddingHorizontal: 5,
    width: '392px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '46px',
  },
  FooterMobile: {
    backgroundColor: 'grey.3',
    paddingVertical: 4,
    paddingHorizontal: 5,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  FormField: {
    cursor: 'pointer',
  },
  FormFieldLabel: {
    color: 'grey.8',
    fontFamily: 'Inter',
    fontWeight: '300',
    letterSpacing: -0.1,
  },
  FormFieldError: {
    fontFamily: 'Inter',
    fontWeight: '500',
    color: 'red.10',
    // paddingLeft: 3,
    variants: {
      center: {
        textAlign: 'center',
      },
    },
  },
  FormInputWrapper: {
    padding: 2,
    borderWidth: 0.25,
    borderRadius: 2,
  },
  FormTextInput: {
    fontFamily: 'Inter',
    fontWeight: '400',
    color: 'grey.8',
    outlineStyle: 'none',
  },
  FormPicker: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    fontSize: 5,
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontWeight: '400',
    maxWidth: '100%',
    variants: {
      buttonDropdown: {
        width: '100%',
        paddingVertical: 2,
        paddingHorizontal: 4,
        color: 'grey.1',
        fontFamily: 'Inter',
        fontWeight: '600',
        fontSize: 4,
        textAlignLast: 'center', // center text when select is closed
        borderRightWidth: 16, // pad select chevron
        borderStyle: 'solid',
        borderColor: 'transparent',
        appearance: 'none', // allows Safari to apply padding to select tag
      },
      buttonDropdownSecondary: {
        width: '100%',
        paddingVertical: 2,
        paddingHorizontal: 4,
        fontSize: 4,
        color: 'grey.8',
        fontFamily: 'Inter',
        fontWeight: '600',
        textAlignLast: 'center', // center text when select is closed
        borderRightWidth: 16, // pad select chevron
        borderStyle: 'solid',
        borderColor: 'transparent',
        // borderWidth: 0.5,
        appearance: 'none', // allows Safari to apply padding to select tag
      },
      buttonDropdownSecondaryHover: {
        width: '100%',
        paddingVertical: 2,
        paddingHorizontal: 4,
        fontSize: 4,
        color: 'grey.8',
        fontFamily: 'Inter',
        fontWeight: '600',
        textAlignLast: 'center', // center text when select is closed
        borderRightWidth: 16, // pad select chevron
        borderStyle: 'solid',
        borderColor: 'transparent',
        // borderWidth: 0.5,
        appearance: 'none', // allows Safari to apply padding to select tag
      },
    },
  },
  FormImageUpload: {},
  FormImageUploadWeb: {},
  FormSubmitButton: {
    cursor: 'pointer',
    userSelect: 'none',
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 6,
    alignItems: 'center',
    borderColor: 'darkBlue.10',
    borderWidth: 0.5,

    linearGradientColorsFrom: '#6E33EB',
    linearGradientColorsTo: '#5533EB',

    variants: {
      hover: {
        linearGradientColorsFrom: '#4F14CC',
        linearGradientColorsTo: '#3F1FCC',

        borderColor: 'rgba(0, 0, 0, 0.15)',
      },
      disabled: {
        linearGradientColorsFrom: '#FFFFFF',
        linearGradientColorsTo: '#F6F8F9',

        borderColor: 'grey.2',
      },
      success: {
        linearGradientColorsFrom: '#1A9E1A',
        linearGradientColorsTo: '#1A9E1A',

        borderColor: '#1A9E1A',
      },
      error: {
        linearGradientColorsFrom: '#E14747',
        linearGradientColorsTo: '#E14747',

        borderColor: '#E14747',
      },
    },
  },
  FormSubmitButtonText: {
    paddingHorizontal: 6,
    color: 'grey.1',
    fontFamily: 'Inter',
    fontWeight: '600',
    variants: {
      wrap: {},

      disabled: {
        color: 'grey.6',
      },
      success: {
        color: 'grey.1',
      },
      error: {
        color: 'grey.1',
      },
    },
  },
  FormCheckBox: {},
  FormRadioButtonItem: {
    marginLeft: 2,
  },
  FormRichTextEditor: {
    minHeight: 60,

    variants: {
      short: {
        minHeight: 20,
      },
    },
  },
  Surface: {
    backgroundColor: 'lightBlue.7',
    padding: 4,

    variants: {
      roundedBottom: {
        flexGrow: 0,
        flexBasis: 'auto',
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
      formIcon: {
        backgroundColor: 'lightBlue.2',
        borderRadius: '50%',
        lineHeight: 4,
      },
      errorBox: {
        backgroundColor: 'red.1',

        borderRadius: 4,
      },
    },
  },
  ActorSelectorResults: {
    backgroundColor: 'grey.0',
    padding: 3,
    width: '100%',
    borderColor: 'grey.7',
    borderWidth: 0.5,
    borderRadius: 3,
    shadowColor: 'grey.3',
    shadowRadius: 3,
  },
  ActorCard: {
    backgroundColor: 'grey.0',
    // borderRadius: 4,
    borderRadius: 6,
    // shadowColor: 'grey.3',
    shadowColor: 'rgba(10, 37, 64, 0.1)',
    shadowRadius: 3,
    paddingBottom: 4,

    variants: {
      noPhotos: {
        paddingTop: 4,
      },
    },
  },

  ActorCardPhotos: {
    // borderRadius: 2,
    borderRadius: 6,

    variants: {
      noBackgroundPhoto: {
        paddingTop: 4,
        paddingBottom: 4,
      },
      hasBackgroundPhoto: {
        paddingBottom: 4,
      },
    },
  },

  ActorCardBackgroundPhoto: {
    // borderTopLeftRadius: 2,
    // borderTopRightRadius: 2

    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  ActorCardPrimaryPhoto: {
    backgroundColor: 'grey.0',
    boxShadow: '0 0 0 3px #FFFFFF',

    variants: {
      userLarge: {
        borderWidth: 0.25,
        borderColor: 'rgba(19, 0, 102, 0.16)',
      },
      userSmall: {
        borderWidth: 0.25,
        borderColor: 'rgba(19, 0, 102, 0.16)',
        backgroundColor: 'grey.0',
        boxShadow: '0 0 0 1px #FFFFFF',
      },
      noOuterBorder: {
        borderWidth: 0.25,
        borderColor: 'rgba(19, 0, 102, 0.16)',
        backgroundColor: 'grey.0',
        boxShadow: 'none',
      },
    },
  },
  ActorCardInfo: {
    paddingLeft: 4,
    paddingRight: 4,
    flex: 1,
  },

  UserPrimaryPhoto: {
    variants: {
      inCard: {
        marginLeft: 4,
        marginRight: 4,
      },
      noOuterBorder: {},
    },
  },

  UserDefaultPhoto: {},
  GuildCardPrimaryPhoto: {
    backgroundColor: 'grey.0',

    borderRadius: 4,
    borderWidth: 0.25,
    borderColor: 'rgba(19, 0, 102, 0.16)',

    variants: {
      inCard: {
        marginLeft: 4,
        marginRight: 4,
        boxShadow: '0 0 0 3px #FFFFFF',
      },
      large: {
        boxShadow: '0 0 0 3px #FFFFFF',
      },
      small: {
        borderRadius: 2,
        boxShadow: '0 0 0 1px #FFFFFF',
      },
    },
  },
  GuildCardInfo: {
    paddingLeft: 4,
    paddingRight: 4,
    flex: 1,
  },
  Crumb: {
    paddingHorizontal: 2,
    paddingVertical: 1,
    borderRadius: 3,
    variants: {
      private: {
        backgroundColor: 'grey.8',
      },
      public: {
        backgroundColor: 'darkBlue.8',
      },
      draft: {
        backgroundColor: 'grey.7',
      },
    },
  },
  Stack: {
    variants: {
      dropDownContainer: {
        zIndex: 9,
      },
      paddedBottom: {
        paddingBottom: 4,
      },
      wrap: {
        flexBasis: 'auto',
        flexShrink: 1,
      },
      calendarMonth: {
        maxWidth: 45.5,
      },
      fullWidth: {
        width: '100%',
      },
    },
  },
  PresentationVideoEmbed: {},
  Markdown: {
    variants: {
      list: {
        marginLeft: 3.5,
      },
    },
  },
  RadioButton: {
    borderColor: 'grey.8',
    borderWidth: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  RadioButtonInner: {
    backgroundColor: 'grey.8',
  },
  EventScreenCoverPhotoWrapper: {
    width: '100%',
    alignItems: 'center',
    marginTop: [18, 9.5],
  },
  PresentationModalDetails: {
    variants: {
      inModal: {
        padding: 4,
      },
      inScreen: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
  },
  HomeScreenWelcome: {
    backgroundColor: 'grey.0',
    borderRadius: 18,
    padding: 8,
    shadowColor: 'grey.3',
    shadowRadius: 3,
  },
  HomeScreenWelcomeText: {
    maxWidth: [50, 100],
  },
  ModalScroll: {
    borderRadius: 4,
  },
  SuspenseWrapperLoadingSpinner: {
    variants: {
      component: {
        marginTop: 4,
        marginBottom: 4,
      },
    },
  },
  DynamicIsland: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',

    variants: {
      hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
      },
    },
  },
}
