import { useMemo, useRef } from "react";
function useConstant(fn) {
  if (typeof document > "u")
    return useMemo(() => fn(), []);
  const ref = useRef();
  return ref.current || (ref.current = { v: fn() }), ref.current.v;
}
export {
  useConstant
};
