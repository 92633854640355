import { validStyles } from "@tamagui/helpers";
import { stackDefaultStyles } from "../constants/constants";
import { createComponent } from "../createComponent";
const View = createComponent({
  acceptsClassName: !0,
  defaultProps: stackDefaultStyles,
  validStyles
});
export {
  View
};
