import { getTokenObject } from "../config";
const themesRaw = {};
function proxyThemesToParents(dedupedThemes) {
  for (const { names, theme } of dedupedThemes)
    for (const name of names)
      themesRaw[name] = theme;
  const themes = {};
  for (const { names, theme } of dedupedThemes)
    for (const themeName of names) {
      const proxiedTheme = proxyThemeToParents(themeName, theme);
      themes[themeName] = proxiedTheme;
    }
  return themes;
}
function proxyThemeToParents(themeName, theme) {
  const cur = [], parents = themeName.split("_").slice(0, -1).map((part) => (cur.push(part), cur.join("_"))), numParents = parents.length;
  return new Proxy(theme, {
    get(target, key) {
      if (!key || // dont ask me, idk why but on hermes you can see that useTheme()[undefined] passes in STRING undefined to proxy
      // if someone is crazy enough to use "undefined" as a theme key then this not working is on them
      key == "undefined" || Reflect.has(target, key))
        return Reflect.get(target, key);
      for (let i = numParents - 1; i >= 0; i--) {
        const parent = themesRaw[parents[i]];
        if (parent && Reflect.has(parent, key))
          return Reflect.get(parent, key);
      }
      return getTokenObject(key);
    }
  });
}
export {
  proxyThemeToParents,
  proxyThemesToParents
};
