import { useContext } from "react";
import { getConfig } from "../config";
import { ComponentContext } from "../contexts/ComponentContext";
const useConfiguration = () => {
  const { groups, animationDriver, ...restComponentConfig } = useContext(ComponentContext), { animations, ...restConfig } = getConfig();
  return {
    ...restConfig,
    ...restComponentConfig,
    animationDriver: animationDriver ?? getConfig().animations
  };
};
export {
  useConfiguration
};
