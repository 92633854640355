import { isWeb } from "@tamagui/constants";
import React, { Children, cloneElement, forwardRef, isValidElement, useRef } from "react";
import { variableToString } from "../createVariable";
import { ThemeManagerIDContext } from "../helpers/ThemeManagerContext";
import { useChangeThemeEffect } from "../hooks/useTheme";
import { ThemeDebug } from "./ThemeDebug";
import { jsx } from "react/jsx-runtime";
const Theme = forwardRef(function({ children, ...props }, ref) {
  if (props.disable)
    return children;
  const isRoot = !!props._isRoot, themeState = useChangeThemeEffect(props, isRoot);
  let finalChildren = props["disable-child-theme"] ? Children.map(
    children,
    (child) => cloneElement(child, { "data-disable-theme": !0 })
  ) : children;
  if (ref)
    try {
      React.Children.only(finalChildren), finalChildren = cloneElement(finalChildren, { ref });
    } catch {
    }
  return process.env.NODE_ENV === "development" && props.debug === "visualize" && (finalChildren = /* @__PURE__ */ jsx(ThemeDebug, { themeState, themeProps: props, children: finalChildren })), getThemedChildren(themeState, finalChildren, props, isRoot);
});
Theme.displayName = "Theme";
Theme.avoidForwardRef = !0;
function getThemedChildren(themeState, children, props, isRoot = !1) {
  const { themeManager, isNewTheme } = themeState;
  if (!themeManager)
    throw new Error(
      process.env.NODE_ENV === "development" ? "\u274C No theme found, either incorrect name, potential duplicate tamagui deps, or TamaguiProvider not providing themes." : "\u274C 005"
    );
  const { shallow, forceClassName } = props, hasEverThemed = useRef(!1), shouldRenderChildrenWithTheme = isNewTheme || props.inverse || hasEverThemed.current || forceClassName || isRoot;
  if (shouldRenderChildrenWithTheme && (hasEverThemed.current = !0), !shouldRenderChildrenWithTheme)
    return children;
  let next = children;
  shallow && (next = Children.toArray(children).map((child) => isValidElement(child) ? cloneElement(
    child,
    void 0,
    /* @__PURE__ */ jsx(Theme, { name: themeManager.state.parentName, children: child.props.children })
  ) : child));
  const elementsWithContext = /* @__PURE__ */ jsx(ThemeManagerIDContext.Provider, { value: themeManager.id, children: next });
  return forceClassName === !1 ? elementsWithContext : isWeb ? wrapThemeElements({
    children: elementsWithContext,
    themeState,
    forceClassName,
    isRoot
  }) : elementsWithContext;
}
function wrapThemeElements({
  children,
  themeState,
  forceClassName,
  isRoot
}) {
  if (isRoot && forceClassName === !1)
    return children;
  const inverse = themeState.inversed, requiresExtraWrapper = inverse != null || forceClassName, { className, style } = getThemeClassNameAndStyle(themeState, isRoot);
  let themedChildren = /* @__PURE__ */ jsx("span", { className: `${className} _dsp_contents is_Theme`, style, children });
  if (requiresExtraWrapper) {
    const name = themeState.state?.name || "", inverseClassName = name.startsWith("light") ? "t_light is_inversed" : name.startsWith("dark") ? "t_dark is_inversed" : "";
    themedChildren = /* @__PURE__ */ jsx("span", { className: `${inverse ? inverseClassName : ""} _dsp_contents`, children: themedChildren });
  }
  return themedChildren;
}
const emptyObj = {};
function getThemeClassNameAndStyle(themeState, isRoot = !1) {
  if (!themeState.isNewTheme)
    return { className: "", style: emptyObj };
  const themeColor = themeState.state?.theme && themeState.isNewTheme ? variableToString(themeState.state.theme.color) : "", style = themeColor ? {
    color: themeColor
  } : void 0;
  let className = themeState.state?.className || "";
  return isRoot && (className = className.replace("t_sub_theme", "")), { style, className };
}
export {
  Theme,
  getThemedChildren
};
