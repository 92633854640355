import { getVariableValue, styled } from "@tamagui/core";
import { createContextScope } from "@tamagui/create-context";
import { getSize } from "@tamagui/get-token";
import { withStaticProperties } from "@tamagui/helpers";
import { ThemeableStack } from "@tamagui/stacks";
import * as React from "react";
import { jsx } from "react/jsx-runtime";
const PROGRESS_NAME = "Progress", [createProgressContext, createProgressScope] = createContextScope(PROGRESS_NAME), [ProgressProvider, useProgressContext] = createProgressContext(PROGRESS_NAME), INDICATOR_NAME = "ProgressIndicator", ProgressIndicatorFrame = styled(ThemeableStack, {
  name: INDICATOR_NAME,
  variants: {
    unstyled: {
      false: {
        height: "100%",
        width: "100%",
        backgrounded: !0
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), ProgressIndicator = ProgressIndicatorFrame.styleable(function(props, forwardedRef) {
  const { __scopeProgress, ...indicatorProps } = props, context = useProgressContext(INDICATOR_NAME, __scopeProgress), pct = context.max - (context.value ?? 0), x = -(context.width === 0 ? 300 : context.width) * (pct / 100);
  return /* @__PURE__ */ jsx(
    ProgressIndicatorFrame,
    {
      "data-state": getProgressState(context.value, context.max),
      "data-value": context.value ?? void 0,
      "data-max": context.max,
      x,
      width: context.width,
      ...!props.unstyled && {
        animateOnly: ["transform"],
        opacity: context.width === 0 ? 0 : 1
      },
      ...indicatorProps,
      ref: forwardedRef
    }
  );
});
function defaultGetValueLabel(value, max) {
  return `${Math.round(value / max * 100)}%`;
}
function getProgressState(value, maxValue) {
  return value == null ? "indeterminate" : value === maxValue ? "complete" : "loading";
}
function isNumber(value) {
  return typeof value == "number";
}
function isValidMaxNumber(max) {
  return isNumber(max) && !Number.isNaN(max) && max > 0;
}
function isValidValueNumber(value, max) {
  return isNumber(value) && !Number.isNaN(value) && value <= max && value >= 0;
}
const DEFAULT_MAX = 100, ProgressFrame = styled(ThemeableStack, {
  name: "Progress",
  variants: {
    unstyled: {
      false: {
        borderRadius: 1e5,
        overflow: "hidden",
        backgrounded: !0
      }
    },
    size: {
      "...size": (val) => {
        const size = Math.round(getVariableValue(getSize(val)) * 0.25);
        return {
          height: size,
          minWidth: getVariableValue(size) * 20,
          width: "100%"
        };
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), Progress = withStaticProperties(
  ProgressFrame.styleable(function(props, forwardedRef) {
    const {
      // @ts-expect-error
      __scopeProgress,
      value: valueProp,
      max: maxProp,
      getValueLabel = defaultGetValueLabel,
      size = "$true",
      ...progressProps
    } = props, max = isValidMaxNumber(maxProp) ? maxProp : DEFAULT_MAX, value = isValidValueNumber(valueProp, max) ? valueProp : null, valueLabel = isNumber(value) ? getValueLabel(value, max) : void 0, [width, setWidth] = React.useState(0);
    return /* @__PURE__ */ jsx(ProgressProvider, { scope: __scopeProgress, value, max, width, children: /* @__PURE__ */ jsx(
      ProgressFrame,
      {
        "aria-valuemax": max,
        "aria-valuemin": 0,
        "aria-valuenow": isNumber(value) ? value : void 0,
        "aria-valuetext": valueLabel,
        role: "progressbar",
        "data-state": getProgressState(value, max),
        "data-value": value ?? void 0,
        "data-max": max,
        ...progressProps.unstyled !== !0 && {
          size
        },
        ...progressProps,
        onLayout: (e) => {
          setWidth(e.nativeEvent.layout.width), progressProps.onLayout?.(e);
        },
        ref: forwardedRef
      }
    ) });
  }),
  {
    Indicator: ProgressIndicator
  }
);
export {
  Progress,
  ProgressFrame,
  ProgressIndicator,
  ProgressIndicatorFrame,
  createProgressScope
};
