import { isWeb } from "@tamagui/constants";
let conf;
const setConfig = (next) => {
  conf = next;
}, setConfigFont = (name, font, fontParsed) => {
  if (process.env.NODE_ENV === "development" && !conf)
    throw new Error("Haven't called createTamagui yet");
  conf.fonts[name] = font, conf.fontsParsed[`$${name}`] = fontParsed;
}, getConfig = () => {
  if (!conf)
    throw new Error(
      process.env.NODE_ENV !== "production" ? "Missing tamagui config, you either have a duplicate config, or haven't set it up. Be sure createTamagui is called before rendering. Also, make sure all of your tamagui dependencies are on the same version (`tamagui`, `@tamagui/package-name`, etc.) not just in your package.json, but in your lockfile." : "Err0"
    );
  return conf;
};
let tokensMerged;
function setTokens(_) {
  tokensMerged = _;
}
const getTokens = ({
  prefixed
} = {}) => {
  if (process.env.NODE_ENV === "development" && !conf)
    throw new Error("Haven't called createTamagui yet");
  const { tokens, tokensParsed } = conf;
  return prefixed === !1 ? tokens : prefixed === !0 ? tokensParsed : tokensMerged;
}, getTokenObject = (value, group) => conf.specificTokens[value] ?? (group ? tokensMerged[group]?.[value] : tokensMerged[Object.keys(tokensMerged).find((cat) => tokensMerged[cat][value]) || ""]?.[value]), getToken = (value, group, useVariable = isWeb) => {
  const token = getTokenObject(value, group);
  return useVariable ? token?.variable : token?.val;
}, getTokenValue = (value, group) => getToken(value, group, !1), useTokens = getTokens, getThemes = () => conf.themes, configListeners = /* @__PURE__ */ new Set(), onConfiguredOnce = (cb) => {
  conf ? cb(conf) : configListeners.add(cb);
}, updateConfig = (key, value) => {
  Object.assign(conf[key], value);
}, getFont = (name) => {
  const conf2 = getConfig();
  return conf2.fontsParsed[name] ?? Object.entries(conf2.fontsParsed).find(
    ([k]) => conf2.fontsParsed[k]?.family?.val === name
  )?.[1];
};
let devConfig;
function setupDev(conf2) {
  process.env.NODE_ENV === "development" && (devConfig = conf2);
}
export {
  configListeners,
  devConfig,
  getConfig,
  getFont,
  getThemes,
  getToken,
  getTokenObject,
  getTokenValue,
  getTokens,
  onConfiguredOnce,
  setConfig,
  setConfigFont,
  setTokens,
  setupDev,
  updateConfig,
  useTokens
};
