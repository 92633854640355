import { isEndish, isMoveish, isStartish } from "./types";
const MAX_TOUCH_BANK = 20;
function timestampForTouch(touch) {
  return touch.timeStamp || touch.timestamp;
}
function createTouchRecord(touch) {
  return {
    touchActive: !0,
    startPageX: touch.pageX,
    startPageY: touch.pageY,
    startTimeStamp: timestampForTouch(touch),
    currentPageX: touch.pageX,
    currentPageY: touch.pageY,
    currentTimeStamp: timestampForTouch(touch),
    previousPageX: touch.pageX,
    previousPageY: touch.pageY,
    previousTimeStamp: timestampForTouch(touch)
  };
}
function resetTouchRecord(touchRecord, touch) {
  touchRecord.touchActive = !0, touchRecord.startPageX = touch.pageX, touchRecord.startPageY = touch.pageY, touchRecord.startTimeStamp = timestampForTouch(touch), touchRecord.currentPageX = touch.pageX, touchRecord.currentPageY = touch.pageY, touchRecord.currentTimeStamp = timestampForTouch(touch), touchRecord.previousPageX = touch.pageX, touchRecord.previousPageY = touch.pageY, touchRecord.previousTimeStamp = timestampForTouch(touch);
}
function getTouchIdentifier({ identifier }) {
  return identifier == null && console.error("Touch object is missing identifier."), process.env.NODE_ENV === "development" && identifier > MAX_TOUCH_BANK && console.error(
    "Touch identifier %s is greater than maximum supported %s which causes performance issues backfilling array locations for all of the indices.",
    identifier,
    MAX_TOUCH_BANK
  ), identifier;
}
function recordTouchStart(touch, touchHistory) {
  const identifier = getTouchIdentifier(touch), touchRecord = touchHistory.touchBank[identifier];
  touchRecord ? resetTouchRecord(touchRecord, touch) : touchHistory.touchBank[identifier] = createTouchRecord(touch), touchHistory.mostRecentTimeStamp = timestampForTouch(touch);
}
function recordTouchMove(touch, touchHistory) {
  const touchRecord = touchHistory.touchBank[getTouchIdentifier(touch)];
  touchRecord ? (touchRecord.touchActive = !0, touchRecord.previousPageX = touchRecord.currentPageX, touchRecord.previousPageY = touchRecord.currentPageY, touchRecord.previousTimeStamp = touchRecord.currentTimeStamp, touchRecord.currentPageX = touch.pageX, touchRecord.currentPageY = touch.pageY, touchRecord.currentTimeStamp = timestampForTouch(touch), touchHistory.mostRecentTimeStamp = timestampForTouch(touch)) : console.warn(
    `Cannot record touch move without a touch start.
`,
    `Touch Move: ${printTouch(touch)}
`,
    `Touch Bank: ${printTouchBank(touchHistory)}`
  );
}
function recordTouchEnd(touch, touchHistory) {
  const touchRecord = touchHistory.touchBank[getTouchIdentifier(touch)];
  touchRecord ? (touchRecord.touchActive = !1, touchRecord.previousPageX = touchRecord.currentPageX, touchRecord.previousPageY = touchRecord.currentPageY, touchRecord.previousTimeStamp = touchRecord.currentTimeStamp, touchRecord.currentPageX = touch.pageX, touchRecord.currentPageY = touch.pageY, touchRecord.currentTimeStamp = timestampForTouch(touch), touchHistory.mostRecentTimeStamp = timestampForTouch(touch)) : console.warn(
    `Cannot record touch end without a touch start.
`,
    `Touch End: ${printTouch(touch)}
`,
    `Touch Bank: ${printTouchBank(touchHistory)}`
  );
}
function printTouch(touch) {
  return JSON.stringify({
    identifier: touch.identifier,
    pageX: touch.pageX,
    pageY: touch.pageY,
    timestamp: timestampForTouch(touch)
  });
}
function printTouchBank(touchHistory) {
  const { touchBank } = touchHistory;
  let printed = JSON.stringify(touchBank.slice(0, MAX_TOUCH_BANK));
  return touchBank.length > MAX_TOUCH_BANK && (printed += ` (original size: ${touchBank.length})`), printed;
}
class ResponderTouchHistoryStore {
  _touchHistory = {
    touchBank: [],
    //Array<TouchRecord>
    numberActiveTouches: 0,
    // If there is only one active touch, we remember its location. This prevents
    // us having to loop through all of the touches all the time in the most
    // common case.
    indexOfSingleActiveTouch: -1,
    mostRecentTimeStamp: 0
  };
  recordTouchTrack(topLevelType, nativeEvent) {
    const touchHistory = this._touchHistory;
    if (isMoveish(topLevelType))
      nativeEvent.changedTouches.forEach((touch) => recordTouchMove(touch, touchHistory));
    else if (isStartish(topLevelType))
      nativeEvent.changedTouches.forEach((touch) => recordTouchStart(touch, touchHistory)), touchHistory.numberActiveTouches = nativeEvent.touches.length, touchHistory.numberActiveTouches === 1 && (touchHistory.indexOfSingleActiveTouch = nativeEvent.touches[0].identifier);
    else if (isEndish(topLevelType) && (nativeEvent.changedTouches.forEach((touch) => recordTouchEnd(touch, touchHistory)), touchHistory.numberActiveTouches = nativeEvent.touches.length, touchHistory.numberActiveTouches === 1)) {
      const { touchBank } = touchHistory;
      for (let i = 0; i < touchBank.length; i++)
        if (touchBank[i]?.touchActive) {
          touchHistory.indexOfSingleActiveTouch = i;
          break;
        }
      process.env.NODE_ENV === "development" && (touchBank[touchHistory.indexOfSingleActiveTouch]?.touchActive || console.error("Cannot find single active touch."));
    }
  }
  get touchHistory() {
    return this._touchHistory;
  }
}
export {
  ResponderTouchHistoryStore
};
