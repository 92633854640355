import { YStack } from "@tamagui/stacks";
import { useEffect, useRef } from "react";
import { View } from "react-native-web";
import { SheetProvider } from "./SheetContext";
import { useSheetOpenState } from "./useSheetOpenState";
import { useSheetProviderProps } from "./useSheetProviderProps";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const nativeSheets = {
  ios: null
};
function getNativeSheet(platform) {
  return nativeSheets[platform];
}
function setupNativeSheet(platform, Implementation) {
  platform === "ios" && (nativeSheets[platform] = (props) => {
    const state = useSheetOpenState(props), providerProps = useSheetProviderProps(props, state), { open, setOpen } = state, ref = useRef();
    return useEffect(() => {
      ref.current?.setVisibility(open);
    }, [open]), /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(SheetProvider, { ...providerProps, onlyShowFrame: !0, children: [
      /* @__PURE__ */ jsx(Implementation, { ref, onModalDismiss: () => setOpen(!1), children: /* @__PURE__ */ jsx(View, { style: { flex: 1 }, children: props.children }) }),
      /* @__PURE__ */ jsx(
        YStack,
        {
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
          width: 0,
          height: 0,
          children: props.children
        }
      )
    ] }) });
  });
}
export {
  getNativeSheet,
  setupNativeSheet
};
