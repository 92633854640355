import { useCallbackRef } from "@tamagui/use-callback-ref";
import { useEffect } from "react";
function useEscapeKeydown(onEscapeKeyDownProp, ownerDocument = globalThis?.document) {
  const onEscapeKeyDown = useCallbackRef(onEscapeKeyDownProp);
  useEffect(() => {
    const handleKeyDown = (event) => {
      event.key === "Escape" && onEscapeKeyDown(event);
    };
    return ownerDocument.addEventListener("keydown", handleKeyDown), () => {
      ownerDocument.removeEventListener("keydown", handleKeyDown);
    };
  }, [onEscapeKeyDown, ownerDocument]);
}
export {
  useEscapeKeydown
};
