import { createContext, useContext } from "react";
const useSheetController = () => {
  const controller = useContext(SheetControllerContext), isHidden = controller?.hidden, isShowingNonSheet = isHidden && controller?.open;
  return {
    controller,
    isHidden,
    isShowingNonSheet,
    disableDrag: controller?.disableDrag
  };
}, SheetControllerContext = createContext(
  null
);
export {
  SheetControllerContext,
  useSheetController
};
