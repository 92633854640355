import { isClient, isServer } from "@tamagui/constants";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getConfig } from "../config";
import { getVariable } from "../createVariable";
import { isEqualShallow } from "../helpers/createShallowSetState";
import { ThemeManager, getHasThemeUpdatingProps } from "../helpers/ThemeManager";
import { ThemeManagerIDContext } from "../helpers/ThemeManagerContext";
const emptyProps = { name: null };
let cached;
function getDefaultThemeProxied() {
  if (cached)
    return cached;
  const config = getConfig(), name = config.themes.light ? "light" : Object.keys(config.themes)[0], defaultTheme = config.themes[name];
  return cached = getThemeProxied({ theme: defaultTheme, name }), cached;
}
const useTheme = (props = emptyProps) => {
  const [_, theme] = useThemeWithState(props);
  return theme || getDefaultThemeProxied();
}, useThemeWithState = (props) => {
  const keys = useRef([]), changedThemeState = useChangeThemeEffect(
    props,
    !1,
    keys.current,
    isServer ? void 0 : () => {
      const next = props.shouldUpdate?.() ?? (keys.current.length > 0 ? !0 : void 0);
      return process.env.NODE_ENV === "development" && typeof props.debug == "string" && props.debug !== "profile" && console.info(
        "  \u{1F3A8} useTheme() shouldUpdate?",
        next,
        isClient ? {
          shouldUpdateProp: props.shouldUpdate?.(),
          keys: [...keys.current]
        } : ""
      ), next;
    }
  ), { themeManager, state } = changedThemeState;
  process.env.NODE_ENV === "development" && (state?.theme || process.env.TAMAGUI_DISABLE_NO_THEME_WARNING !== "1" && console.warn(
    `[tamagui] No theme found, this could be due to an invalid theme name (given theme props ${JSON.stringify(
      props
    )}).

If this is intended and you are using Tamagui without any themes, you can disable this warning by setting the environment variable TAMAGUI_DISABLE_NO_THEME_WARNING=1`
  ));
  const themeProxied = useMemo(() => !themeManager || !state?.theme ? {} : getThemeProxied(state, props.deopt, themeManager, keys.current, props.debug), [state?.theme, themeManager, props.deopt, props.debug]);
  return process.env.NODE_ENV === "development" && props.debug === "verbose" && (console.groupCollapsed("  \u{1F539} useTheme =>", state?.name), console.info("returning state", changedThemeState, "from props", props), console.groupEnd()), [changedThemeState, themeProxied];
};
function getThemeProxied({ theme, name, scheme }, deopt = !1, themeManager, keys, debug) {
  if (!theme)
    return {};
  const config = getConfig();
  function track(key) {
    keys && !keys.includes(key) && (keys.push(key), process.env.NODE_ENV === "development" && debug && console.info(` \u{1F3A8} useTheme() tracking new key: ${key}`));
  }
  return new Proxy(theme, {
    has(_, key) {
      if (Reflect.has(theme, key))
        return !0;
      if (typeof key == "string")
        return key[0] === "$" && (key = key.slice(1)), themeManager?.allKeys.has(key);
    },
    get(_, key) {
      if (
        // dont ask me, idk why but on hermes you can see that useTheme()[undefined] passes in STRING undefined to proxy
        // if someone is crazy enough to use "undefined" as a theme key then this not working is on them
        key !== "undefined" && typeof key == "string"
      ) {
        const keyString = key[0] === "$" ? key.slice(1) : key, val = theme[keyString];
        if (val && typeof val == "object")
          return new Proxy(val, {
            // when they touch the actual value we only track it
            // if its a variable (web), its ignored!
            get(_2, subkey) {
              if (subkey === "val")
                track(keyString);
              else if (subkey === "get")
                return (platform) => getVariable(val);
              return Reflect.get(val, subkey);
            }
          });
        if (process.env.NODE_ENV === "development" && process.env.TAMAGUI_FEAT_THROW_ON_MISSING_THEME_VALUE === "1")
          throw new Error(
            `[tamagui] No theme key "${key}" found in theme ${name}. 
  Keys in theme: ${Object.keys(
              theme
            ).join(", ")}`
          );
      }
      return Reflect.get(_, key);
    }
  });
}
const activeThemeManagers = /* @__PURE__ */ new Set(), _uidToManager = /* @__PURE__ */ new WeakMap(), _idToUID = {}, getId = (id) => _idToUID[id], getThemeManager = (id) => _uidToManager.get(getId(id)), registerThemeManager = (t) => {
  if (!_idToUID[t.id]) {
    const id = _idToUID[t.id] = {};
    _uidToManager.set(id, t);
  }
}, useChangeThemeEffect = (props, isRoot = !1, keys, shouldUpdate) => {
  const { disable } = props, parentManagerId = useContext(ThemeManagerIDContext), parentManager = getThemeManager(parentManagerId);
  if (!isRoot && !parentManager || disable)
    return {
      isNewTheme: !1,
      state: parentManager?.state,
      themeManager: parentManager
    };
  const [themeState, setThemeState] = useState(createState), { state, mounted, isNewTheme, themeManager, inversed } = themeState, isInversingOnMount = !!(!themeState.mounted && props.inverse);
  function getShouldUpdateTheme(manager = themeManager, nextState, prevState = state, forceShouldChange = !1) {
    const forceUpdate = shouldUpdate?.();
    if (!manager || !forceShouldChange && forceUpdate === !1)
      return;
    const next = nextState || manager.getState(props, parentManager);
    if (forceShouldChange)
      return next;
    if (next && !(forceUpdate !== !0 && !manager.getStateShouldChange(next, prevState)))
      return next;
  }
  if (isServer || (useEffect(() => {
    if (!themeManager)
      return;
    if (props.inverse && !mounted) {
      setThemeState((prev) => createState({
        ...prev,
        mounted: !0
      }));
      return;
    }
    (isNewTheme || getShouldUpdateTheme(themeManager)) && (activeThemeManagers.add(themeManager), setThemeState(createState));
    const selfListenerDispose = themeManager.onChangeTheme((_a, _b, forced) => {
      forced && setThemeState((prev) => createState(prev, !0));
    }), disposeChangeListener = parentManager?.onChangeTheme(
      (name, manager, forced) => {
        const force = forced || shouldUpdate?.() || props.deopt || void 0, shouldTryUpdate = force ?? !!(keys?.length || isNewTheme);
        process.env.NODE_ENV === "development" && props.debug === "verbose" && console.info(" \u{1F538} onChange", themeManager.id, {
          force,
          shouldTryUpdate,
          props,
          name,
          manager,
          keys
        }), shouldTryUpdate && setThemeState((prev) => createState(prev, force));
      },
      themeManager.id
    );
    return () => {
      selfListenerDispose(), disposeChangeListener?.(), isNewTheme && activeThemeManagers.delete(themeManager);
    };
  }, [
    themeManager,
    parentManager,
    isNewTheme,
    props.componentName,
    props.inverse,
    props.name,
    props.reset,
    mounted
  ]), process.env.NODE_ENV === "development" && props.debug !== "profile" && useEffect(() => (globalThis.TamaguiThemeManagers ??= /* @__PURE__ */ new Set(), globalThis.TamaguiThemeManagers.add(themeManager), () => {
    globalThis.TamaguiThemeManagers.delete(themeManager);
  }), [themeManager])), isInversingOnMount)
    return {
      isNewTheme: !1,
      inversed: !1,
      themeManager: parentManager,
      state: {
        name: "",
        ...parentManager?.state,
        className: ""
      }
    };
  return {
    state,
    isNewTheme,
    inversed,
    themeManager
  };
  function createState(prev, force = !1) {
    if (prev && shouldUpdate?.() === !1 && !force)
      return prev;
    let themeManager2 = parentManager, state2;
    if (getHasThemeUpdatingProps(props)) {
      const getNewThemeManager = () => new ThemeManager(props, isRoot ? "root" : parentManager);
      if (prev?.themeManager) {
        themeManager2 = prev.themeManager;
        const forceChange = force || !!keys?.length, next = themeManager2.getState(props, parentManager), nextState = getShouldUpdateTheme(
          themeManager2,
          next,
          prev.state,
          forceChange
        );
        nextState ? (state2 = nextState, prev.isNewTheme ? themeManager2.updateState(nextState) : themeManager2 = getNewThemeManager()) : prev.isNewTheme && parentManager && !next && (themeManager2 = parentManager);
      } else
        themeManager2 = getNewThemeManager(), state2 = { ...themeManager2.state };
    }
    const isNewTheme2 = !!(themeManager2 !== parentManager || props.inverse);
    isNewTheme2 && registerThemeManager(themeManager2);
    const mounted2 = props.inverse ? isRoot || prev?.mounted : !0;
    state2 || (isNewTheme2 ? state2 = themeManager2.state : (state2 = parentManager.state, themeManager2 = parentManager));
    const wasInversed = prev?.inversed, inversed2 = isNewTheme2 && state2.scheme !== parentManager?.state.scheme ? !0 : wasInversed != null ? !1 : null, response = {
      themeManager: themeManager2,
      isNewTheme: isNewTheme2,
      mounted: mounted2,
      inversed: inversed2
    }, shouldReturnPrev = prev && !force && // isEqualShallow uses the second arg as the keys so this should compare without state first...
    isEqualShallow(prev, response) && // ... and then compare just the state, because we make a new state obj but is likely the same
    isEqualShallow(prev.state, state2);
    if (prev && shouldReturnPrev)
      return prev;
    if (response.state = state2, process.env.NODE_ENV === "development" && props.debug && isClient) {
      console.groupCollapsed(` \u{1F537} ${themeManager2.id} useChangeThemeEffect createState`);
      const parentState = { ...parentManager?.state }, parentId = parentManager?.id, themeManagerState = { ...themeManager2.state };
      console.info({
        props,
        parentState,
        parentId,
        themeManager: themeManager2,
        prev,
        response,
        themeManagerState
      }), console.groupEnd();
    }
    return response;
  }
};
export {
  activeThemeManagers,
  getThemeManager,
  getThemeProxied,
  useChangeThemeEffect,
  useTheme,
  useThemeWithState
};
