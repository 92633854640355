import { isWeb } from "@tamagui/constants";
import { stylePropsAll, stylePropsUnitless } from "@tamagui/helpers";
import { getAllSelectors } from "./insertStyleRule";
const stylePropsAllPlusTransforms = {
  ...stylePropsAll,
  translateX: !0,
  translateY: !0
};
function normalizeValueWithProperty(value, property = "") {
  if (!isWeb || stylePropsUnitless[property] || property && !stylePropsAllPlusTransforms[property] || typeof value == "boolean")
    return value;
  let res = value;
  return value && typeof value == "object" ? value : (typeof value == "number" ? res = `${value}px` : property && (res = `${res}`), res);
}
const rcache = {};
function reverseMapClassNameToValue(key, className) {
  const cssRule = getAllSelectors()[className];
  if (rcache[cssRule])
    return rcache[cssRule];
  if (!cssRule) {
    process.env.NODE_ENV === "development" && console.warn(
      `No CSS rule found for ${key} looking for selector ".${className}", you may not be injecting extracted CSS`
    );
    return;
  }
  const cssVal = cssRule.replace(/.*:/, "").replace(/;.*/, "").trim();
  let res;
  return cssVal.startsWith("var(") ? res = cssVal : stylePropsUnitless[key] ? res = +cssVal : cssVal.endsWith("px") ? res = +cssVal.replace("px", "") : res = cssVal, rcache[cssRule] = res, process.env.NODE_ENV === "development" && typeof res == "number" && Number.isNaN(res) && console.info("Tamagui invalid parsed value, NaN:", {
    res,
    cssVal,
    cssRule,
    key,
    className
  }), res;
}
export {
  normalizeValueWithProperty,
  reverseMapClassNameToValue
};
