import { isAndroid } from "@tamagui/constants";
const placeHolderTextColors = {
  placeholderTextColor: !0
}, validStylesOnBaseProps = {
  ...placeHolderTextColors
}, textColors = {
  color: !0,
  ...placeHolderTextColors,
  textDecorationColor: !0,
  textShadowColor: !0
}, tokenCategories = {
  radius: {
    borderRadius: !0,
    borderTopLeftRadius: !0,
    borderTopRightRadius: !0,
    borderBottomLeftRadius: !0,
    borderBottomRightRadius: !0,
    // logical
    borderStartStartRadius: !0,
    borderStartEndRadius: !0,
    borderEndStartRadius: !0,
    borderEndEndRadius: !0
  },
  size: {
    width: !0,
    height: !0,
    minWidth: !0,
    minHeight: !0,
    maxWidth: !0,
    maxHeight: !0
  },
  zIndex: {
    zIndex: !0
  },
  color: {
    backgroundColor: !0,
    borderColor: !0,
    borderBlockStartColor: !0,
    borderBlockEndColor: !0,
    borderBlockColor: !0,
    borderBottomColor: !0,
    borderTopColor: !0,
    borderLeftColor: !0,
    borderRightColor: !0,
    borderEndColor: !0,
    borderStartColor: !0,
    shadowColor: !0,
    ...textColors,
    outlineColor: !0
  }
}, stylePropsUnitless = {
  WebkitLineClamp: !0,
  animationIterationCount: !0,
  aspectRatio: !0,
  borderImageOutset: !0,
  borderImageSlice: !0,
  borderImageWidth: !0,
  columnCount: !0,
  flex: !0,
  flexGrow: !0,
  flexOrder: !0,
  flexPositive: !0,
  flexShrink: !0,
  flexNegative: !0,
  fontWeight: !0,
  gridRow: !0,
  gridRowEnd: !0,
  gridRowGap: !0,
  gridRowStart: !0,
  gridColumn: !0,
  gridColumnEnd: !0,
  gridColumnGap: !0,
  gridColumnStart: !0,
  lineClamp: !0,
  opacity: !0,
  order: !0,
  orphans: !0,
  tabSize: !0,
  widows: !0,
  zIndex: !0,
  zoom: !0,
  scale: !0,
  scaleX: !0,
  scaleY: !0,
  scaleZ: !0,
  shadowOpacity: !0
}, stylePropsTransform = {
  x: !0,
  y: !0,
  scale: !0,
  perspective: !0,
  scaleX: !0,
  scaleY: !0,
  skewX: !0,
  skewY: !0,
  matrix: !0,
  rotate: !0,
  rotateY: !0,
  rotateX: !0,
  rotateZ: !0
}, stylePropsView = {
  backfaceVisibility: !0,
  borderBottomEndRadius: !0,
  borderBottomStartRadius: !0,
  borderBottomWidth: !0,
  borderLeftWidth: !0,
  borderRightWidth: !0,
  borderStyle: !0,
  borderTopEndRadius: !0,
  borderTopStartRadius: !0,
  borderTopWidth: !0,
  borderWidth: !0,
  transform: !0,
  transformOrigin: !0,
  alignContent: !0,
  alignItems: !0,
  alignSelf: !0,
  borderEndWidth: !0,
  borderStartWidth: !0,
  bottom: !0,
  display: !0,
  end: !0,
  flexBasis: !0,
  flexDirection: !0,
  flexWrap: !0,
  gap: !0,
  columnGap: !0,
  rowGap: !0,
  justifyContent: !0,
  left: !0,
  margin: !0,
  marginBlockEnd: !0,
  marginBlockStart: !0,
  marginInlineEnd: !0,
  marginInline: !0,
  marginInlineStart: !0,
  marginBottom: !0,
  marginEnd: !0,
  marginHorizontal: !0,
  marginLeft: !0,
  marginRight: !0,
  marginStart: !0,
  marginTop: !0,
  marginVertical: !0,
  overflow: !0,
  padding: !0,
  paddingBottom: !0,
  paddingInline: !0,
  paddingBlock: !0,
  paddingBlockStart: !0,
  paddingInlineEnd: !0,
  paddingInlineStart: !0,
  paddingEnd: !0,
  paddingHorizontal: !0,
  paddingLeft: !0,
  paddingRight: !0,
  paddingStart: !0,
  paddingTop: !0,
  paddingVertical: !0,
  position: !0,
  right: !0,
  start: !0,
  top: !0,
  direction: !0,
  shadowOffset: !0,
  shadowRadius: !0,
  ...tokenCategories.color,
  ...tokenCategories.radius,
  ...tokenCategories.size,
  ...tokenCategories.radius,
  ...validStylesOnBaseProps,
  ...stylePropsTransform,
  ...stylePropsUnitless,
  // RN doesn't support specific border styles per-edge
  borderBottomStyle: !0,
  borderTopStyle: !0,
  borderLeftStyle: !0,
  borderRightStyle: !0,
  overflowX: !0,
  overflowY: !0,
  userSelect: !0,
  cursor: !0,
  contain: !0,
  pointerEvents: !0,
  boxSizing: !0,
  boxShadow: !0,
  outlineStyle: !0,
  outlineOffset: !0,
  outlineWidth: !0,
  touchAction: !0,
  ...isAndroid ? { elevationAndroid: !0 } : {}
}, stylePropsFont = {
  fontFamily: !0,
  fontSize: !0,
  fontStyle: !0,
  fontWeight: !0,
  letterSpacing: !0,
  lineHeight: !0,
  textTransform: !0
}, stylePropsTextOnly = {
  ...stylePropsFont,
  textAlign: !0,
  textDecorationLine: !0,
  textDecorationStyle: !0,
  ...textColors,
  textShadowOffset: !0,
  textShadowRadius: !0,
  whiteSpace: !0,
  wordWrap: !0,
  textOverflow: !0,
  textDecorationDistance: !0,
  userSelect: !0,
  selectable: !0,
  cursor: !0,
  WebkitLineClamp: !0,
  WebkitBoxOrient: !0
}, stylePropsText = {
  ...stylePropsView,
  ...stylePropsTextOnly
}, stylePropsAll = stylePropsText, validPseudoKeys = {
  enterStyle: !0,
  exitStyle: !0,
  hoverStyle: !0,
  pressStyle: !0,
  focusStyle: !0
}, validStyles = {
  ...validPseudoKeys,
  ...stylePropsView
};
export {
  stylePropsAll,
  stylePropsFont,
  stylePropsText,
  stylePropsTextOnly,
  stylePropsTransform,
  stylePropsUnitless,
  stylePropsView,
  tokenCategories,
  validPseudoKeys,
  validStyles,
  validStylesOnBaseProps
};
