import { useReducer } from "react";
const isServerSide = typeof window > "u", idFn = () => {
};
function useForceUpdate() {
  return isServerSide ? idFn : useReducer((x) => Math.random(), 0)[1];
}
export {
  isServerSide,
  useForceUpdate
};
