import { getVariable, useTheme } from "@tamagui/web";
const useCurrentColor = (colorProp) => {
  const theme = useTheme();
  return getVariable(
    colorProp || theme[colorProp]?.get("web") || theme.color?.get("web")
  );
};
export {
  useCurrentColor
};
