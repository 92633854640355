import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { getBoundingClientRect } from "../helpers/getBoundingClientRect";
const LayoutHandlers = /* @__PURE__ */ new WeakMap();
let resizeObserver = null;
typeof window < "u" && "ResizeObserver" in window && (resizeObserver = new ResizeObserver((entries) => {
  for (const { target } of entries) {
    const onLayout = LayoutHandlers.get(target);
    if (typeof onLayout != "function")
      return;
    measureLayout(target, null, (x, y, width, height, left, top) => {
      onLayout({
        nativeEvent: {
          layout: { x, y, width, height, left, top },
          target
        },
        timeStamp: Date.now()
      });
    });
  }
}));
const cache = /* @__PURE__ */ new WeakMap(), measureLayout = (node, relativeTo, callback) => {
  const relativeNode = relativeTo || node?.parentNode;
  if (relativeNode instanceof HTMLElement) {
    const now = Date.now();
    cache.set(node, now), Promise.all([
      getBoundingClientRectAsync(node),
      getBoundingClientRectAsync(relativeNode)
    ]).then(([nodeDim, relativeNodeDim]) => {
      if (relativeNodeDim && nodeDim && cache.get(node) === now) {
        const { x, y, width, height, left, top } = getRelativeDimensions(
          nodeDim,
          relativeNodeDim
        );
        callback(x, y, width, height, left, top);
      }
    });
  }
}, getRelativeDimensions = (a, b) => {
  const { height, left, top, width } = a, x = left - b.left, y = top - b.top;
  return { x, y, width, height, left, top };
}, getBoundingClientRectAsync = (element) => new Promise((resolve) => {
  function fallbackToSync() {
    resolve(getBoundingClientRect(element));
  }
  const tm = setTimeout(fallbackToSync, 10);
  new IntersectionObserver(
    (entries, ob) => {
      clearTimeout(tm), ob.disconnect(), resolve(entries[0]?.boundingClientRect);
    },
    {
      threshold: 1e-4
    }
  ).observe(element);
});
function useElementLayout(ref, onLayout) {
  useIsomorphicLayoutEffect(() => {
    if (!resizeObserver || !onLayout)
      return;
    const node = ref.current;
    if (node)
      return LayoutHandlers.set(node, onLayout), resizeObserver.observe(node), () => {
        resizeObserver?.unobserve(node);
      };
  }, [ref, onLayout]);
}
export {
  measureLayout,
  useElementLayout
};
