import { isWeb } from "@tamagui/constants";
import { styled, useTheme } from "@tamagui/core";
import { useFocusable } from "@tamagui/focusable";
import { TextInput } from "react-native-web";
import { inputSizeVariant } from "../helpers/inputHelpers";
import { jsx } from "react/jsx-runtime";
const defaultStyles = {
  size: "$true",
  fontFamily: "$body",
  borderWidth: 1,
  outlineWidth: 0,
  color: "$color",
  ...isWeb ? {
    tabIndex: 0
  } : {
    focusable: !0
  },
  borderColor: "$borderColor",
  backgroundColor: "$background",
  // this fixes a flex bug where it overflows container
  minWidth: 0,
  hoverStyle: {
    borderColor: "$borderColorHover"
  },
  focusStyle: {
    outlineColor: "$outlineColor",
    outlineWidth: 2,
    outlineStyle: "solid",
    borderColor: "$borderColorFocus"
  }
}, InputFrame = styled(
  TextInput,
  {
    name: "Input",
    variants: {
      unstyled: {
        false: defaultStyles
      },
      size: {
        "...size": inputSizeVariant
      },
      disabled: {
        true: {}
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  },
  {
    isInput: !0,
    acceptTokens: {
      placeholderTextColor: "color"
    }
  }
), Input = InputFrame.styleable((propsIn, ref) => {
  const props = useInputProps(propsIn, ref);
  return /* @__PURE__ */ jsx(InputFrame, { ...props });
});
function useInputProps(props, ref) {
  const theme = useTheme(), { onChangeText, ref: combinedRef } = useFocusable({
    // @ts-ignore
    props,
    ref,
    isInput: !0
  }), placeholderColorProp = props.placeholderTextColor, placeholderTextColor = theme[placeholderColorProp]?.get() ?? placeholderColorProp ?? theme.placeholderColor?.get();
  return {
    ref: combinedRef,
    readOnly: props.disabled,
    ...props,
    placeholderTextColor,
    onChangeText
  };
}
export {
  Input,
  InputFrame,
  defaultStyles,
  useInputProps
};
