import { useEvent } from "@tamagui/core";
import { useMemo } from "react";
import { SheetControllerContext } from "./useSheetController";
import { jsx } from "react/jsx-runtime";
const SheetController = ({
  children,
  onOpenChange: onOpenChangeProp,
  ...value
}) => {
  const onOpenChange = useEvent(onOpenChangeProp), memoValue = useMemo(
    () => ({
      open: value.open,
      hidden: value.hidden,
      disableDrag: value.disableDrag,
      onOpenChange
    }),
    [onOpenChange, value.open, value.hidden, value.disableDrag]
  );
  return /* @__PURE__ */ jsx(SheetControllerContext.Provider, { value: memoValue, children });
};
export {
  SheetController
};
