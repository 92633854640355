import "@tamagui/polyfill-dev";
import {
  FloatingDelayGroup,
  useDelayGroup,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole
} from "@floating-ui/react";
import { useEvent } from "@tamagui/core";
import { FloatingOverrideContext } from "@tamagui/floating";
import { getSize } from "@tamagui/get-token";
import { withStaticProperties } from "@tamagui/helpers";
import {
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
  PopoverContext,
  PopoverTrigger
} from "@tamagui/popover";
import { Popper, PopperContentFrame, usePopperContext } from "@tamagui/popper";
import { useControllableState } from "@tamagui/use-controllable-state";
import * as React from "react";
import { jsx } from "react/jsx-runtime";
const TOOLTIP_SCOPE = "tooltip", TooltipContent = PopperContentFrame.extractable(
  React.forwardRef(
    ({ __scopeTooltip, ...props }, ref) => {
      const popper = usePopperContext(__scopeTooltip || TOOLTIP_SCOPE), padding = props.padding ?? props.size ?? popper.size ?? getSize("$true", {
        shift: -2
      });
      return /* @__PURE__ */ jsx(
        PopoverContent,
        {
          __scopePopover: __scopeTooltip || TOOLTIP_SCOPE,
          componentName: "Tooltip",
          disableRemoveScroll: !0,
          disableFocusScope: !0,
          ...!props.unstyled && {
            padding
          },
          ref,
          ...props
        }
      );
    }
  )
), TooltipArrow = React.forwardRef(
  (props, ref) => {
    const { __scopeTooltip, ...rest } = props;
    return /* @__PURE__ */ jsx(
      PopoverArrow,
      {
        __scopePopper: __scopeTooltip || TOOLTIP_SCOPE,
        componentName: "Tooltip",
        ref,
        ...rest
      }
    );
  }
), TooltipGroup = ({ children, delay }) => /* @__PURE__ */ jsx(FloatingDelayGroup, { delay: React.useMemo(() => delay, [JSON.stringify(delay)]), children }), TooltipComponent = React.forwardRef(function(props, ref) {
  const {
    children,
    delay: delayProp,
    restMs = typeof delayProp > "u" ? 500 : typeof delayProp == "number" ? delayProp : 0,
    onOpenChange: onOpenChangeProp,
    focus,
    open: openProp,
    __scopeTooltip,
    ...restProps
  } = props, triggerRef = React.useRef(null), [hasCustomAnchor, setHasCustomAnchor] = React.useState(!1), { delay: delayGroup, setCurrentId } = useDelayGroupContext(), delay = delayProp ?? delayGroup, [open, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: !1,
    onChange: onOpenChangeProp
  }), id = props.groupId, onOpenChange = useEvent((open2) => {
    open2 && setCurrentId(id), setOpen(open2);
  }), useFloatingFn = (props2) => {
    const floating = useFloating({
      ...props2,
      open,
      onOpenChange
    }), { getReferenceProps, getFloatingProps } = useInteractions([
      useHover(floating.context, { delay, restMs }),
      useFocus(floating.context, focus),
      useRole(floating.context, { role: "tooltip" }),
      useDismiss(floating.context),
      useDelayGroup(floating.context, { id })
    ]);
    return {
      ...floating,
      open,
      getReferenceProps,
      getFloatingProps
    };
  }, useFloatingContext = React.useCallback(useFloatingFn, [
    id,
    delay,
    open,
    restMs,
    focus ? JSON.stringify(focus) : 0
  ]), onCustomAnchorAdd = React.useCallback(() => setHasCustomAnchor(!0), []), onCustomAnchorRemove = React.useCallback(() => setHasCustomAnchor(!1), []), contentId = React.useId(), smallerSize = props.unstyled ? null : getSize("$true", {
    shift: -2,
    bounds: [0]
  });
  return (
    // TODO: FloatingOverrideContext might also need to be scoped
    /* @__PURE__ */ jsx(FloatingOverrideContext.Provider, { value: useFloatingContext, children: /* @__PURE__ */ jsx(
      Popper,
      {
        __scopePopper: __scopeTooltip || TOOLTIP_SCOPE,
        size: smallerSize?.key,
        allowFlip: !0,
        stayInFrame: !0,
        ...restProps,
        children: /* @__PURE__ */ jsx(
          PopoverContext.Provider,
          {
            contentId,
            triggerRef,
            sheetBreakpoint: !1,
            open,
            scope: __scopeTooltip || TOOLTIP_SCOPE,
            onOpenChange: setOpen,
            onOpenToggle: voidFn,
            hasCustomAnchor,
            onCustomAnchorAdd,
            onCustomAnchorRemove,
            children
          }
        )
      }
    ) })
  );
}), TooltipTrigger = React.forwardRef(function(props, ref) {
  const { __scopeTooltip, ...rest } = props;
  return /* @__PURE__ */ jsx(
    PopoverTrigger,
    {
      ...rest,
      __scopePopover: __scopeTooltip || TOOLTIP_SCOPE,
      ref
    }
  );
}), TooltipAnchor = React.forwardRef(function(props, ref) {
  const { __scopeTooltip, ...rest } = props;
  return /* @__PURE__ */ jsx(PopoverAnchor, { ...rest, __scopePopover: __scopeTooltip || TOOLTIP_SCOPE, ref });
}), Tooltip2 = withStaticProperties(TooltipComponent, {
  Anchor: TooltipAnchor,
  Arrow: TooltipArrow,
  Content: TooltipContent,
  Trigger: TooltipTrigger
}), voidFn = () => {
};
export {
  Tooltip2 as Tooltip,
  TooltipGroup
};
