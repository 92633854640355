import { cloneElement, createElement, isValidElement } from "react";
import { useCurrentColor } from "./useCurrentColor";
const useGetThemedIcon = (props) => {
  const color = useCurrentColor(props.color);
  return (el) => el && (isValidElement(el) ? cloneElement(el, {
    ...props,
    color,
    // @ts-expect-error
    ...el.props
  }) : createElement(el, props));
};
export {
  useGetThemedIcon
};
