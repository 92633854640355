import { PresenceContext, ResetPresence, usePresence } from "@tamagui/use-presence";
import { useMotify } from "moti/author";
import { useCallback, useContext, useMemo } from "react";
import Animated, {
  cancelAnimation,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  withTiming
} from "react-native-reanimated";
function createAnimations(animations) {
  return {
    View: Animated.View,
    Text: Animated.Text,
    isReactNative: !0,
    animations,
    usePresence,
    ResetPresence,
    useAnimatedNumber(initial) {
      const sharedValue = useSharedValue(initial);
      return useMemo(
        () => ({
          getInstance() {
            "worklet";
            return sharedValue;
          },
          getValue() {
            "worklet";
            return sharedValue.value;
          },
          setValue(next, config = { type: "spring" }) {
            "worklet";
            config.type === "direct" ? sharedValue.value = next : config.type === "spring" ? sharedValue.value = withSpring(next, config) : sharedValue.value = withTiming(next, config);
          },
          stop() {
            "worklet";
            cancelAnimation(sharedValue);
          }
        }),
        [sharedValue]
      );
    },
    useAnimatedNumberReaction({ value }, onValue) {
      const instance = value.getInstance();
      return useAnimatedReaction(
        () => instance.value,
        (next, prev) => {
          prev !== next && runOnJS(onValue)(next);
        },
        // dependency array is very important here
        [onValue, instance]
      );
    },
    /**
     * `getStyle` must be a worklet
     */
    useAnimatedNumberStyle(val, getStyle) {
      const instance = val.getInstance(), derivedValue = useDerivedValue(() => instance.value, [instance, getStyle]);
      return useAnimatedStyle(() => getStyle(derivedValue.value), [val, getStyle, derivedValue, instance]);
    },
    useAnimations: (animationProps) => {
      const { props, presence, style, onDidAnimate, componentState } = animationProps, animationKey = Array.isArray(props.animation) ? props.animation[0] : props.animation, isHydrating = componentState.unmounted === "should-enter";
      let animate = {}, dontAnimate = {};
      if (isHydrating)
        dontAnimate = style;
      else {
        const animateOnly = props.animateOnly || ["transform", "opacity"];
        if (animateOnly) {
          dontAnimate = { ...style };
          for (const key of animateOnly)
            key in style && (animate[key] = style[key], delete dontAnimate[key]);
        } else
          animate = style;
      }
      const animateStr = JSON.stringify(animate), styles = useMemo(() => JSON.parse(animateStr), [animateStr]), isExiting = !!presence?.[1], sendExitComplete = presence?.[1], onDidAnimateCombined = useCallback(() => {
        onDidAnimate?.(), sendExitComplete?.();
      }, []), motiProps = {
        animate: isExiting || isHydrating ? {} : styles,
        transition: animations[animationKey],
        // isHydrating
        //   ? ({ type: 'timing', duration: 0 } as const)
        //   : componentState.unmounted
        //     ? { type: 'timing', duration: 0 }
        //     : animations[animationKey as keyof typeof animations]
        onDidAnimate: onDidAnimateCombined,
        usePresenceValue: presence,
        presenceContext: useContext(PresenceContext),
        exit: isExiting ? styles : void 0
      }, moti = useMotify(motiProps);
      return process.env.NODE_ENV === "development" && props.debug && console.info("useMotify(", JSON.stringify(motiProps, null, 2) + ")", {
        animationProps,
        motiProps,
        moti,
        style: [dontAnimate, moti.style]
      }), {
        style: [dontAnimate, moti.style]
      };
    }
  };
}
export {
  createAnimations
};
