import { FloatingFocusManager } from "@floating-ui/react";
import { AnimatePresence } from "@tamagui/animate-presence";
import { composeRefs } from "@tamagui/compose-refs";
import { isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { styled } from "@tamagui/core";
import { PortalItem } from "@tamagui/portal";
import { ThemeableStack } from "@tamagui/stacks";
import { VIEWPORT_NAME } from "./constants";
import {
  ForwardSelectContext,
  useSelectContext,
  useSelectItemParentContext
} from "./context";
import { useSelectBreakpointActive } from "./useSelectBreakpointActive";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const SelectViewportFrame = styled(ThemeableStack, {
  name: VIEWPORT_NAME,
  variants: {
    unstyled: {
      false: {
        size: "$2",
        backgroundColor: "$background",
        elevate: !0,
        bordered: !0,
        userSelect: "none",
        outlineWidth: 0
      }
    },
    size: {
      "...size": (val, { tokens }) => ({
        borderRadius: tokens.radius[val] ?? val
      })
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), SelectViewport = SelectViewportFrame.styleable(
  function(props, forwardedRef) {
    const { __scopeSelect, children, disableScroll, ...viewportProps } = props, context = useSelectContext(VIEWPORT_NAME, __scopeSelect), itemContext = useSelectItemParentContext(VIEWPORT_NAME, __scopeSelect), breakpointActive = useSelectBreakpointActive(context.sheetBreakpoint);
    if (useIsomorphicLayoutEffect(() => {
      context.update && context.update();
    }, [breakpointActive]), itemContext.shouldRenderWebNative)
      return /* @__PURE__ */ jsx(Fragment, { children });
    if (breakpointActive || !isWeb)
      return /* @__PURE__ */ jsx(PortalItem, { hostName: `${context.scopeKey}SheetContents`, children: /* @__PURE__ */ jsx(
        ForwardSelectContext,
        {
          __scopeSelect,
          itemContext,
          context,
          children
        }
      ) });
    if (!itemContext.interactions)
      return process.env.NODE_ENV === "development" && console.warn("No interactions provided to Select, potentially missing Adapt"), null;
    const {
      style,
      // remove this, it was set to "Select" always
      className,
      ...floatingProps
    } = itemContext.interactions.getFloatingProps(), composedRefs = composeRefs(
      forwardedRef,
      context.floatingContext?.refs.setFloating
    ), { scrollbarWidth, listStyleType, overflow, ...restStyle } = style;
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      !disableScroll && !props.unstyled && /* @__PURE__ */ jsx(
        "style",
        {
          dangerouslySetInnerHTML: {
            __html: selectViewportCSS
          }
        }
      ),
      /* @__PURE__ */ jsx(AnimatePresence, { children: context.open ? /* @__PURE__ */ jsx(FloatingFocusManager, { context: context.floatingContext, modal: !1, children: /* @__PURE__ */ jsx(
        SelectViewportFrame,
        {
          disableClassName: !0,
          size: itemContext.size,
          role: "presentation",
          ...viewportProps,
          ...floatingProps,
          ...restStyle,
          ...!props.unstyled && {
            overflow: disableScroll ? void 0 : overflow ?? "scroll"
          },
          ref: composedRefs,
          children
        },
        "select-viewport"
      ) }) : null }),
      !context.open && /* @__PURE__ */ jsx("div", { style: { display: "none" }, children: props.children })
    ] });
  }
), selectViewportCSS = `
.is_SelectViewport {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.is_SelectViewport::-webkit-scrollbar{
  display:none
}
`;
export {
  SelectViewport,
  SelectViewportFrame
};
