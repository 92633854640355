import { useContext } from "react";
import { ComponentContext } from "../contexts/ComponentContext";
import { jsx } from "react/jsx-runtime";
const Configuration = (props) => {
  const current = useContext(ComponentContext);
  return /* @__PURE__ */ jsx(ComponentContext.Provider, { ...current, ...props });
};
Configuration.displayName = "Configuration";
export {
  Configuration
};
