import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { useCallback, useRef } from "react";
function useGet(currentValue, initialValue, forwardToFunction) {
  const curRef = useRef(initialValue ?? currentValue);
  return useIsomorphicLayoutEffect(() => {
    curRef.current = currentValue;
  }), useCallback(
    forwardToFunction ? (...args) => curRef.current?.apply(null, args) : () => curRef.current,
    []
  );
}
export {
  useGet
};
