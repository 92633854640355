const defaultComponentState = {
  hover: !1,
  press: !1,
  pressIn: !1,
  focus: !1,
  unmounted: !0
}, defaultComponentStateMounted = {
  ...defaultComponentState,
  unmounted: !1
}, defaultComponentStateShouldEnter = {
  ...defaultComponentState,
  unmounted: "should-enter"
};
export {
  defaultComponentState,
  defaultComponentStateMounted,
  defaultComponentStateShouldEnter
};
