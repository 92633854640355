import { styled } from "@tamagui/web";
import { SizableText } from "./SizableText";
const Paragraph = styled(SizableText, {
  name: "Paragraph",
  tag: "p",
  userSelect: "auto",
  color: "$color",
  size: "$true"
});
export {
  Paragraph
};
