import { View, styled } from "@tamagui/core";
const Section = styled(View, {
  name: "Section",
  tag: "section",
  flexDirection: "column",
  accessibilityRole: "summary"
}), Article = styled(View, {
  name: "Article",
  tag: "article",
  flexDirection: "column"
}), Main = styled(View, {
  name: "Main",
  tag: "main",
  flexDirection: "column"
}), Header = styled(View, {
  name: "Header",
  tag: "header",
  accessibilityRole: "header",
  flexDirection: "column"
}), Aside = styled(View, {
  name: "Aside",
  tag: "aside",
  flexDirection: "column"
  // accessibilityRole: 'complementary',
}), Footer = styled(View, {
  name: "Footer",
  tag: "footer",
  flexDirection: "column"
  // accessibilityRole: 'contentinfo',
}), Nav = styled(View, {
  name: "Nav",
  tag: "nav",
  flexDirection: "column"
  // accessibilityRole: 'navigation',
});
export {
  Article,
  Aside,
  Footer,
  Header,
  Main,
  Nav,
  Section
};
