import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { Slottable, View, styled } from "@tamagui/core";
import { createContextScope } from "@tamagui/create-context";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogOverlayFrame,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DialogWarningProvider,
  createDialogScope
} from "@tamagui/dialog";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import * as React from "react";
import { jsx, jsxs } from "react/jsx-runtime";
const ROOT_NAME = "AlertDialog", [createAlertDialogContext, createAlertDialogScope] = createContextScope(ROOT_NAME, [
  createDialogScope
]), useDialogScope = createDialogScope(), TRIGGER_NAME = "AlertDialogTrigger", NativeAlertDialogTriggerFrame = styled(View, {
  name: TRIGGER_NAME
}), AlertDialogTrigger = React.forwardRef(
  (props, forwardedRef) => {
    if (props.__native) {
      const { __native, onPress, __onPress, ...rest } = props;
      return /* @__PURE__ */ jsx(
        NativeAlertDialogTriggerFrame,
        {
          ...rest,
          onPress: composeEventHandlers(onPress, __onPress)
        }
      );
    }
    const { __scopeAlertDialog, ...triggerProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
    return /* @__PURE__ */ jsx(DialogTrigger, { ...dialogScope, ...triggerProps, ref: forwardedRef });
  }
);
AlertDialogTrigger.displayName = TRIGGER_NAME;
const PORTAL_NAME = "AlertDialogPortal", AlertDialogPortal = (props) => {
  const { __scopeAlertDialog, ...portalProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
  return /* @__PURE__ */ jsx(DialogPortal, { ...dialogScope, ...portalProps });
};
AlertDialogPortal.displayName = PORTAL_NAME;
const OVERLAY_NAME = "AlertDialogOverlay", AlertDialogOverlayFrame = styled(DialogOverlayFrame, {
  name: OVERLAY_NAME
}), AlertDialogOverlay = AlertDialogOverlayFrame.extractable(
  React.forwardRef(
    (props, forwardedRef) => {
      const { __scopeAlertDialog, ...overlayProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
      return /* @__PURE__ */ jsx(DialogOverlay, { ...dialogScope, ...overlayProps, ref: forwardedRef });
    }
  )
);
AlertDialogOverlay.displayName = OVERLAY_NAME;
const CONTENT_NAME = "AlertDialogContent", [AlertDialogContentProvider, useAlertDialogContentContext] = createAlertDialogContext(CONTENT_NAME), AlertDialogContent = React.forwardRef(
  (props, forwardedRef) => {
    const { __scopeAlertDialog, children, ...contentProps } = props, dialogScope = useDialogScope(__scopeAlertDialog), contentRef = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, contentRef), cancelRef = React.useRef(null);
    return /* @__PURE__ */ jsx(
      DialogWarningProvider,
      {
        contentName: CONTENT_NAME,
        titleName: TITLE_NAME,
        docsSlug: "alert-dialog",
        children: /* @__PURE__ */ jsx(AlertDialogContentProvider, { scope: __scopeAlertDialog, cancelRef, children: /* @__PURE__ */ jsxs(
          DialogContent,
          {
            role: "alertdialog",
            ...dialogScope,
            ...contentProps,
            ref: composedRefs,
            onOpenAutoFocus: composeEventHandlers(
              contentProps.onOpenAutoFocus,
              (event) => {
                event.preventDefault(), isWeb && cancelRef.current?.focus({ preventScroll: !0 });
              }
            ),
            onPointerDownOutside: (event) => event.preventDefault(),
            onInteractOutside: (event) => event.preventDefault(),
            children: [
              /* @__PURE__ */ jsx(Slottable, { children }),
              process.env.NODE_ENV === "development" && /* @__PURE__ */ jsx(DescriptionWarning, { contentRef })
            ]
          }
        ) })
      }
    );
  }
);
AlertDialogContent.displayName = CONTENT_NAME;
const TITLE_NAME = "AlertDialogTitle", AlertDialogTitle = React.forwardRef(
  (props, forwardedRef) => {
    const { __scopeAlertDialog, ...titleProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
    return /* @__PURE__ */ jsx(DialogTitle, { ...dialogScope, ...titleProps, ref: forwardedRef });
  }
);
AlertDialogTitle.displayName = TITLE_NAME;
const DESCRIPTION_NAME = "AlertDialogDescription", AlertDialogDescription = React.forwardRef((props, forwardedRef) => {
  const { __scopeAlertDialog, ...descriptionProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
  return /* @__PURE__ */ jsx(DialogDescription, { ...dialogScope, ...descriptionProps, ref: forwardedRef });
});
AlertDialogDescription.displayName = DESCRIPTION_NAME;
const ACTION_NAME = "AlertDialogAction", AlertDialogAction = React.forwardRef(
  (props, forwardedRef) => {
    const { __scopeAlertDialog, ...actionProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
    return /* @__PURE__ */ jsx(DialogClose, { ...dialogScope, ...actionProps, ref: forwardedRef });
  }
);
AlertDialogAction.displayName = ACTION_NAME;
const CANCEL_NAME = "AlertDialogCancel", AlertDialogCancel = React.forwardRef(
  (props, forwardedRef) => {
    const { __scopeAlertDialog, ...cancelProps } = props, { cancelRef } = useAlertDialogContentContext(CANCEL_NAME, __scopeAlertDialog), dialogScope = useDialogScope(__scopeAlertDialog), ref = useComposedRefs(forwardedRef, cancelRef);
    return /* @__PURE__ */ jsx(DialogClose, { ...dialogScope, ...cancelProps, ref });
  }
);
AlertDialogCancel.displayName = CANCEL_NAME;
const DescriptionWarning = ({ contentRef }) => (process.env.NODE_ENV === "development" && React.useEffect(() => {
  if (!isWeb)
    return;
  document.getElementById(
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    contentRef.current?.getAttribute("aria-describedby")
  ) || console.warn(`\`${CONTENT_NAME}\` requires a description for the component to be accessible for screen reader users.
  
        You can add a description to the \`${CONTENT_NAME}\` by passing a \`${DESCRIPTION_NAME}\` component as a child, which also benefits sighted users by adding visible context to the dialog.
        
        Alternatively, you can use your own component as a description by assigning it an \`id\` and passing the same value to the \`aria-describedby\` prop in \`${CONTENT_NAME}\`. If the description is confusing or duplicative for sighted users, you can use the \`@radix-ui/react-visually-hidden\` primitive as a wrapper around your description component.
        
        For more information, see https://tamagui.dev/docs/components/alert-dialog`);
}, [contentRef]), null), AlertDialogInner = (props) => {
  const { __scopeAlertDialog, native, ...alertDialogProps } = props, dialogScope = useDialogScope(__scopeAlertDialog);
  return /* @__PURE__ */ jsx(Dialog, { ...dialogScope, ...alertDialogProps, modal: !0 });
};
const AlertDialog = withStaticProperties(AlertDialogInner, {
  Trigger: AlertDialogTrigger,
  Portal: AlertDialogPortal,
  Overlay: AlertDialogOverlay,
  Content: AlertDialogContent,
  Action: AlertDialogAction,
  Cancel: AlertDialogCancel,
  Title: AlertDialogTitle,
  Description: AlertDialogDescription
});
AlertDialog.displayName = ROOT_NAME;
export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  createAlertDialogScope
};
