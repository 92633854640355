import { Stack, View, styled } from "@tamagui/core";
import { createContextScope } from "@tamagui/create-context";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { jsx } from "react/jsx-runtime";
const FORM_NAME = "Form", FormFrame = styled(Stack, {
  name: FORM_NAME,
  tag: "form"
}), [createFormContext] = createContextScope(FORM_NAME), [FormProvider, useFormContext] = createFormContext(FORM_NAME), TRIGGER_NAME = "FormTrigger", FormTriggerFrame = styled(View, {
  name: TRIGGER_NAME
}), FormTrigger = FormTriggerFrame.styleable(
  (props, forwardedRef) => {
    const { __scopeForm, children, onPress, ...triggerProps } = props, context = useFormContext(TRIGGER_NAME, __scopeForm);
    return /* @__PURE__ */ jsx(
      FormTriggerFrame,
      {
        tag: "button",
        ...triggerProps,
        ref: forwardedRef,
        onPress: composeEventHandlers(onPress, context.onSubmit),
        children
      }
    );
  }
), FormComponent = FormFrame.extractable(function({
  onSubmit,
  ...props
}) {
  return /* @__PURE__ */ jsx(FormProvider, { scope: props.__scopeForm, onSubmit, children: /* @__PURE__ */ jsx(FormFrame, { ...props, onSubmit: (e) => e.preventDefault() }) });
}), Form2 = withStaticProperties(FormComponent, {
  Trigger: FormTrigger
});
export {
  Form2 as Form,
  FormFrame,
  FormProvider,
  FormTrigger,
  useFormContext
};
