import {
  autoUpdate,
  inner,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInnerOffset,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead
} from "@floating-ui/react";
import { isClient, isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { useEvent, useIsTouchDevice } from "@tamagui/core";
import * as React from "react";
import { flushSync } from "react-dom";
import { SCROLL_ARROW_THRESHOLD } from "./constants";
import {
  SelectItemParentProvider,
  SelectProvider,
  useSelectContext,
  useSelectItemParentContext
} from "./context";
import { jsx } from "react/jsx-runtime";
const SelectInlineImpl = (props) => {
  const {
    __scopeSelect,
    children,
    open = !1,
    selectedIndexRef,
    listContentRef
  } = props, selectContext = useSelectContext("SelectSheetImpl", __scopeSelect), selectItemParentContext = useSelectItemParentContext(
    "SelectSheetImpl",
    __scopeSelect
  ), { setActiveIndex, selectedIndex, activeIndex, forceUpdate } = selectContext, { setOpen, setSelectedIndex } = selectItemParentContext, [scrollTop, setScrollTop] = React.useState(0), touch = useIsTouchDevice(), listItemsRef = React.useRef([]), overflowRef = React.useRef(null), upArrowRef = React.useRef(null), downArrowRef = React.useRef(null), allowSelectRef = React.useRef(!1), allowMouseUpRef = React.useRef(!0), selectTimeoutRef = React.useRef(), state = React.useRef({
    isMouseOutside: !1
  }), [controlledScrolling, setControlledScrolling] = React.useState(!1), [fallback, setFallback] = React.useState(!1), [innerOffset, setInnerOffset] = React.useState(0), [blockSelection, setBlockSelection] = React.useState(!1), floatingStyle = React.useRef({});
  useIsomorphicLayoutEffect(() => {
    queueMicrotask(() => {
      open || (setScrollTop(0), setFallback(!1), setActiveIndex(null), setControlledScrolling(!1));
    });
  }, [open, setActiveIndex]), isWeb && isClient && useIsomorphicLayoutEffect(() => {
    if (!open)
      return;
    const mouseUp = (e) => {
      state.current.isMouseOutside && setOpen(!1);
    };
    return document.addEventListener("mouseup", mouseUp), () => {
      document.removeEventListener("mouseup", mouseUp);
    };
  }, [open]);
  const { x, y, strategy, context, refs, update } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    // biome-ignore lint/correctness/noConstantCondition: <explanation>
    middleware: [
      size({
        apply({
          rects: {
            reference: { width }
          }
        }) {
          Object.assign(floatingStyle.current, {
            minWidth: width + 8
          }), refs.floating.current && Object.assign(refs.floating.current.style, floatingStyle.current);
        }
      }),
      inner({
        listRef: listItemsRef,
        overflowRef,
        index: selectedIndex,
        offset: innerOffset,
        onFallbackChange: setFallback,
        padding: 10,
        minItemsVisible: touch ? 10 : 4,
        referenceOverflowThreshold: 20
      }),
      offset({ crossAxis: -5 })
    ]
  });
  useIsomorphicLayoutEffect(() => (window.addEventListener("resize", update), open && update(), () => window.removeEventListener("resize", update)), [update, open]);
  const floatingRef = refs.floating, showUpArrow = open && scrollTop > SCROLL_ARROW_THRESHOLD, showDownArrow = open && floatingRef.current && scrollTop < floatingRef.current.scrollHeight - floatingRef.current.clientHeight - SCROLL_ARROW_THRESHOLD, onMatch = useEvent((index) => (open ? setActiveIndex : setSelectedIndex)(index)), interactionsProps = [
    useClick(context, { event: "mousedown", keyboardHandlers: !1 }),
    useDismiss(context, { outsidePress: !1 }),
    useRole(context, { role: "listbox" }),
    useInnerOffset(context, {
      enabled: !fallback,
      onChange: setInnerOffset,
      overflowRef,
      scrollRef: refs.floating
    }),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex: activeIndex || 0,
      selectedIndex,
      onNavigate: setActiveIndex
    }),
    useTypeahead(context, {
      listRef: listContentRef,
      onMatch,
      selectedIndex,
      activeIndex
    })
  ], interactions = useInteractions(
    // unfortunately these memos will just always break due to floating-ui context always changing :/
    React.useMemo(() => interactionsProps, interactionsProps)
  ), interactionsContext = React.useMemo(() => ({
    ...interactions,
    getReferenceProps() {
      return interactions.getReferenceProps({
        ref: refs.reference,
        className: "SelectTrigger",
        onKeyDown(event) {
          (event.key === "Enter" || event.code === "Space" || event.key === " " && !context.dataRef.current.typing) && (event.preventDefault(), setOpen(!0));
        }
      });
    },
    getFloatingProps(props2) {
      return interactions.getFloatingProps({
        ref: refs.floating,
        className: "Select",
        ...props2,
        style: {
          position: strategy,
          top: y ?? "",
          left: x ?? "",
          outline: 0,
          scrollbarWidth: "none",
          ...floatingStyle.current,
          ...props2?.style
        },
        onPointerEnter() {
          setControlledScrolling(!1), state.current.isMouseOutside = !1;
        },
        onPointerLeave() {
          state.current.isMouseOutside = !0;
        },
        onPointerMove() {
          state.current.isMouseOutside = !1, setControlledScrolling(!1);
        },
        onKeyDown() {
          setControlledScrolling(!0);
        },
        onContextMenu(e) {
          e.preventDefault();
        },
        onScroll(event) {
          flushSync(() => {
            setScrollTop(event.currentTarget.scrollTop);
          });
        }
      });
    }
  }), [refs.reference.current, x, y, refs.floating.current, interactions]);
  return useIsomorphicLayoutEffect(() => {
    if (open)
      return selectTimeoutRef.current = setTimeout(() => {
        allowSelectRef.current = !0;
      }, 300), () => {
        clearTimeout(selectTimeoutRef.current);
      };
    allowSelectRef.current = !1, allowMouseUpRef.current = !0, setInnerOffset(0), setFallback(!1), setBlockSelection(!1);
  }, [open]), useIsomorphicLayoutEffect(() => {
    !open && state.current.isMouseOutside && (state.current.isMouseOutside = !1);
  }, [open]), useIsomorphicLayoutEffect(() => {
    function onPointerDown(e) {
      const target = e.target;
      refs.floating.current?.contains(target) || upArrowRef.current?.contains(target) || downArrowRef.current?.contains(target) || (setOpen(!1), setControlledScrolling(!1));
    }
    if (open)
      return document.addEventListener("pointerdown", onPointerDown), () => {
        document.removeEventListener("pointerdown", onPointerDown);
      };
  }, [open, refs, setOpen]), React.useEffect(() => {
    open && controlledScrolling && activeIndex != null && listItemsRef.current[activeIndex]?.scrollIntoView({ block: "nearest" }), setScrollTop(refs.floating.current?.scrollTop ?? 0);
  }, [open, refs, controlledScrolling, activeIndex]), React.useEffect(() => {
    open && fallback && selectedIndex != null && listItemsRef.current[selectedIndex]?.scrollIntoView({ block: "nearest" });
  }, [open, fallback, selectedIndex]), useIsomorphicLayoutEffect(() => {
    refs.floating.current && fallback && (refs.floating.current.style.maxHeight = "");
  }, [refs, fallback]), /* @__PURE__ */ jsx(
    SelectProvider,
    {
      scope: __scopeSelect,
      ...selectContext,
      setScrollTop,
      setInnerOffset,
      fallback,
      floatingContext: context,
      activeIndex,
      canScrollDown: !!showDownArrow,
      canScrollUp: !!showUpArrow,
      controlledScrolling,
      blockSelection,
      upArrowRef,
      downArrowRef,
      update,
      children: /* @__PURE__ */ jsx(
        SelectItemParentProvider,
        {
          scope: __scopeSelect,
          ...selectItemParentContext,
          allowMouseUpRef,
          allowSelectRef,
          dataRef: context.dataRef,
          interactions: interactionsContext,
          listRef: listItemsRef,
          selectTimeoutRef,
          children
        }
      )
    }
  );
};
export {
  SelectInlineImpl
};
