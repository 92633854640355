import React, { FC, useEffect, useState, useContext } from 'react'
import { useWindowDimensions, Platform } from 'react-native'
// import { loadAsync } from 'expo-font'

import { TokensContext, ComponentsContext, BreakpointContext } from './context'
import { ServerContext } from '@data/ServerContext'

// Use `require` to avoid Webpack tree shaking
const InterVariableFont = require('../../assets/Inter-Font/InterVariable.woff2')
const InterVariableItalicFont = require('../../assets/Inter-Font/InterVariable-Italic.woff2')
const AtomicMarker = require('../../assets/AtomicMarker-Font/Regular-removed-glyphs.woff2')
const AtomicMarkerExtras = require('../../assets/AtomicMarker-Font/Extras-removed-glyphs.woff2')

if (process.env.NODE_ENV !== 'production') {
  // Needed for social cards...
  const Inter300 = require('../../assets/Inter-Font/Inter-Light.ttf')
  const Inter400 = require('../../assets/Inter-Font/Inter-Regular.ttf')
  const Inter500 = require('../../assets/Inter-Font/Inter-Medium.ttf')
  const Inter600 = require('../../assets/Inter-Font/Inter-SemiBold.ttf')
  const Inter700 = require('../../assets/Inter-Font/Inter-Bold.ttf')
  const Inter800 = require('../../assets/Inter-Font/Inter-ExtraBold.ttf')

  // Needed for emails...
  const Inter300Woff = require('../../assets/Inter-Font/Inter-Light.woff2')
  const Inter400Woff = require('../../assets/Inter-Font/Inter-Regular.woff2')
  const Inter500Woff = require('../../assets/Inter-Font/Inter-Medium.woff2')
  const Inter600Woff = require('../../assets/Inter-Font/Inter-SemiBold.woff2')
  const Inter700Woff = require('../../assets/Inter-Font/Inter-Bold.woff2')
  const Inter800Woff = require('../../assets/Inter-Font/Inter-ExtraBold.woff2')
}

interface ProviderProps {
  tokens: any
  components: any
}

export const Provider: FC<ProviderProps> = ({
  tokens,
  components,
  children,
}) => {
  const serverContext = useContext(ServerContext)
  const [fontsLoaded, setFontsLoaded] = useState(
    serverContext || Platform.OS === 'web' ? true : false
  )

  // useEffect(() => {
  //   // Web fonts are loaded in the initial HTML payload
  //   if (Platform.OS === 'web') {
  //     return
  //   }

  //   if (fontsLoaded) {
  //     return
  //   }

  //   const loadFonts = async () => {
  //     await loadAsync({
  //       // Inter100: require('../../assets/Inter-Font/Inter-Thin.ttf'),
  //       // Inter200: require('../../assets/Inter-Font/Inter-ExtraLight.ttf'),
  //       Inter300,
  //       Inter400,
  //       Inter500,
  //       Inter600,
  //       Inter700,
  //       // Inter800: require('../../assets/Inter-Font/Inter-ExtraBold.ttf'),
  //       // Inter900: require('../../assets/Inter-Font/Inter-Black.ttf'),
  //     })

  //     setFontsLoaded(true)
  //   }

  //   loadFonts()
  // }, [fontsLoaded, setFontsLoaded])

  const { width: windowWidth } = useWindowDimensions()

  const content =
    serverContext !== null ? children : fontsLoaded ? children : null

  return (
    <TokensContext.Provider value={tokens}>
      <ComponentsContext.Provider value={components}>
        <BreakpointContext.Provider
          value={
            serverContext !== null
              ? serverContext.defaultBreakpoint
              : computeBreakpoint(windowWidth, tokens.breakpoints)
          }
        >
          {content}
        </BreakpointContext.Provider>
      </ComponentsContext.Provider>
    </TokensContext.Provider>
  )
}

function computeBreakpoint(windowWidth: number, breakpoints: number[]): number {
  if (breakpoints.length === 0) {
    return 0
  }

  const computedBreakpoint = breakpoints.findIndex(
    (breakpoint) => breakpoint >= windowWidth
  )

  // Return the index of the last breakpoint plus 1, to signify it's beyond what's configured
  if (computedBreakpoint < 0) {
    return breakpoints.length
  }

  return computedBreakpoint
}
