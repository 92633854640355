import "@tamagui/constants";
import { tokenCategories } from "@tamagui/helpers";
import { getConfig } from "../config";
import { getVariableValue, isVariable } from "../createVariable";
import { expandStyle } from "./expandStyle";
import { normalizeStyle } from "./normalizeStyle";
import { getFontsForLanguage, getVariantExtras } from "./getVariantExtras";
import { isObj } from "./isObj";
import { pseudoDescriptors } from "./pseudoDescriptors";
import { skipProps } from "./skipProps";
const propMapper = (key, value, styleStateIn, subPropsIn) => {
  if (lastFontFamilyToken = null, key === "elevationAndroid")
    return;
  if (value === "unset") {
    const unsetVal = styleStateIn.conf.unset?.[key];
    if (unsetVal != null)
      value = unsetVal;
    else
      return;
  }
  const subProps = styleStateIn.styleProps.fallbackProps || subPropsIn, styleState = subProps ? new Proxy(styleStateIn, {
    get(_, k) {
      return k === "curProps" ? subProps : Reflect.get(_, k);
    }
  }) : styleStateIn, { conf, styleProps, fontFamily, staticConfig } = styleState, { variants } = staticConfig;
  if (process.env.NODE_ENV === "development" && fontFamily && fontFamily[0] === "$" && !(fontFamily in conf.fontsParsed) && console.warn(
    `Warning: no fontFamily "${fontFamily}" found in config: ${Object.keys(
      conf.fontsParsed
    ).join(", ")}`
  ), !styleProps.noExpand && variants && key in variants) {
    styleState.curProps[key] = value;
    const variantValue = resolveVariants(key, value, styleProps, styleState, "");
    if (variantValue)
      return variantValue;
  }
  let shouldReturn = !1;
  if (styleProps.disableExpandShorthands || key in conf.shorthands && (shouldReturn = !0, key = conf.shorthands[key]), value && (value[0] === "$" ? value = getTokenForKey(key, value, styleProps.resolveValues, styleState) : isVariable(value) && (value = resolveVariableValue(key, value, styleProps.resolveValues))), shouldReturn || value != null) {
    const result = (styleProps.noExpand ? null : expandStyle(key, value)) || [
      [key, value]
    ];
    return key === "fontFamily" && lastFontFamilyToken && fontFamilyCache.set(result, lastFontFamilyToken), result;
  }
}, resolveVariants = (key, value, styleProps, styleState, parentVariantKey) => {
  const { staticConfig, conf, debug } = styleState, { variants } = staticConfig;
  if (!variants)
    return;
  let variantValue = getVariantDefinition(variants[key], value, conf);
  if (process.env.NODE_ENV === "development" && debug === "verbose" && (console.groupCollapsed(`\u2666\uFE0F\u2666\uFE0F\u2666\uFE0F resolve variant ${key}`), console.info({
    key,
    value,
    variantValue,
    variants,
    curProps: { ...styleState.curProps }
  }), console.groupEnd()), !variantValue) {
    if (process.env.TAMAGUI_WARN_ON_MISSING_VARIANT === "1" && typeof value != "boolean") {
      const name = staticConfig.componentName || "[UnnamedComponent]";
      console.warn(
        `No variant found: ${name} has variant "${key}", but no matching value "${value}"`
      );
    }
    return;
  }
  if (typeof variantValue == "function") {
    const fn = variantValue, extras = getVariantExtras(styleState);
    variantValue = fn(value, extras), process.env.NODE_ENV === "development" && debug === "verbose" && (console.groupCollapsed("   expanded functional variant", key), console.info({ fn, variantValue, extras }), console.groupEnd());
  }
  let fontFamilyResult;
  if (isObj(variantValue)) {
    const fontFamilyUpdate = variantValue.fontFamily || variantValue[conf.inverseShorthands.fontFamily];
    fontFamilyUpdate && (fontFamilyResult = getFontFamilyFromNameOrVariable(fontFamilyUpdate, conf), styleState.fontFamily = fontFamilyResult, process.env.NODE_ENV === "development" && debug === "verbose" && console.info("   updating font family", fontFamilyResult)), variantValue = resolveTokensAndVariants(
      key,
      variantValue,
      styleProps,
      styleState,
      parentVariantKey
    );
  }
  if (variantValue) {
    const expanded = normalizeStyle(variantValue, !!styleProps.noNormalize);
    process.env.NODE_ENV === "development" && debug === "verbose" && console.info("   expanding styles from ", variantValue, "to", expanded);
    const next = Object.entries(expanded);
    return fontFamilyResult && fontFamilyResult[0] === "$" && fontFamilyCache.set(next, getVariableValue(fontFamilyResult)), next;
  }
};
function getFontFamilyFromNameOrVariable(input, conf) {
  if (isVariable(input)) {
    const val = variableToFontNameCache.get(input);
    if (val)
      return val;
    for (const key in conf.fontsParsed) {
      const familyVariable = conf.fontsParsed[key].family;
      if (isVariable(familyVariable) && (variableToFontNameCache.set(familyVariable, key), familyVariable === input))
        return key;
    }
  } else if (typeof input == "string" && input[0] === "$")
    return input;
}
const variableToFontNameCache = /* @__PURE__ */ new WeakMap(), fontFamilyCache = /* @__PURE__ */ new WeakMap(), getPropMappedFontFamily = (expanded) => expanded && fontFamilyCache.get(expanded), resolveTokensAndVariants = (key, value, styleProps, styleState, parentVariantKey) => {
  const { conf, staticConfig, debug, theme } = styleState, { variants } = staticConfig, res = {};
  process.env.NODE_ENV === "development" && debug === "verbose" && console.info("   - resolveTokensAndVariants", key, value);
  for (const _key in value) {
    const subKey = conf.shorthands[_key] || _key, val = value[_key];
    if (!(!styleProps.noSkip && subKey in skipProps)) {
      if (styleProps.noExpand)
        res[subKey] = val;
      else if (variants && subKey in variants) {
        if (styleState.curProps[subKey] = val, parentVariantKey && parentVariantKey === key)
          res[subKey] = // SYNC WITH *1
          val[0] === "$" ? getTokenForKey(subKey, val, styleProps.resolveValues, styleState) : val;
        else {
          const variantOut = resolveVariants(subKey, val, styleProps, styleState, key);
          if (variantOut)
            for (const [key2, val2] of variantOut)
              val2 != null && (key2 in pseudoDescriptors ? (res[key2] ??= {}, Object.assign(res[key2], val2)) : res[key2] = val2);
        }
        continue;
      }
      if (isVariable(val)) {
        res[subKey] = resolveVariableValue(subKey, val, styleProps.resolveValues), process.env.NODE_ENV === "development" && debug === "verbose" && console.info("variable", subKey, res[subKey]);
        continue;
      }
      if (typeof val == "string") {
        const fVal = (
          // SYNC WITH *1
          val[0] === "$" ? getTokenForKey(subKey, val, styleProps.resolveValues, styleState) : val
        );
        res[subKey] = fVal;
        continue;
      }
      if (isObj(val)) {
        const subObject = resolveTokensAndVariants(subKey, val, styleProps, styleState, key);
        process.env.NODE_ENV === "development" && debug === "verbose" && console.info("object", subKey, subObject), res[subKey] ??= {}, Object.assign(res[subKey], subObject);
      } else
        res[subKey] = val;
      process.env.NODE_ENV === "development" && debug && res[subKey]?.[0] === "$" && console.warn(
        `\u26A0\uFE0F Missing token in theme ${theme.name}:`,
        subKey,
        res[subKey],
        theme
      );
    }
  }
  return res;
}, tokenCats = ["size", "color", "radius", "space", "zIndex"].map((name) => ({
  name,
  spreadName: `...${name}`
}));
function getVariantDefinition(variant, value, conf) {
  if (typeof variant == "function")
    return variant;
  const exact = variant[value];
  if (exact)
    return exact;
  if (value != null) {
    const { tokensParsed } = conf;
    for (const { name, spreadName } of tokenCats)
      if (spreadName in variant && value in tokensParsed[name])
        return variant[spreadName];
    const fontSizeVariant = variant["...fontSize"];
    if (fontSizeVariant && conf.fontSizeTokens.has(value))
      return fontSizeVariant;
  }
  return variant[`:${typeof value}`] || variant["..."];
}
const fontShorthand = {
  fontSize: "size",
  fontWeight: "weight"
};
let lastFontFamilyToken = null;
const getTokenForKey = (key, value, resolveAs = "none", styleState) => {
  if (resolveAs === "none")
    return value;
  const { theme, conf = getConfig(), context, fontFamily, staticConfig } = styleState, tokensParsed = conf.tokensParsed;
  let valOrVar, hasSet = !1;
  if (theme && value in theme)
    valOrVar = theme[value], process.env.NODE_ENV === "development" && styleState.debug === "verbose" && console.info(` - resolving ${key} to theme value ${value}: ${valOrVar?.get?.()}`), hasSet = !0;
  else if (value in conf.specificTokens)
    hasSet = !0, valOrVar = conf.specificTokens[value];
  else {
    const customTokenAccept = staticConfig?.acceptTokens?.[key];
    if (customTokenAccept) {
      const val = tokensParsed[customTokenAccept][value];
      val && (valOrVar = val, hasSet = !0);
    } else {
      switch (key) {
        case "fontFamily": {
          valOrVar = (context?.language ? getFontsForLanguage(conf.fontsParsed, context.language) : conf.fontsParsed)[value]?.family || value, lastFontFamilyToken = value, hasSet = !0;
          break;
        }
        case "fontSize":
        case "lineHeight":
        case "letterSpacing":
        case "fontWeight": {
          const defaultFont = conf.defaultFont || "$body", fam = fontFamily || defaultFont;
          if (fam) {
            const fontsParsed = context?.language ? getFontsForLanguage(conf.fontsParsed, context.language) : conf.fontsParsed;
            valOrVar = (fontsParsed[fam] || fontsParsed[defaultFont])?.[fontShorthand[key] || key]?.[value] || value, hasSet = !0;
          }
          break;
        }
      }
      for (const cat in tokenCategories)
        if (key in tokenCategories[cat]) {
          const res = tokensParsed[cat][value];
          res != null && (valOrVar = res, hasSet = !0);
        }
    }
    if (!hasSet) {
      const spaceVar = tokensParsed.space[value];
      spaceVar != null && (valOrVar = spaceVar, hasSet = !0);
    }
  }
  if (hasSet) {
    const out = resolveVariableValue(key, valOrVar, resolveAs);
    return process.env.NODE_ENV === "development" && styleState.debug === "verbose" && console.info("resolved", resolveAs, valOrVar, out), out;
  }
  process.env.NODE_ENV === "development" && styleState.debug === "verbose" && console.warn(`Warning: no token found for ${key}, omitting`);
};
function resolveVariableValue(key, valOrVar, resolveValues) {
  if (resolveValues === "none")
    return valOrVar;
  if (isVariable(valOrVar)) {
    if (resolveValues === "value")
      return valOrVar.val;
    const get = valOrVar?.get;
    return typeof get == "function" ? get(resolveValues === "web" ? "web" : void 0) : valOrVar.variable;
  }
  return valOrVar;
}
export {
  getFontFamilyFromNameOrVariable,
  getPropMappedFontFamily,
  getTokenForKey,
  propMapper
};
