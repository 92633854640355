import { getSpace } from "@tamagui/get-token";
const getButtonSized = (val, { tokens, props }) => {
  if (!val || props.circular)
    return;
  if (typeof val == "number")
    return {
      paddingHorizontal: val * 0.25,
      height: val,
      borderRadius: props.circular ? 1e5 : val * 0.2
    };
  const xSize = getSpace(val), radiusToken = tokens.radius[val] ?? tokens.radius.$true;
  return {
    paddingHorizontal: xSize,
    height: val,
    borderRadius: props.circular ? 1e5 : radiusToken
  };
};
export {
  getButtonSized
};
