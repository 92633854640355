import { useEffect, useState } from "react";
import { Keyboard } from "react-native-web";
const useKeyboardVisible = () => {
  const [isKeyboardVisible, setKeyboardVisible] = useState(!1);
  return useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener("keyboardDidShow", () => {
      setKeyboardVisible(!0);
    }), keyboardDidHideListener = Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardVisible(!1);
    });
    return () => {
      keyboardDidHideListener.remove(), keyboardDidShowListener.remove();
    };
  }, []), isKeyboardVisible;
};
export {
  useKeyboardVisible
};
