import { validStyles } from "@tamagui/helpers";
import { stackDefaultStyles } from "../constants/constants";
import { createComponent } from "../createComponent";
const Stack = createComponent({
  acceptsClassName: !0,
  defaultProps: stackDefaultStyles,
  validStyles
});
Stack.displayName = "Stack";
export {
  Stack
};
