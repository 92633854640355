import { useSyncExternalStore } from "react";
const emptyFn = () => {
}, emptyFnFn = () => emptyFn;
function useDidFinishSSR(value) {
  return useSyncExternalStore(
    emptyFnFn,
    () => value ?? !0,
    () => !1
  );
}
function useClientValue(value) {
  return useDidFinishSSR() ? typeof value == "function" ? value() : value : void 0;
}
export {
  useClientValue,
  useDidFinishSSR
};
