import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { ListItemFrame, useListItem } from "@tamagui/list-item";
import * as React from "react";
import { createSelectContext, useSelectItemParentContext } from "./context";
import { jsx } from "react/jsx-runtime";
const ITEM_NAME = "SelectItem", [SelectItemContextProvider, useSelectItemContext] = createSelectContext(ITEM_NAME), SelectItem = ListItemFrame.styleable(
  function(props, forwardedRef) {
    const {
      __scopeSelect,
      value,
      disabled = !1,
      textValue: textValueProp,
      index,
      ...restProps
    } = props, { props: listItemProps } = useListItem({
      ...!props.unstyled && {
        ellipse: !0
      },
      ...restProps
    }), context = useSelectItemParentContext(ITEM_NAME, __scopeSelect), {
      setSelectedIndex,
      listRef,
      setOpen,
      onChange,
      activeIndexSubscribe,
      valueSubscribe,
      allowMouseUpRef,
      allowSelectRef,
      setValueAtIndex,
      selectTimeoutRef,
      dataRef,
      interactions,
      shouldRenderWebNative,
      size,
      onActiveChange,
      initialValue
    } = context, [isSelected, setSelected] = React.useState(initialValue === value);
    React.useEffect(() => activeIndexSubscribe((i) => {
      index === i && (onActiveChange(value, index), listRef?.current[index]?.focus());
    }), [index]), React.useEffect(() => valueSubscribe((val) => {
      setSelected(val === value);
    }), [value]);
    const textId = React.useId(), composedRefs = useComposedRefs(forwardedRef, (node) => {
      isWeb && node instanceof HTMLElement && listRef && (listRef.current[index] = node);
    });
    useIsomorphicLayoutEffect(() => {
      setValueAtIndex(index, value);
    }, [index, setValueAtIndex, value]);
    function handleSelect() {
      setSelectedIndex(index), onChange(value), setOpen(!1);
    }
    const selectItemProps = React.useMemo(() => interactions ? interactions.getItemProps({
      onTouchMove() {
        allowSelectRef.current = !0, allowMouseUpRef.current = !1;
      },
      onTouchEnd() {
        allowSelectRef.current = !1, allowMouseUpRef.current = !0;
      },
      onKeyDown(event) {
        event.key === "Enter" || event.key === " " && !dataRef?.current.typing ? (event.preventDefault(), handleSelect()) : allowSelectRef.current = !0;
      },
      onClick() {
        allowSelectRef.current && handleSelect();
      },
      onMouseUp() {
        allowMouseUpRef.current && (allowSelectRef.current && handleSelect(), clearTimeout(selectTimeoutRef.current), selectTimeoutRef.current = setTimeout(() => {
          allowSelectRef.current = !0;
        }));
      }
    }) : {
      onPress: handleSelect
    }, [handleSelect]);
    return /* @__PURE__ */ jsx(
      SelectItemContextProvider,
      {
        scope: __scopeSelect,
        value,
        textId: textId || "",
        isSelected,
        children: shouldRenderWebNative ? /* @__PURE__ */ jsx("option", { value, children: props.children }) : /* @__PURE__ */ jsx(
          ListItemFrame,
          {
            tag: "div",
            componentName: ITEM_NAME,
            ref: composedRefs,
            "aria-labelledby": textId,
            "aria-selected": isSelected,
            "data-state": isSelected ? "active" : "inactive",
            "aria-disabled": disabled || void 0,
            "data-disabled": disabled ? "" : void 0,
            tabIndex: disabled ? void 0 : -1,
            ...!props.unstyled && {
              backgrounded: !0,
              pressTheme: !0,
              hoverTheme: !0,
              cursor: "default",
              size,
              outlineOffset: -1,
              focusStyle: {
                outlineColor: "$outlineColor",
                outlineWidth: 2,
                outlineStyle: "solid",
                outlineOffset: -2
              }
            },
            ...listItemProps,
            ...selectItemProps
          }
        )
      }
    );
  },
  {
    disableTheme: !0
  }
);
export {
  SelectItem,
  SelectItemContextProvider,
  useSelectItemContext
};
