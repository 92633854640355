'use strict';

/*
 * Stubbed for web, where we don't use this file;
 */
export function withReanimatedTimer() {
  // NOOP
}
export function advanceAnimationByTime() {
  // NOOP
}
export function advanceAnimationByFrame() {
  // NOOP
}
export function setUpTests() {
  // NOOP
}
export function getAnimatedStyle() {
  // NOOP
}
