import { isClient } from "@tamagui/core";
const constants = {}, SHEET_NAME = "Sheet", SHEET_HANDLE_NAME = "SheetHandle", SHEET_OVERLAY_NAME = "SheetOverlay", SHEET_HIDDEN_STYLESHEET = isClient ? document.createElement("style") : null;
SHEET_HIDDEN_STYLESHEET && document.head.appendChild(SHEET_HIDDEN_STYLESHEET);
export {
  SHEET_HANDLE_NAME,
  SHEET_HIDDEN_STYLESHEET,
  SHEET_NAME,
  SHEET_OVERLAY_NAME,
  constants
};
