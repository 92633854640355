const cache = /* @__PURE__ */ new Map(), simpleHash = (str, hashMin = 10) => {
  if (cache.has(str))
    return cache.get(str);
  let hash = 0, valids = "";
  const len = str.length;
  for (let i = 0; i < len; i++) {
    const char = str.charCodeAt(i);
    if (hashMin !== "strict" && (char === 46 && (valids += "d0t"), isValidCSSCharCode(char) && len <= hashMin)) {
      valids += str[i];
      continue;
    }
    hash = hashChar(hash, str[i]);
  }
  const res = valids + (hash ? Math.abs(hash) : "");
  return cache.size > 1e4 && cache.clear(), cache.set(str, res), res;
}, hashChar = (hash, c) => Math.imul(31, hash) + c.charCodeAt(0) | 0;
function isValidCSSCharCode(code) {
  return (
    // A-Z
    code >= 65 && code <= 90 || // a-z
    code >= 97 && code <= 122 || // _
    code === 95 || // -
    code === 45 || // 0-9
    code >= 48 && code <= 57
  );
}
export {
  simpleHash
};
