function resisted(y, minY, maxOverflow = 25) {
  if (y < minY) {
    const past = minY - y, extra = -(1.1 - 0.15 ** (Math.min(maxOverflow, past) / maxOverflow)) * maxOverflow;
    return minY + extra;
  }
  return y;
}
export {
  resisted
};
