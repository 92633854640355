import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import {
  createStyledContext,
  getVariableValue,
  styled,
  useProps,
  useTheme
} from "@tamagui/core";
import { createContextScope } from "@tamagui/create-context";
import { getFontSize } from "@tamagui/font-size";
import { getSize } from "@tamagui/get-token";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { useGetThemedIcon } from "@tamagui/helpers-tamagui";
import { useLabelContext } from "@tamagui/label";
import { ButtonNestingContext, ThemeableStack } from "@tamagui/stacks";
import { useControllableState } from "@tamagui/use-controllable-state";
import { usePrevious } from "@tamagui/use-previous";
import * as React from "react";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const CheckboxStyledContext = createStyledContext({
  size: "$true",
  scaleIcon: 1
});
function isIndeterminate(checked) {
  return checked === "indeterminate";
}
function getState(checked) {
  return isIndeterminate(checked) ? "indeterminate" : checked ? "checked" : "unchecked";
}
const BubbleInput = (props) => {
  const { checked, bubbles = !0, control, isHidden, ...inputProps } = props, ref = React.useRef(null), prevChecked = usePrevious(checked);
  return React.useEffect(() => {
    const input = ref.current, inputProto = window.HTMLInputElement.prototype, setChecked = Object.getOwnPropertyDescriptor(
      inputProto,
      "checked"
    ).set;
    if (prevChecked !== checked && setChecked) {
      const event = new Event("click", { bubbles });
      input.indeterminate = isIndeterminate(checked), setChecked.call(input, isIndeterminate(checked) ? !1 : checked), input.dispatchEvent(event);
    }
  }, [prevChecked, checked, bubbles]), /* @__PURE__ */ jsx(
    "input",
    {
      type: "checkbox",
      defaultChecked: isIndeterminate(checked) ? !1 : checked,
      ...inputProps,
      tabIndex: -1,
      ref,
      "aria-hidden": isHidden,
      style: {
        ...isHidden ? {
          // ...controlSize,
          position: "absolute",
          pointerEvents: "none",
          opacity: 0,
          margin: 0
        } : {
          appearance: "auto",
          accentColor: "var(--color6)"
        },
        ...props.style
      }
    }
  );
}, INDICATOR_NAME = "CheckboxIndicator", CheckboxIndicatorFrame = styled(ThemeableStack, {
  // use Checkbox for easier themes
  name: INDICATOR_NAME,
  context: CheckboxStyledContext
}), CheckboxIndicator = CheckboxIndicatorFrame.extractable(
  React.forwardRef(
    (props, forwardedRef) => {
      const {
        __scopeCheckbox,
        children: childrenProp,
        forceMount,
        disablePassStyles,
        ...indicatorProps
      } = props;
      process.env.NODE_ENV === "development" && !childrenProp && console.warn(
        "Warning: You created a Checkbox.Indicator without passing an child prop for it to use as an icon."
      );
      const context = useCheckboxContext(INDICATOR_NAME, __scopeCheckbox), styledContext = React.useContext(CheckboxStyledContext), iconSize = (typeof styledContext.size == "number" ? styledContext.size * 0.65 : getFontSize(styledContext.size)) * styledContext.scaleIcon, theme = useTheme(), getThemedIcon = useGetThemedIcon({ size: iconSize, color: theme.color }), children = React.Children.toArray(childrenProp).map((child) => disablePassStyles || !React.isValidElement(child) ? child : getThemedIcon(child));
      return forceMount || isIndeterminate(context.state) || context.state === !0 ? /* @__PURE__ */ jsx(
        CheckboxIndicatorFrame,
        {
          "data-state": getState(context.state),
          "data-disabled": context.disabled ? "" : void 0,
          pointerEvents: "none",
          ...indicatorProps,
          ref: forwardedRef,
          children
        }
      ) : null;
    }
  )
);
CheckboxIndicator.displayName = INDICATOR_NAME;
const CHECKBOX_NAME = "Checkbox", CheckboxFrame = styled(ThemeableStack, {
  name: CHECKBOX_NAME,
  tag: "button",
  context: CheckboxStyledContext,
  variants: {
    unstyled: {
      false: {
        size: "$true",
        backgroundColor: "$background",
        alignItems: "center",
        justifyContent: "center",
        pressTheme: !0,
        focusable: !0,
        borderWidth: 1,
        borderColor: "$borderColor",
        hoverStyle: {
          borderColor: "$borderColorHover"
        },
        focusStyle: {
          borderColor: "$borderColorFocus",
          outlineStyle: "solid",
          outlineWidth: 2,
          outlineColor: "$outlineColor"
        }
      }
    },
    disabled: {
      true: {
        pointerEvents: "none",
        userSelect: "none",
        cursor: "not-allowed",
        hoverStyle: {
          borderColor: "$borderColor",
          backgroundColor: "$background"
        },
        pressStyle: {
          borderColor: "$borderColor",
          backgroundColor: "$backgroundColor"
        },
        focusStyle: {
          outlineWidth: 0
        }
      }
    },
    size: {
      "...size": (val, { tokens }) => ({
        borderRadius: getVariableValue(getSize(val)) / 8
      })
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), [createCheckboxContext, createCheckboxScope] = createContextScope(CHECKBOX_NAME), [CheckboxProvider, useCheckboxContext] = createCheckboxContext(CHECKBOX_NAME), CheckboxComponent = CheckboxFrame.styleable(function(props, forwardedRef) {
  const {
    __scopeCheckbox,
    labelledBy: ariaLabelledby,
    name,
    checked: checkedProp,
    defaultChecked,
    required,
    scaleSize = 0.45,
    sizeAdjust = 0,
    disabled,
    value = "on",
    onCheckedChange,
    native,
    scaleIcon,
    ...checkboxProps
  } = props, isInsideButton = React.useContext(ButtonNestingContext), [button, setButton] = React.useState(null), composedRefs = useComposedRefs(forwardedRef, (node) => setButton(node)), hasConsumerStoppedPropagationRef = React.useRef(!1), propsActive = useProps(props), isFormControl = isWeb ? button ? !!button.closest("form") : !0 : !1, [checked = !1, setChecked] = useControllableState({
    prop: checkedProp,
    defaultProp: defaultChecked,
    onChange: onCheckedChange
  }), styledContext = React.useContext(CheckboxStyledContext), adjustedSize = getVariableValue(
    getSize(propsActive.size ?? styledContext?.size ?? "$true", {
      shift: sizeAdjust
    })
  ), size = scaleSize ? Math.round(adjustedSize * scaleSize) : adjustedSize, labelId = useLabelContext(button);
  return /* @__PURE__ */ jsx(CheckboxProvider, { scope: __scopeCheckbox, state: checked, disabled: !!disabled, children: isWeb && native ? /* @__PURE__ */ jsx(
    BubbleInput,
    {
      control: button,
      bubbles: !hasConsumerStoppedPropagationRef.current,
      name,
      value,
      checked,
      required,
      disabled,
      id: props.id
    }
  ) : /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      CheckboxFrame,
      {
        width: size,
        height: size,
        tag: isInsideButton ? "span" : "button",
        role: "checkbox",
        "aria-labelledby": ariaLabelledby || labelId,
        "aria-checked": isIndeterminate(checked) ? "mixed" : checked,
        "aria-required": required,
        "data-state": getState(checked),
        "data-disabled": disabled ? "" : void 0,
        disabled,
        ...checkboxProps,
        ref: composedRefs,
        ...isWeb && {
          type: "button",
          value,
          onKeyDown: composeEventHandlers(
            props.onKeyDown,
            (event) => {
              event.key === "Enter" && event.preventDefault();
            }
          )
        },
        onPress: composeEventHandlers(props.onPress, (event) => {
          setChecked(
            (prevChecked) => isIndeterminate(prevChecked) ? !0 : !prevChecked
          ), isFormControl && (hasConsumerStoppedPropagationRef.current = event.isPropagationStopped(), hasConsumerStoppedPropagationRef.current || event.stopPropagation());
        }),
        children: /* @__PURE__ */ jsx(
          CheckboxStyledContext.Provider,
          {
            size: propsActive.size ?? styledContext?.size ?? "$true",
            scaleIcon: scaleIcon ?? styledContext?.scaleIcon ?? 1,
            children: propsActive.children
          }
        )
      }
    ),
    isWeb && isFormControl ? /* @__PURE__ */ jsx(
      BubbleInput,
      {
        isHidden: !0,
        control: button,
        bubbles: !hasConsumerStoppedPropagationRef.current,
        name,
        value,
        checked,
        required,
        disabled
      }
    ) : null
  ] }) });
}), Checkbox2 = withStaticProperties(CheckboxComponent, {
  Indicator: CheckboxIndicator,
  Props: CheckboxStyledContext.Provider
});
export {
  BubbleInput,
  Checkbox2 as Checkbox,
  CheckboxFrame,
  CheckboxStyledContext,
  createCheckboxScope,
  getState,
  isIndeterminate
};
