import { createContext, useContext, useMemo } from "react";
import { objectIdentityKey } from "./objectIdentityKey";
import { jsx } from "react/jsx-runtime";
function createStyledContext(defaultValues) {
  const OGContext = createContext(defaultValues), OGProvider = OGContext.Provider, Context = OGContext, scopedContexts = /* @__PURE__ */ new Map(), Provider = ({
    children,
    scope,
    ...values
  }) => {
    const next = useMemo(() => ({
      // this ! is a workaround for ts error
      ...defaultValues,
      ...values
    }), [objectIdentityKey(values)]);
    let Provider2 = OGProvider;
    if (scope) {
      let ScopedContext = scopedContexts.get(scope);
      ScopedContext || (ScopedContext = createContext(defaultValues), scopedContexts.set(scope, ScopedContext)), Provider2 = ScopedContext.Provider;
    }
    return /* @__PURE__ */ jsx(Provider2, { value: next, children });
  }, useStyledContext = (scope) => {
    const context = scope ? scopedContexts.get(scope) : OGContext;
    return useContext(context);
  };
  return Context.Provider = Provider, Context.props = defaultValues, Context.context = OGContext, Context.useStyledContext = useStyledContext, Context;
}
export {
  createStyledContext
};
