import { FloatingOverlay, FloatingPortal } from "@floating-ui/react";
import { Theme, useIsTouchDevice, useThemeName } from "@tamagui/core";
import { FocusScope } from "@tamagui/focus-scope";
import { useMemo } from "react";
import { useSelectContext, useSelectItemParentContext } from "./context";
import { useShowSelectSheet } from "./useSelectBreakpointActive";
import { Fragment, jsx } from "react/jsx-runtime";
const CONTENT_NAME = "SelectContent", SelectContent = ({
  children,
  __scopeSelect,
  zIndex = 1e3,
  ...focusScopeProps
}) => {
  const context = useSelectContext(CONTENT_NAME, __scopeSelect), itemParentContext = useSelectItemParentContext(CONTENT_NAME, __scopeSelect), themeName = useThemeName(), showSheet = useShowSelectSheet(context), contents = /* @__PURE__ */ jsx(Theme, { forceClassName: !0, name: themeName, children }), touch = useIsTouchDevice(), overlayStyle = useMemo(() => ({ zIndex, pointerEvents: context.open ? "auto" : "none" }), [context.open]);
  return itemParentContext.shouldRenderWebNative ? /* @__PURE__ */ jsx(Fragment, { children }) : showSheet ? context.open ? /* @__PURE__ */ jsx(Fragment, { children: contents }) : null : /* @__PURE__ */ jsx(FloatingPortal, { children: /* @__PURE__ */ jsx(
    FloatingOverlay,
    {
      style: overlayStyle,
      lockScroll: !context.disablePreventBodyScroll && !!context.open && !touch,
      children: /* @__PURE__ */ jsx(FocusScope, { loop: !0, enabled: !!context.open, trapped: !0, ...focusScopeProps, children: contents })
    }
  ) });
};
export {
  SelectContent
};
