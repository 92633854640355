import { styled } from "@tamagui/web";
import { Paragraph } from "./Paragraph";
const Heading = styled(Paragraph, {
  tag: "span",
  name: "Heading",
  accessibilityRole: "header",
  fontFamily: "$heading",
  size: "$8",
  margin: 0
}), H1 = styled(Heading, {
  name: "H1",
  tag: "h1",
  size: "$10"
}), H2 = styled(Heading, {
  name: "H2",
  tag: "h2",
  size: "$9"
}), H3 = styled(Heading, {
  name: "H3",
  tag: "h3",
  size: "$8"
}), H4 = styled(Heading, {
  name: "H4",
  tag: "h4",
  size: "$7"
}), H5 = styled(Heading, {
  name: "H5",
  tag: "h5",
  size: "$6"
}), H6 = styled(Heading, {
  name: "H6",
  tag: "h6",
  size: "$5"
});
export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Heading
};
