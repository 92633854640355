const keyName = "__reactResponderId", canUseDOM = !!(typeof window < "u" && window.document && window.document.createElement), getBoundingClientRect = (node) => {
  if (node && node.nodeType === 1 && node.getBoundingClientRect)
    return node.getBoundingClientRect();
};
function getEventPath(domEvent) {
  if (domEvent.type === "selectionchange") {
    const target = window.getSelection()?.anchorNode;
    return composedPathFallback(target);
  }
  return domEvent.composedPath != null ? domEvent.composedPath() : composedPathFallback(domEvent.target);
}
function composedPathFallback(target) {
  const path = [];
  for (; target != null && target !== document.body; )
    path.push(target), target = target.parentNode;
  return path;
}
function getResponderId(node) {
  return node != null ? node[keyName] : null;
}
function setResponderId(node, id) {
  node != null && (node[keyName] = id);
}
function getResponderPaths(domEvent) {
  const idPath = [], nodePath = [], eventPath = getEventPath(domEvent);
  for (let i = 0; i < eventPath.length; i++) {
    const node = eventPath[i], id = getResponderId(node);
    id != null && (idPath.push(id), nodePath.push(node));
  }
  return { idPath, nodePath };
}
function getLowestCommonAncestor(pathA, pathB) {
  let pathALength = pathA.length, pathBLength = pathB.length;
  if (
    // If either path is empty
    pathALength === 0 || pathBLength === 0 || // If the last elements aren't the same there can't be a common ancestor
    // that is connected to the responder system
    pathA[pathALength - 1] !== pathB[pathBLength - 1]
  )
    return null;
  let itemA = pathA[0], indexA = 0, itemB = pathB[0], indexB = 0;
  pathALength - pathBLength > 0 && (indexA = pathALength - pathBLength, itemA = pathA[indexA], pathALength = pathBLength), pathBLength - pathALength > 0 && (indexB = pathBLength - pathALength, itemB = pathB[indexB], pathBLength = pathALength);
  let depth = pathALength;
  for (; depth--; ) {
    if (itemA === itemB)
      return itemA;
    itemA = pathA[indexA++], itemB = pathB[indexB++];
  }
  return null;
}
function hasTargetTouches(target, touches) {
  if (!touches || touches.length === 0)
    return !1;
  for (let i = 0; i < touches.length; i++) {
    const node = touches[i].target;
    if (node != null && target.contains(node))
      return !0;
  }
  return !1;
}
function hasValidSelection(domEvent) {
  return domEvent.type === "selectionchange" ? isSelectionValid() : domEvent.type === "select";
}
function isPrimaryPointerDown(domEvent) {
  const { altKey, button, buttons, ctrlKey, type } = domEvent, isTouch = type === "touchstart" || type === "touchmove", isPrimaryMouseDown = type === "mousedown" && (button === 0 || buttons === 1), isPrimaryMouseMove = type === "mousemove" && buttons === 1, noModifiers = altKey === !1 && ctrlKey === !1;
  return !!(isTouch || isPrimaryMouseDown && noModifiers || isPrimaryMouseMove && noModifiers);
}
function isSelectionValid() {
  const selection = window.getSelection();
  if (!selection)
    return !1;
  const string = selection.toString(), anchorNode = selection.anchorNode, focusNode = selection.focusNode, isTextNode = anchorNode && anchorNode.nodeType === window.Node.TEXT_NODE || focusNode && focusNode.nodeType === window.Node.TEXT_NODE;
  return string.length >= 1 && string !== `
` && !!isTextNode;
}
export {
  canUseDOM,
  getBoundingClientRect,
  getLowestCommonAncestor,
  getResponderPaths,
  hasTargetTouches,
  hasValidSelection,
  isPrimaryPointerDown,
  isSelectionValid,
  setResponderId
};
