import { createContextScope } from "@tamagui/create-context";
import { SELECT_NAME } from "./constants";
import { jsx } from "react/jsx-runtime";
const [createSelectContext, createSelectScope] = createContextScope(SELECT_NAME), [SelectProvider, useSelectContext] = createSelectContext(SELECT_NAME), [createSelectItemParentContext, createSelectItemParentScope] = createContextScope(SELECT_NAME), [SelectItemParentProvider, useSelectItemParentContext] = createSelectContext(SELECT_NAME), ForwardSelectContext = ({
  __scopeSelect,
  context,
  itemContext,
  children
}) => /* @__PURE__ */ jsx(SelectProvider, { isInSheet: !0, scope: __scopeSelect, ...context, children: /* @__PURE__ */ jsx(SelectItemParentProvider, { scope: __scopeSelect, ...itemContext, children }) });
export {
  ForwardSelectContext,
  SelectItemParentProvider,
  SelectProvider,
  createSelectContext,
  createSelectItemParentContext,
  createSelectItemParentScope,
  createSelectScope,
  useSelectContext,
  useSelectItemParentContext
};
