import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { transformsToString } from "@tamagui/core";
import { ResetPresence, usePresence } from "@tamagui/use-presence";
import { useEffect, useState } from "react";
function createAnimations(animations) {
  const reactionListeners = /* @__PURE__ */ new WeakMap();
  return {
    animations,
    usePresence,
    ResetPresence,
    supportsCSSVars: !0,
    useAnimatedNumber(initial) {
      const [val, setVal] = useState(initial);
      return {
        getInstance() {
          return setVal;
        },
        getValue() {
          return val;
        },
        setValue(next) {
          setVal(next);
          const listeners = reactionListeners.get(setVal);
          listeners && listeners.forEach((cb) => cb(next));
        },
        stop() {
        }
      };
    },
    useAnimatedNumberReaction({ value }, onValue) {
      useEffect(() => {
        const instance = value.getInstance();
        let queue = reactionListeners.get(instance);
        if (!queue) {
          const next = /* @__PURE__ */ new Set();
          reactionListeners.set(instance, next), queue = next;
        }
        return queue.add(onValue), () => {
          queue?.delete(onValue);
        };
      }, []);
    },
    useAnimatedNumberStyle(val, getStyle) {
      return getStyle(val.getValue());
    },
    useAnimations: ({ props, presence, style, componentState, stateRef }) => {
      const isEntering = !!componentState.unmounted, isExiting = presence?.[0] === !1, sendExitComplete = presence?.[1], [animationKey, animationConfig] = [].concat(props.animation), animation = animations[animationKey], keys = props.animateOnly ?? ["all"];
      return useIsomorphicLayoutEffect(() => {
        const host = stateRef.current.host;
        if (!sendExitComplete || !isExiting || !host)
          return;
        const node = host, onFinishAnimation = () => {
          sendExitComplete?.();
        };
        return node.addEventListener("transitionend", onFinishAnimation), node.addEventListener("transitioncancel", onFinishAnimation), () => {
          node.removeEventListener("transitionend", onFinishAnimation), node.removeEventListener("transitioncancel", onFinishAnimation);
        };
      }, [sendExitComplete, isExiting]), animation ? (Array.isArray(style.transform) && (style.transform = transformsToString(style.transform)), style.transition = keys.map((key) => {
        const override = animations[animationConfig?.[key]] ?? animation;
        return `${key} ${override}`;
      }).join(", "), process.env.NODE_ENV === "development" && props.debug && console.info("CSS animation", style, style.transition, { isEntering, isExiting }), { style }) : null;
    }
  };
}
export {
  createAnimations
};
