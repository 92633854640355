import { isWeb } from "@tamagui/constants";
import { useSyncExternalStore } from "react";
import { Dimensions } from "react-native-web";
const initialValue = {
  height: 800,
  width: 600
};
function configureInitialWindowDimensions(next) {
  Object.assign(initialValue, next);
}
Dimensions.addEventListener("change", () => {
  cbs.forEach((cb) => cb(window));
});
const cbs = /* @__PURE__ */ new Set();
function subscribe(cb) {
  return cbs.add(cb), () => cbs.delete(cb);
}
function useWindowDimensions({
  serverValue = initialValue
} = {}) {
  return useSyncExternalStore(
    subscribe,
    () => Dimensions.get("window"),
    () => isWeb ? serverValue : Dimensions.get("window")
  );
}
export {
  configureInitialWindowDimensions,
  useWindowDimensions
};
