import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { getVariableValue, styled } from "@tamagui/core";
import { createContextScope } from "@tamagui/create-context";
import { getSize } from "@tamagui/get-token";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { useLabelContext } from "@tamagui/label";
import { RovingFocusGroup } from "@tamagui/roving-focus";
import { ThemeableStack } from "@tamagui/stacks";
import { useControllableState } from "@tamagui/use-controllable-state";
import { usePrevious } from "@tamagui/use-previous";
import * as React from "react";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const RADIO_GROUP_NAME = "RadioGroup", ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"], [createRadioGroupContext, createRadioGroupScope] = createContextScope(RADIO_GROUP_NAME), [RadioGroupProvider, useRadioGroupContext] = createRadioGroupContext(RADIO_GROUP_NAME), getState = (checked) => checked ? "checked" : "unchecked", RADIO_GROUP_INDICATOR_NAME = "RadioGroupIndicator", RadioIndicatorFrame = styled(ThemeableStack, {
  name: RADIO_GROUP_INDICATOR_NAME,
  variants: {
    unstyled: {
      false: {
        width: "33%",
        height: "33%",
        borderRadius: 1e3,
        backgroundColor: "$color",
        pressTheme: !0
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), RadioIndicator = RadioIndicatorFrame.extractable(
  React.forwardRef(
    (props, forwardedRef) => {
      const { __scopeRadioGroupItem, forceMount, disabled, ...indicatorProps } = props, { checked } = useRadioGroupItemContext(
        RADIO_GROUP_INDICATOR_NAME,
        __scopeRadioGroupItem
      );
      return forceMount || checked ? /* @__PURE__ */ jsx(
        RadioIndicatorFrame,
        {
          "data-state": getState(checked),
          "data-disabled": disabled ? "" : void 0,
          ...indicatorProps,
          ref: forwardedRef
        }
      ) : null;
    }
  )
);
RadioIndicator.displayName = RADIO_GROUP_INDICATOR_NAME;
const RADIO_GROUP_ITEM_NAME = "RadioGroupItem", [RadioGroupItemProvider, useRadioGroupItemContext] = createRadioGroupContext(RADIO_GROUP_NAME), RadioGroupItemFrame = styled(ThemeableStack, {
  name: RADIO_GROUP_ITEM_NAME,
  tag: "button",
  variants: {
    unstyled: {
      false: {
        size: "$true",
        borderRadius: 1e3,
        backgroundColor: "$background",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: "$borderColor",
        padding: 0,
        hoverStyle: {
          borderColor: "$borderColorHover",
          backgroundColor: "$backgroundHover"
        },
        focusStyle: {
          borderColor: "$borderColorHover",
          backgroundColor: "$backgroundHover",
          outlineStyle: "solid",
          outlineWidth: 2,
          outlineColor: "$outlineColor"
        },
        pressStyle: {
          borderColor: "$borderColorFocus",
          backgroundColor: "$backgroundFocus"
        }
      }
    },
    disabled: {
      true: {
        pointerEvents: "none",
        userSelect: "none",
        cursor: "not-allowed",
        hoverStyle: {
          borderColor: "$borderColor",
          backgroundColor: "$background"
        },
        pressStyle: {
          borderColor: "$borderColor",
          backgroundColor: "$backgroundColor"
        },
        focusStyle: {
          outlineWidth: 0
        }
      }
    },
    size: {
      "...size": (value, { props }) => {
        const size = Math.floor(
          getVariableValue(getSize(value)) * (props.scaleSize ?? 0.5)
        );
        return {
          width: size,
          height: size
        };
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), RadioGroupItem = RadioGroupItemFrame.extractable(
  React.forwardRef(
    (props, forwardedRef) => {
      const {
        __scopeRadioGroup,
        value,
        labelledBy: ariaLabelledby,
        disabled: itemDisabled,
        ...itemProps
      } = props, {
        value: groupValue,
        disabled,
        required,
        onChange,
        name,
        native,
        accentColor
      } = useRadioGroupContext(RADIO_GROUP_ITEM_NAME, __scopeRadioGroup), [button, setButton] = React.useState(null), hasConsumerStoppedPropagationRef = React.useRef(!1), ref = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, (node) => setButton(node), ref), isArrowKeyPressedRef = React.useRef(!1), isFormControl = isWeb ? button ? !!button.closest("form") : !0 : !1, checked = groupValue === value, labelId = useLabelContext(button), labelledBy = ariaLabelledby || labelId;
      React.useEffect(() => {
        if (isWeb) {
          const handleKeyDown = (event) => {
            ARROW_KEYS.includes(event.key) && (isArrowKeyPressedRef.current = !0);
          }, handleKeyUp = () => {
            isArrowKeyPressedRef.current = !1;
          };
          return document.addEventListener("keydown", handleKeyDown), document.addEventListener("keyup", handleKeyUp), () => {
            document.removeEventListener("keydown", handleKeyDown), document.removeEventListener("keyup", handleKeyUp);
          };
        }
      }, []);
      const isDisabled = disabled || itemDisabled;
      return /* @__PURE__ */ jsx(RadioGroupItemProvider, { checked, scope: __scopeRadioGroup, children: isWeb && native ? /* @__PURE__ */ jsx(
        BubbleInput,
        {
          control: button,
          bubbles: !hasConsumerStoppedPropagationRef.current,
          name,
          value,
          checked,
          required,
          disabled: isDisabled,
          id: props.id,
          accentColor
        }
      ) : /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          RovingFocusGroup.Item,
          {
            __scopeRovingFocusGroup: RADIO_GROUP_NAME,
            asChild: "except-style",
            focusable: !isDisabled,
            active: checked,
            children: /* @__PURE__ */ jsx(
              RadioGroupItemFrame,
              {
                "data-state": getState(checked),
                "data-disabled": isDisabled ? "" : void 0,
                role: "radio",
                "aria-labelledby": labelledBy,
                "aria-checked": checked,
                "aria-required": required,
                disabled: isDisabled,
                ref: composedRefs,
                ...isWeb && {
                  type: "button",
                  value
                },
                ...itemProps,
                onPress: composeEventHandlers(props.onPress, (event) => {
                  checked || onChange?.(value), isFormControl && (hasConsumerStoppedPropagationRef.current = event.isPropagationStopped(), hasConsumerStoppedPropagationRef.current || event.stopPropagation());
                }),
                ...isWeb && {
                  onKeyDown: composeEventHandlers(
                    props.onKeyDown,
                    (event) => {
                      event.key === "Enter" && event.preventDefault();
                    }
                  ),
                  onFocus: composeEventHandlers(itemProps.onFocus, () => {
                    isArrowKeyPressedRef.current && ref.current?.click();
                  })
                }
              }
            )
          }
        ),
        isFormControl && /* @__PURE__ */ jsx(
          BubbleInput,
          {
            isHidden: !0,
            control: button,
            bubbles: !hasConsumerStoppedPropagationRef.current,
            name,
            value,
            checked,
            required,
            disabled: isDisabled
          }
        )
      ] }) });
    }
  )
), BubbleInput = (props) => {
  const { checked, bubbles = !0, control, isHidden, accentColor, ...inputProps } = props, ref = React.useRef(null), prevChecked = usePrevious(checked);
  return React.useEffect(() => {
    const input = ref.current, inputProto = window.HTMLInputElement.prototype, setChecked = Object.getOwnPropertyDescriptor(
      inputProto,
      "checked"
    ).set;
    if (prevChecked !== checked && setChecked) {
      const event = new Event("click", { bubbles });
      setChecked.call(input, checked), input.dispatchEvent(event);
    }
  }, [prevChecked, checked, bubbles]), /* @__PURE__ */ jsx(
    "input",
    {
      type: "radio",
      defaultChecked: checked,
      ...inputProps,
      tabIndex: -1,
      ref,
      "aria-hidden": isHidden,
      style: {
        ...isHidden ? {
          // ...controlSize,
          position: "absolute",
          pointerEvents: "none",
          opacity: 0,
          margin: 0
        } : {
          appearance: "auto",
          accentColor
        },
        ...props.style
      }
    }
  );
}, RadioGroupFrame = styled(ThemeableStack, {
  name: RADIO_GROUP_NAME,
  variants: {
    orientation: {
      horizontal: {
        flexDirection: "row",
        spaceDirection: "horizontal"
      },
      vertical: {
        flexDirection: "column",
        spaceDirection: "vertical"
      }
    }
  }
}), RadioGroup = withStaticProperties(
  RadioGroupFrame.extractable(
    React.forwardRef(
      (props, forwardedRef) => {
        const {
          __scopeRadioGroup,
          value: valueProp,
          defaultValue,
          onValueChange,
          disabled = !1,
          required = !1,
          name,
          orientation,
          native,
          accentColor,
          ...radioGroupProps
        } = props, [value, setValue] = useControllableState({
          prop: valueProp,
          defaultProp: defaultValue,
          onChange: onValueChange
        });
        return /* @__PURE__ */ jsx(
          RadioGroupProvider,
          {
            scope: __scopeRadioGroup,
            value,
            required,
            onChange: setValue,
            disabled,
            name,
            native,
            accentColor,
            children: /* @__PURE__ */ jsx(
              RovingFocusGroup,
              {
                __scopeRovingFocusGroup: RADIO_GROUP_NAME,
                orientation,
                loop: !0,
                children: /* @__PURE__ */ jsx(
                  RadioGroupFrame,
                  {
                    role: "radiogroup",
                    "aria-orientation": orientation,
                    ref: forwardedRef,
                    orientation,
                    "data-disabled": disabled ? "" : void 0,
                    ...radioGroupProps
                  }
                )
              }
            )
          }
        );
      }
    )
  ),
  {
    Indicator: RadioIndicator,
    Item: RadioGroupItem
  }
);
RadioGroup.displayName = RADIO_GROUP_NAME;
export {
  RadioGroup,
  createRadioGroupScope
};
