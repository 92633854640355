import { useContext } from "react";
import { ComponentContext } from "../contexts/ComponentContext";
import { defaultComponentStateMounted } from "../defaultComponentState";
import { useSplitStyles } from "../helpers/getSplitStyles";
import { Stack } from "../views/Stack";
import { useMedia } from "./useMedia";
import { useThemeWithState } from "./useTheme";
function useProps(props, opts) {
  const [propsOut, styleOut] = usePropsAndStyle(props, {
    ...opts,
    noExpand: !0,
    noNormalize: !0,
    resolveValues: "none"
  });
  return {
    ...propsOut,
    ...styleOut
  };
}
function useStyle(props, opts) {
  return usePropsAndStyle(props, opts)[1];
}
function usePropsAndStyle(props, opts) {
  const staticConfig = opts?.forComponent?.staticConfig ?? Stack.staticConfig, [themeState, theme] = useThemeWithState({
    componentName: staticConfig.componentName
  }), componentContext = useContext(ComponentContext), media = useMedia(), splitStyles = useSplitStyles(
    props,
    staticConfig,
    theme,
    themeState.state?.name || "",
    defaultComponentStateMounted,
    {
      isAnimated: !1,
      mediaState: media,
      noSkip: !0,
      noClassNames: !0,
      resolveValues: "auto",
      ...opts
    },
    null,
    componentContext
  );
  return [splitStyles.viewProps, splitStyles.style, theme, media];
}
export {
  useProps,
  usePropsAndStyle,
  useStyle
};
