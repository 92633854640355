import React, { useCallback, useRef, useEffect } from 'react'
// Ordinary Expo Linking creates a build issue for Cloudflare
import Linking from 'expo-linking/build/ExpoLinking'

import { Platform, GestureResponderEvent } from 'react-native'

import { useNavigate, NavigateOptions } from 'react-router-dom'

import { styled } from 'tamagui'

import { Text } from './Text'

/**
 * Increase web accessibility for Right click -> Open in new tab,
 * Command/Ctrl click, and "target=_blank".
 * taken from
 * https://github.com/react-navigation/react-navigation/blob/1e342f16627854bca6534e2aef149824c00c99c0/packages/native/src/useLinkProps.tsx
 */

type PressEvent =
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  | GestureResponderEvent

const EVENT_LISTENER_OPTIONS = { capture: true }

export const useCreateLinkPressHandler = (linkAction: () => void) => {
  const anchorRef = useRef<HTMLAnchorElement>()
  const clickableRef = useRef()

  const linkPressHandler = useCallback(
    (e?: PressEvent) => {
      let shouldHandle = false

      if (Platform.OS !== 'web' || !e) {
        shouldHandle = e ? !e.defaultPrevented : true
      } else if (
        !e.defaultPrevented && // onPress prevented default
        // @ts-expect-error: these properties exist on web, but not in React Native
        !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) && // ignore clicks with modifier keys
        // @ts-expect-error: these properties exist on web, but not in React Native
        (e.button == null || e.button === 0) && // ignore everything but left clicks
        // replaced `e.currentTarget` with React ref pattern
        [undefined, null, '', 'self'].includes(anchorRef.current?.target) // let browser handle "target=_blank" etc.
      ) {
        e.preventDefault()

        shouldHandle = true
      }

      // console.log('LINK PRESS HANDLER', shouldHandle)

      if (shouldHandle) {
        linkAction()
      }
    },
    [linkAction]
  )

  /**
   * Catch event on capture phase before it bubbles down to anchor tag.
   * Element as View does not accept onClick or onPress props, therefore
   * we will manually handle event listeners here.
   * We don't want the anchor tags to handle links, we want react-router
   * navigate to properly handle nested routes.
   */
  useEffect(() => {
    if (clickableRef.current) {
      // @ts-expect-error: these properties exist on web, but not in React Native
      clickableRef.current?.addEventListener(
        'click',
        linkPressHandler,
        EVENT_LISTENER_OPTIONS
      )

      return () => {
        // @ts-expect-error: these properties exist on web, but not in React Native
        clickableRef.current?.removeEventListener(
          'click',
          linkPressHandler,
          EVENT_LISTENER_OPTIONS
        )
      }
    }
  }, [linkPressHandler, clickableRef.current])

  return { anchorRef, clickableRef, linkPressHandler }
}

export interface LinkProps {
  beforeNavigate?: () => void
  to: string
}

export const Anchor = styled(Text, {
  tag: 'a',
  accessibilityRole: 'link',

  cursor: 'pointer',

  // fontWeight: '$4',
  color: '$darkBlue9',

  textDecorationLine: 'none',

  hoverStyle: {
    textDecorationLine: 'underline',
  },

  variants: {
    size: {
      '...size': (size) => {
        return {
          fontSize: size,
          lineHeight: size,
        }
      },
    },
  } as const,

  defaultVariants: {
    size: '$5',
  },
})

export const Link = Anchor.styleable<{
  href: string
  options?: NavigateOptions
}>(({ href, options, ...props }, ref) => {
  const navigate = useNavigate()

  const linkAction = useCallback(
    () => navigate(href, options),
    [navigate, href, options]
  )
  const { linkPressHandler, anchorRef, clickableRef } =
    useCreateLinkPressHandler(linkAction)

  return (
    <Anchor
      ref={(node) => {
        if (ref) {
          ref.current = node
        }

        if (anchorRef) {
          anchorRef.current = node as HTMLAnchorElement | undefined
        }
      }}
      href={href}
      onPress={linkPressHandler}
      {...props}
    />
  )
})

export const ExternalLink = Anchor.styleable<{ href: string }>(
  ({ href, ...props }, ref) => {
    const linkAction = useCallback(() => Linking.openURL(href), [href])
    const { linkPressHandler, anchorRef, clickableRef } =
      useCreateLinkPressHandler(linkAction)

    return (
      <Anchor
        ref={(node) => {
          if (ref) {
            ref.current = node
          }

          if (anchorRef) {
            anchorRef.current = node as HTMLAnchorElement | undefined
          }
        }}
        target='_blank'
        href={href}
        {...props}
        onPress={linkPressHandler}
      />
    )
  }
)
