import "@tamagui/polyfill-dev";
import { isWeb } from "@tamagui/constants";
import { useIsomorphicLayoutEffect } from "@tamagui/constants";
import { YStack } from "@tamagui/stacks";
import * as React from "react";
import { createPortal } from "react-dom";
import { jsx } from "react/jsx-runtime";
const Portal = React.memo(
  ({ host = globalThis.document?.body, ...props }) => {
    const contents = /* @__PURE__ */ jsx(
      YStack,
      {
        contain: "strict",
        fullscreen: !0,
        position: isWeb ? "fixed" : "absolute",
        maxWidth: isWeb ? "100vw" : "100%",
        maxHeight: isWeb ? "100vh" : "100%",
        pointerEvents: "none",
        ...props
      }
    ), [hostElement, setHostElement] = React.useState(null);
    return useIsomorphicLayoutEffect(() => {
      setHostElement(host);
    }, [host]), hostElement && props.children ? createPortal(contents, hostElement) : null;
  }
);
export {
  Portal
};
