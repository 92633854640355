import { isWeb } from "@tamagui/constants";
import { registerFocusable } from "@tamagui/focusable";
import { getFontSize } from "@tamagui/font-size";
import { getSize } from "@tamagui/get-token";
import { Group, useGroupItem } from "@tamagui/group";
import { withStaticProperties } from "@tamagui/helpers";
import { useGetThemedIcon } from "@tamagui/helpers-tamagui";
import { RovingFocusGroup } from "@tamagui/roving-focus";
import { useControllableState } from "@tamagui/use-controllable-state";
import { useDirection } from "@tamagui/use-direction";
import { createStyledContext, getVariableValue, styled, useTheme } from "@tamagui/web";
import React from "react";
import { Toggle, ToggleFrame } from "./Toggle";
import { jsx } from "react/jsx-runtime";
const TOGGLE_GROUP_NAME = "ToggleGroup", TOGGLE_GROUP_ITEM_NAME = "ToggleGroupItem", TOGGLE_GROUP_CONTEXT = "ToggleGroup", { Provider: ToggleGroupItemProvider, useStyledContext: useToggleGroupItemContext } = createStyledContext(), { Provider: ToggleGroupContext, useStyledContext: useToggleGroupContext } = createStyledContext(), ToggleGroupItem = ToggleFrame.extractable(
  React.forwardRef(
    (props, forwardedRef) => {
      const valueContext = useToggleGroupValueContext(props.__scopeToggleGroup), context = useToggleGroupContext(props.__scopeToggleGroup), pressed = valueContext?.value.includes(props.value), disabled = context.disabled || props.disabled || !1, groupItemProps = useGroupItem({ disabled }), size = props.size ?? context.size, sizeProps = props.unstyled ? {} : {
        width: void 0,
        height: void 0,
        padding: getVariableValue(size) * 0.6
      }, iconSize = (typeof size == "number" ? size * 0.7 : getFontSize(size)) * 1.2, theme = useTheme(), getThemedIcon = useGetThemedIcon({ size: iconSize, color: theme.color }), children = React.Children.toArray(props.children).map((child) => props.disablePassStyles || !React.isValidElement(child) ? child : getThemedIcon(child)), commonProps = { pressed, disabled, ...sizeProps, ...props, children }, inner = /* @__PURE__ */ jsx(
        ToggleGroupItemImpl,
        {
          ...commonProps,
          ref: forwardedRef,
          focusable: !disabled,
          disabled,
          ...groupItemProps
        }
      );
      return /* @__PURE__ */ jsx(ToggleGroupItemProvider, { scope: props.__scopeToggleGroup, children: context.rovingFocus ? /* @__PURE__ */ jsx(
        RovingFocusGroup.Item,
        {
          asChild: "except-style",
          __scopeRovingFocusGroup: props.__scopeToggleGroup || TOGGLE_GROUP_CONTEXT,
          focusable: !disabled,
          active: pressed,
          children: inner
        }
      ) : inner });
    }
  )
);
ToggleGroupItem.displayName = TOGGLE_GROUP_ITEM_NAME;
const ToggleGroupItemImpl = React.forwardRef((props, forwardedRef) => {
  const { __scopeToggleGroup, value, ...itemProps } = props, valueContext = useToggleGroupValueContext(__scopeToggleGroup), singleProps = {
    "aria-pressed": void 0
  }, typeProps = valueContext.type === "single" ? singleProps : void 0;
  return /* @__PURE__ */ jsx(
    Toggle,
    {
      ...typeProps,
      ...itemProps,
      ref: forwardedRef,
      onPressedChange: (pressed) => {
        pressed ? valueContext.onItemActivate(value) : valueContext.onItemDeactivate(value);
      }
    }
  );
}), ToggleGroup = withStaticProperties(
  React.forwardRef(
    (props, forwardedRef) => {
      const { type, ...toggleGroupProps } = props;
      if (isWeb || React.useEffect(() => {
        if (props.id)
          return registerFocusable(props.id, {
            // TODO: would be nice to focus on the first child later - could be done with reforest
            // for now leaving it empty
            focus: () => {
            }
          });
      }, [props.id]), type === "single")
        return /* @__PURE__ */ jsx(ToggleGroupImplSingle, { ...toggleGroupProps, ref: forwardedRef });
      if (type === "multiple")
        return /* @__PURE__ */ jsx(ToggleGroupImplMultiple, { ...toggleGroupProps, ref: forwardedRef });
      throw new Error(`Missing prop \`type\` expected on \`${TOGGLE_GROUP_NAME}\``);
    }
  ),
  {
    Item: ToggleGroupItem
  }
);
ToggleGroup.displayName = TOGGLE_GROUP_NAME;
const {
  Provider: ToggleGroupValueProvider,
  useStyledContext: useToggleGroupValueContext
} = createStyledContext(), ToggleGroupImplSingle = React.forwardRef((props, forwardedRef) => {
  const {
    value: valueProp,
    defaultValue,
    onValueChange = () => {
    },
    disableDeactivation = !1,
    ...toggleGroupSingleProps
  } = props, [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: onValueChange
  });
  return /* @__PURE__ */ jsx(
    ToggleGroupValueProvider,
    {
      scope: props.__scopeToggleGroup,
      type: "single",
      value: value ? [value] : [],
      defaultValue: value,
      onItemActivate: setValue,
      onItemDeactivate: React.useCallback(
        () => disableDeactivation ? null : setValue(""),
        [setValue, disableDeactivation]
      ),
      children: /* @__PURE__ */ jsx(ToggleGroupImpl, { ...toggleGroupSingleProps, ref: forwardedRef })
    }
  );
}), ToggleGroupImplMultiple = React.forwardRef((props, forwardedRef) => {
  const {
    value: valueProp,
    defaultValue,
    onValueChange = () => {
    },
    ...toggleGroupMultipleProps
  } = props, [value = [], setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: onValueChange
  }), handleButtonActivate = React.useCallback(
    (itemValue) => setValue((prevValue = []) => [...prevValue, itemValue]),
    [setValue]
  ), handleButtonDeactivate = React.useCallback(
    (itemValue) => setValue((prevValue = []) => prevValue.filter((value2) => value2 !== itemValue)),
    [setValue]
  );
  return /* @__PURE__ */ jsx(
    ToggleGroupValueProvider,
    {
      scope: props.__scopeToggleGroup,
      type: "multiple",
      value,
      defaultValue: value,
      onItemActivate: handleButtonActivate,
      onItemDeactivate: handleButtonDeactivate,
      children: /* @__PURE__ */ jsx(ToggleGroupImpl, { ...toggleGroupMultipleProps, ref: forwardedRef })
    }
  );
});
ToggleGroup.displayName = TOGGLE_GROUP_NAME;
const ToggleGroupImplElementFrame = styled(Group, {
  name: TOGGLE_GROUP_NAME,
  variants: {
    unstyled: {
      false: {
        backgroundColor: "$background"
      }
    },
    orientation: {
      vertical: {
        flexDirection: "column",
        spaceDirection: "vertical"
      },
      horizontal: {
        flexDirection: "row",
        spaceDirection: "horizontal"
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), ToggleGroupImpl = ToggleGroupImplElementFrame.extractable(
  React.forwardRef(
    (props, forwardedRef) => {
      const {
        __scopeToggleGroup,
        disabled = !1,
        orientation = "horizontal",
        dir,
        rovingFocus = !0,
        loop = !0,
        unstyled = !1,
        size: sizeProp = "$true",
        sizeAdjust = 0,
        ...toggleGroupProps
      } = props, direction = useDirection(dir), commonProps = {
        role: "group",
        dir: direction,
        ...toggleGroupProps
      }, adjustedSize = getVariableValue(
        getSize(sizeProp, {
          shift: sizeAdjust
        })
      ), size = Math.round(adjustedSize * 0.45);
      return /* @__PURE__ */ jsx(
        ToggleGroupContext,
        {
          scope: __scopeToggleGroup,
          rovingFocus,
          disabled,
          size,
          children: rovingFocus ? /* @__PURE__ */ jsx(
            RovingFocusGroup,
            {
              asChild: "except-style",
              __scopeRovingFocusGroup: __scopeToggleGroup || TOGGLE_GROUP_CONTEXT,
              orientation,
              dir: direction,
              loop,
              children: /* @__PURE__ */ jsx(
                ToggleGroupImplElementFrame,
                {
                  "aria-orientation": orientation,
                  orientation,
                  axis: orientation,
                  ref: forwardedRef,
                  "data-disabled": disabled ? "" : void 0,
                  unstyled,
                  ...commonProps
                }
              )
            }
          ) : /* @__PURE__ */ jsx(
            ToggleGroupImplElementFrame,
            {
              "aria-orientation": orientation,
              ref: forwardedRef,
              orientation,
              "data-disabled": disabled ? "" : void 0,
              unstyled,
              ...commonProps
            }
          )
        }
      );
    }
  )
);
export {
  ToggleGroup
};
