import { mediaKeys } from "../hooks/useMedia";
import { pseudoDescriptors } from "./pseudoDescriptors";
const mergeProps = (a, b, inverseShorthands) => {
  const out = {};
  for (const key in a)
    mergeProp(out, a, b, key, inverseShorthands);
  if (b)
    for (const key in b)
      mergeProp(out, b, void 0, key, inverseShorthands);
  return out;
};
function mergeProp(out, a, b, key, inverseShorthands) {
  const longhand = inverseShorthands?.[key] || null, val = a[key];
  if (key in pseudoDescriptors || mediaKeys.has(key)) {
    out[key] = {
      ...out[key],
      ...val
    };
    return;
  }
  b && (key in b || longhand && longhand in b) || (out[longhand || key] = val);
}
export {
  mergeProps
};
