import { getVariableValue, isAndroid, isVariable } from "@tamagui/core";
const getElevation = (size, extras) => {
  if (!size)
    return;
  const { tokens } = extras, token = tokens.size[size], sizeNum = isVariable(token) ? +token.val : size;
  return getSizedElevation(sizeNum, extras);
}, getSizedElevation = (val, { theme, tokens }) => {
  let num = 0;
  if (val === !0) {
    const val2 = getVariableValue(tokens.size.true);
    typeof val2 == "number" ? num = val2 : num = 10;
  } else
    num = +val;
  if (num === 0)
    return;
  const [height, shadowRadius] = [Math.round(num / 4 + 1), Math.round(num / 2 + 2)];
  return {
    shadowColor: theme.shadowColor,
    shadowRadius,
    shadowOffset: { height, width: 0 },
    ...isAndroid ? {
      elevationAndroid: 2 * height
    } : {}
  };
};
export {
  getElevation,
  getSizedElevation
};
